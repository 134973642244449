import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { defaultStyles, muiOptions, defaultColors, MuiProps } from '../../../../../infrastructure/materialUiThemeProvider'
import { StockMovementContainer } from '../../stockMovementStore'
import { MovementType } from '../../../stockModels'
import {
    DeliveryNote, GoodsIssue, MaterialDocument, MaterialDocumentDate, MeanOfTransportation,
    PaymentTerms, PostingDate, ReferenceOrder, VettingNumber
} from '../_movementComponents'
import { PlantStorageLocationForm } from './plantStorageLocation'
import { DirectMovementSwitch } from './directMovementSwitch'
import { TemperatureRefObsSwitch } from './temperatureRefObsSwitch'
import { CreateSapMovementButtons } from './createInSap/component'

type SapFormProps = {
    isMainSapFlow: boolean
}

function _sapForm({ isMainSapFlow, classes }: SapFormProps & MuiProps) {
    let store = StockMovementContainer.useContainer()
    let fieldVisibility = getFieldVisibility(isMainSapFlow)

    return (
        <div>
            <div className={classes.row}>
                <DirectMovementSwitch className={classes.switch} isMainSapFlow={isMainSapFlow} />
                <TemperatureRefObsSwitch
                    unit={store.unit}
                    className={classes.switch}
                    isMainSapFlow={isMainSapFlow} />
            </div>
            <div className={classes.row}>
                <CreateSapMovementButtons isMainSapFlow={isMainSapFlow} />
            </div>
            <div className={classes.row}>
                {fieldVisibility.referenceOrder && <ReferenceOrder isMainSapFlow={isMainSapFlow} />}
                {fieldVisibility.deliveryNote && <DeliveryNote isMainSapFlow={isMainSapFlow} />}
                {fieldVisibility.goodsIssue && <GoodsIssue isMainSapFlow={isMainSapFlow} />}
                <MaterialDocument isMainSapFlow={isMainSapFlow} />
                <MaterialDocumentDate isMainSapFlow={isMainSapFlow} />
                {fieldVisibility.paymentTerms && <PaymentTerms />}
                {fieldVisibility.meanOfTransportation && <MeanOfTransportation />}
                <PostingDate isMainSapFlow={isMainSapFlow} />
            </div>
            <div className={classes.row}>
                {fieldVisibility.originPlant && <PlantStorageLocationForm target={'origin'} isMainSapFlow={isMainSapFlow} />}
                {fieldVisibility.originPlant && fieldVisibility.destinationPlant && <Icons.DoubleArrow className={classes.arrowTransition} />}
                {fieldVisibility.destinationPlant && <PlantStorageLocationForm target={'destination'} isMainSapFlow={isMainSapFlow} />}
                {fieldVisibility.vettingNumber && <VettingNumber isMainSapFlow={isMainSapFlow} />}
            </div>
        </div>
    )
}

function getFieldVisibility(isMainSapFlow: boolean) {
    let store = StockMovementContainer.useContainer()
    let sapFlow = store.getSapFlow(isMainSapFlow)

    function shouldDisplayReferenceOrder() {
        return sapFlow.movementType !== MovementType.Rebranding
            && sapFlow.movementType !== MovementType.Gains
            && sapFlow.movementType !== MovementType.Losses
            && sapFlow.sapFlowType !== 'direct301'
    }

    function shouldDisplayDeliveryNote() {
        return (sapFlow.movementType === MovementType.Sale
            || sapFlow.movementType === MovementType.Transfer
            || sapFlow.movementType === MovementType.StatusChange)
            && sapFlow.sapFlowType !== 'direct301'
    }

    function shouldDisplayGoodsIssue() {
        return (sapFlow.movementType === MovementType.Transfer
            || sapFlow.movementType === MovementType.StatusChange)
            && sapFlow.sapFlowType !== 'direct301'
    }

    function shouldDisplayOriginPlant() {
        return sapFlow.movementType === MovementType.Sale
            || sapFlow.movementType === MovementType.Loan
            || sapFlow.movementType === MovementType.Transfer
            || sapFlow.movementType === MovementType.StatusChange
            || sapFlow.movementType === MovementType.Rebranding
    }

    function shouldDisplayDestinationPlant() {
        return sapFlow.movementType === MovementType.Purchase
            || sapFlow.movementType === MovementType.Borrow
            || sapFlow.movementType === MovementType.Transfer
            || sapFlow.movementType === MovementType.StatusChange
            || sapFlow.movementType === MovementType.Rebranding
            || sapFlow.movementType === MovementType.Gains
            || sapFlow.movementType === MovementType.Losses
    }

    function shouldDisplayMeanOfTransportation() {
        return sapFlow.movementType !== MovementType.Rebranding
            && sapFlow.movementType !== MovementType.Gains
            && sapFlow.movementType !== MovementType.Losses
            && sapFlow.sapFlowType !== 'direct301'
    }

    function shouldDisplayPaymentTerms() {
        return sapFlow.movementType !== MovementType.Rebranding
            && sapFlow.movementType !== MovementType.Gains
            && sapFlow.movementType !== MovementType.Losses
            && sapFlow.sapFlowType !== 'direct301'
    }

    function shouldDisplayVettingNumber() {
        return sapFlow.movementType === MovementType.Purchase
    }

    return {
        referenceOrder: shouldDisplayReferenceOrder(),
        deliveryNote: shouldDisplayDeliveryNote(),
        goodsIssue: shouldDisplayGoodsIssue(),
        originPlant: shouldDisplayOriginPlant(),
        destinationPlant: shouldDisplayDestinationPlant(),
        paymentTerms: shouldDisplayPaymentTerms(),
        meanOfTransportation: shouldDisplayMeanOfTransportation(),
        vettingNumber: shouldDisplayVettingNumber(),
    }
}

let styles = () =>
    createStyles({
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
        switch: {
            '&:not(:first-child)': {
                marginLeft: '8em'
            }
        },
        arrowTransition: {
            width: '1em',
            height: '1em',
            marginTop: '0.6em',
            color: defaultColors.grey.dark.color
        },
        rowBottomAligned: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            marginTop: '1em',
            marginBottom: '1em'
        },
        leftMargin5em: {
            marginLeft: '5em'
        }
    })

export default withStyles(styles, muiOptions)(_sapForm)