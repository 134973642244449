import React from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { SupplyBalanceStore } from '../supplyBalanceStore'
import { MultipleSelect, GroupedMultiSelect } from '../../common/customComponents'

function _SupplyBalanceFilters({ classes }) {
    let store = SupplyBalanceStore.useContainer()

    return (
        <div className="supplyBalanceFilters">
            <GroupedMultiSelect
                label={t('supplyBalance.filters.sites')}
                classesOverride={{ form: classes.filterField }}
                values={store.filters.sites}
                choices={store.sites.map(x => ({ value: x.code, text: x.name, group: x.siteGroup }))}
                allWhenEmpty={false} disableNewStyle
                onChange={x => store.setFilters({ ...store.filters, sites: x })} />
            <MultipleSelect label={t('supplyBalance.filters.products')} classesOverride={{ form: classes.filterField }}
                values={store.filters.products}
                choices={store.products.map(x => ({ value: x.id, text: x.code }))}
                allWhenEmpty={false} disableNewStyle
                onChange={x => store.setFilters({ ...store.filters, products: x })} />
            <MultipleSelect label={t('supplyBalance.filters.dutyStatus')} classesOverride={{ form: classes.filterField }}
                values={store.filters.dutyStatuses}
                choices={store.dutyStatuses.map(x => ({ value: x, text: x }))}
                allWhenEmpty={false} disableNewStyle
                onChange={x => store.setFilters({ ...store.filters, dutyStatuses: x })} />
            <MultipleSelect label={t('supplyBalance.filters.companies')} classesOverride={{ form: classes.filterField }}
                values={store.filters.companys}
                choices={store.companies.map(x => ({ value: x.code, text: x.name }))}
                allWhenEmpty={false} disableNewStyle
                onChange={x => store.setFilters({ ...store.filters, companys: x })} />
        </div>
    )
}

let styles = _ =>
    createStyles({
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        }
    })

export let SupplyBalanceFilters = withStyles(styles, muiOptions)(_SupplyBalanceFilters)