import React, { useEffect, useState } from 'react'
import { createStyles, withStyles, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Divider, FormControlLabel, Checkbox } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { t } from '../../../infrastructure/i18nextHelper'
import { Popup, AttachFileButton, Button, ButtonPopupParams, CustomDialog, Stepper } from '../../common/customComponents'
import { muiOptions, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { CreateMovementFromDeal, DuplicateDealCommand, DealStatus } from '../dealModels'
import { TitleSection, ReferenceNumberSection, SiteSection, VolumeSection, TolerancesSection, CommentSection } from './_dealGeneralInformations'
import { PricingFirstLine, PricingSecondLine, PricingThirdLine } from './_dealPricingInformations'
import { VesselEditDialogContainer } from '../../vessels/vesselEdit/vesselEditStore'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { DealContainer, DealDialogContainer, dealDialog } from './dealEditStore'
import { DealSapForm } from './_dealSapForm'
import { useActionDebounce } from '../../common/debounce'
import { ValidationProcess } from './_validationProcess'
import { hasFeature } from '../../../infrastructure/feature'
import { pricingDialog, PricingDialogContainer } from '../../../app/pricings/pricingDialog'
import { popupNavigator } from '../../../infrastructure/popupNavigator'
import { CustomButton } from '../../common/components/button'
import guid from '../../../infrastructure/guid'
import DealMovements from './dealMovements'
import { DealStatusChip } from './statusChip'
import * as Component from './_dealComponents'

function DealEdit({ classes }) {
    let store = DealContainer.useContainer()
    let dealDialogStore = DealDialogContainer.useContainer()
    let pricingDialogStore = PricingDialogContainer.useContainer()
    let vessel = VesselEditDialogContainer.useContainer()
    let handleSaveDebouncer = useActionDebounce(save)
    let handleDuplicateDebouncer = useActionDebounce(duplicateDeal)
    let handleAssignMovementsDebouncer = useActionDebounce(assingMovements)
    let handleUnAssignMovementsDebouncer = useActionDebounce(unAssingMovement)
    let handleCreateMovementDebouncer = useActionDebounce(createMovement)
    let [duplicateConfirmDialogOpen, setDuplicateConfirmDialogOpen] = useState<boolean>(false)
    let [showPopupCreateMovement, setShowPopupCreateMovement] = useState<boolean>(false)

    async function save(): Promise<boolean> { return store.saveIfNoFieldErrors() }

    async function duplicateDeal() {
        let command: DuplicateDealCommand = {
            sourceId: store.dealId!,
            duplicateId: guid.createNew()
        }
        await store.duplicateDeal(command)
        popupNavigator.open('deal', command.duplicateId)
    }

    async function createMovement() {
        let command: CreateMovementFromDeal = {
            dealId: store.dealId!,
            movementId: guid.createNew()
        }
        if (await save()) {
            await store.createMovement(command)
            popupNavigator.open('movement', command.movementId)
        }
    }

    async function assingMovements() {
        let selectedMovements = store.selectedMovements
        if (selectedMovements?.length === 0) return
        let movementIds = selectedMovements?.map(x => x.id)
        await store.assignMovements(movementIds)
    }

    async function unAssingMovement(movementId: string) {
        if (!movementId) return
        await store.unAssignMovement(movementId)
    }

    let handleClose = () => {
        dealDialog.close()
    }

    let openPricing = async () => {
        if (!store.dealId) return
        if (hasClaim(Claims.DealManager)) {
            if (await save())
                pricingDialog.open({ id: store.dealId, pricingType: store.deal?.pricingType ?? null, costGroup: undefined })
        }
        else
            pricingDialog.open({ id: store.dealId, pricingType: store.deal?.pricingType ?? null, costGroup: undefined })
    }


    let openVessel = () => {
        if (store.associatedVesselId) {
            let options = store.deal?.productId
                ? { productId: store.deal?.productId }
                : undefined
            popupNavigator.open('vessel', store.associatedVesselId, options)
        }
    }

    let buttons: ButtonPopupParams[] = [{
        buttonEffect: () => setDuplicateConfirmDialogOpen(true),
        buttonText: t('deals.label.duplicate'),
        hideButton: !hasClaim(Claims.DealManager) || !(store.isAssociatedToAMovement() || store.isAutonomous())
    },
    {
        buttonEffect: handleSaveDebouncer.execute,
        buttonText: t('components.updateButton'),
        hideButton: !hasClaim(Claims.DealManager)
    }]

    useEffect(() => {
        if (store.associatedVesselId && !vessel.isOpen && store.dealId) {
            store.loadDeal(store.dealId)
        }
    }, [vessel.isOpen])

    useEffect(() => {
        if (!pricingDialogStore.isOpen)
            store.loadDeal(store.dealId)
    }, [pricingDialogStore.isOpen])

    let closeConfirmationPopup = () => {
        store.setUnAssignConfirmDialogOpen(false)
        store.loadDeal(store.dealId)
    }

    let canApplyForecast = () => store.isSaleOnTruckDeal()

    let onClickDealForecastMarketingSales = async () => {
        if (await save())
            store.forecastMktSales({ dealId: store.dealId! })
    }

    let handleStatusChange = (index: number) => {
        if (!store.deal) return
        let newStatus = ''

        switch (index) {
            case 0: newStatus = DealStatus.Mandate; break;
            case 1: newStatus = DealStatus.Negociated; break;
            case 2: newStatus = DealStatus.Closed; break;
            case 3: newStatus = DealStatus.Cancelled; break;
        }

        store.setDeal({ ...store.deal, status: newStatus.toString() })
    }

    let getStepIndexByStatus = (status: string) => {
        switch (status) {
            case DealStatus.Mandate: return 0
            case DealStatus.Negociated: return 1
            case DealStatus.Closed: return 2
            case DealStatus.Cancelled: return 3
            default: return -1
        }
    }

    let isFormulaGenericPricing = store.deal?.pricingType == 'Formula' && hasFeature('GenericPricing')

    let canAddMovement = () =>
        store.canAssignMovement()
        && !store.isInternal()
        && !store.isSaleOnTruckDeal()
        && !store.canForecastDailyPurchases()
        && !store.isPaperTransaction()
        && store.hasActiveStatus()

    return (
        <>
            <Popup title={t('deals.popup.title')}
                close={handleClose}
                isOpen={dealDialogStore.isOpen}
                buttons={buttons}>
                <div>
                    {store.deal && dealDialogStore.isOpen ?
                        <>
                            {hasFeature('DealStatus') &&
                                <Paper className={classes.dealStatusInformation}>
                                    <div className={classes.stepper}>
                                        <Stepper steps={['', '', '']}
                                            icons={[DealStatus.Mandate, DealStatus.Negociated, DealStatus.Closed]
                                                .map(x => <DealStatusChip key={x} status={x} disabled={store.deal!.status !== x} hoverable />)
                                            }
                                            activeStep={getStepIndexByStatus(store.deal!.status?.toString())}
                                            ignoreStepStyles
                                            classesOverride={{ root: classes.dealStepper }}
                                            onStepChange={(index: number) => handleStatusChange(index)} />
                                    </div>
                                    <div className={classes.cancelledDeal}>
                                        <Stepper steps={['']}
                                            icons={[
                                                <DealStatusChip key={DealStatus.Cancelled} status={DealStatus.Cancelled} disabled={store.deal!.status !== DealStatus.Cancelled} hoverable />]}
                                            activeStep={getStepIndexByStatus(store.deal!.status?.toString())}
                                            ignoreStepStyles
                                            classesOverride={{ root: classes.dealStepper }}
                                            onStepChange={() => handleStatusChange(3)} />
                                    </div>
                                </Paper>
                            }
                            <Paper className={classes.dealInformation}>
                                <div className={classes.buttonDiv}>
                                    <TitleSection />
                                    <div className={classes.headerRight}>
                                        <div className={classes.headerText}>
                                            <FormControlLabel
                                                control={<Checkbox onChange={x => store.setDeal({ ...store.deal!, signed: !store.deal!.signed })}
                                                    checked={store.deal!.signed} />}
                                                label={t('deals.label.signed')}
                                            />
                                        </div>
                                        {canApplyForecast() && !store.isInternal()
                                            ? store.deal?.generateMktSaleForecasts
                                                ? <Typography variant='h6' className={classes.forecastCreatedText}>{t('deals.label.marketingSalesForecastCreated')}</Typography>
                                                : <CustomButton
                                                    label={t('deals.label.forecastMarketingSales')}
                                                    className={classes.secondaryButtonForecast}
                                                    onClick={onClickDealForecastMarketingSales} />
                                            : null
                                        }
                                        {store.canForecastDailyPurchases() && <>
                                            {!store.deal || !store.deal?.movements || store.deal?.movements?.length == 0
                                                ? <CustomButton
                                                    label={t('deals.label.forecastDailyPurchases')}
                                                    onClick={() => store.forecastDailyPurchases()}
                                                    className={classes.secondaryButtonForecast} />
                                                : <Typography className={classes.boldHeaderText} variant='overline'>
                                                    {t('deals.label.forecastDailyPurchasesCreated')}
                                                </Typography>
                                            }</>
                                        }
                                        <AttachFileButton id='upload-doc-button'
                                            className={classes.documentButton}
                                            title={t('deals.label.documentDialogTitle')}
                                            disableDelOrAdd={!hasClaim(Claims.DealManager)}
                                            context={store.deal?.company}
                                            keyTemplate='deal-{dealId}'
                                            keyParameters={{ dealId: store.dealId ? store.dealId.replace(/-/g, '') : '' }} />
                                    </div>
                                </div>
                                <ReferenceNumberSection />
                                <SiteSection />
                                <VolumeSection />
                                <TolerancesSection />
                                <CommentSection />
                                {store.associatedVesselId !== null
                                    ? <Button onClick={openVessel}
                                        label={t('deals.popup.goToVessel')}
                                        img={<Icons.DirectionsBoatOutlined className={classes.buttonPictogram} />}
                                        className={classes.buttonToVessel} />
                                    : null}
                                {store.deal?.mirrorDealId
                                    ? <Button
                                        label={t('deals.popup.openMirrorDeal')}
                                        onClick={() => dealDialog.open(store.deal?.mirrorDealId || '')}
                                        className={classes.secondaryButton} /> : null}
                                {canAddMovement() &&
                                    <>
                                        <Button
                                            label={t('deals.label.newMovement')}
                                            onClick={() => setShowPopupCreateMovement(true)}
                                            className={classes.secondaryButton} />
                                        <Component.AssignMovementButton buttonClass={classes.secondaryButton} />
                                    </>
                                }
                            </Paper>
                            {store?.deal &&
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={store.openSections['pricingInformation']} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<Icons.ExpandMore />} onClick={() => store.toggleSection('pricingInformation')}>
                                        <PricingFirstLine />
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetail}>
                                        <Paper elevation={0}>
                                            <PricingSecondLine />
                                            {!isFormulaGenericPricing && <PricingThirdLine />}
                                            {hasFeature('Pricing') && !isFormulaGenericPricing &&
                                                <Button onClick={openPricing}
                                                    label={t('deals.popup.goToPricing')}
                                                    img={<Icons.GavelOutlined className={classes.buttonPictogram} />}
                                                    className={classes.buttonToVessel} />
                                            }
                                        </Paper>
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {store.deal && !store.isInternal() && <ValidationProcess />}
                            {store?.deal && !store.isInternal() && !store.isPaperTransaction() &&
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={store.openSections['sap']} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<Icons.ExpandMore />} onClick={() => store.toggleSection('sap')}>
                                        <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                                            {t('deals.popup.sapTitle')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetail}>
                                        <DealSapForm className={classes.dealInformation} />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {!!store?.deal?.movements?.length && !store.isInternal() && !store.isPaperTransaction() &&
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={store.openSections['movements']}
                                    className={classes.accordion + ' ' + classes.lastSection}>
                                    <AccordionSummary expandIcon={<Icons.ExpandMore />} onClick={() => store.toggleSection('movements')}>
                                        <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                                            {t('deals.label.movements')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetail}>
                                        <Paper elevation={0}>
                                            <DealMovements classes={classes} />
                                        </Paper>
                                    </AccordionDetails>
                                </Accordion>
                            }
                        </>
                        : <></>}
                </div>
            </Popup>
            <CustomDialog isOpen={duplicateConfirmDialogOpen} title={t('deals.label.confirmTitle')}
                contentText={t('deals.label.confirmDuplicate')}
                confirmButtonText={t('deals.label.duplicate')}
                onConfirm={async () => {
                    setDuplicateConfirmDialogOpen(false)
                    await handleDuplicateDebouncer.execute()
                }}
                onClose={() => setDuplicateConfirmDialogOpen(false)}
                onCancel={() => setDuplicateConfirmDialogOpen(false)} />
            <CustomDialog isOpen={showPopupCreateMovement} title={t('deals.label.confirmTitle')}
                contentText={t('deals.label.confirmMovementCreation')}
                confirmButtonText={t('deals.label.createMovement')}
                onConfirm={async () => {
                    setShowPopupCreateMovement(false)
                    await handleCreateMovementDebouncer.execute()
                }}
                onClose={() => setShowPopupCreateMovement(false)}
                onCancel={() => setShowPopupCreateMovement(false)} />
            <CustomDialog isOpen={store.assignConfirmDialogOpen} title={t('deals.label.confirmAssignTitle')}
                contentText={t('deals.label.confirmAssign')}
                confirmButtonText={t('deals.label.assign')}
                onConfirm={async () => {
                    store.setAssignConfirmDialogOpen(false)
                    await handleAssignMovementsDebouncer.execute()
                    store.loadDeal(store.dealId)
                }}
                onClose={closeConfirmationPopup}
                onCancel={closeConfirmationPopup} />
            <CustomDialog isOpen={store.unAssignConfirmDialogOpen} title={t('deals.label.confirmUnassignTitle')}
                contentText={t('deals.label.confirmUnAssign')}
                confirmButtonText={t('deals.label.unAssign')}
                onConfirm={async () => {
                    store.setUnAssignConfirmDialogOpen(false)
                    if (store.dealMovement)
                        await handleUnAssignMovementsDebouncer.execute(store.dealMovement.movementId)
                    store.loadDeal(store.dealId)
                }}
                onClose={closeConfirmationPopup}
                onCancel={closeConfirmationPopup} />
        </>
    )
}

let styles = theme =>
    createStyles({
        dealInformation: {
            padding: '0.5em',
            marginTop: '1em',
            marginBottom: '0.5em',
        },
        buttonDiv: {
            ...defaultStyles.flexRow,
            width: '100%',
            justifyContent: 'space-between'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginLeft: '-0.5em'
        },
        buttonToVessel: {
            ...defaultStyles.primaryButton,
            marginTop: '2em',
            marginRight: '1em'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            marginTop: '2em',
            marginLeft: '0.5em'
        },
        buttonPictogram: {
            marginRight: '0.5em'
        },
        documentButton: {
            marginRight: '0.5em'
        },
        accordionDetail: {
            flexDirection: 'column',
            marginTop: '-1em'
        },
        accordion: {
            padding: '0.5em',
            marginTop: '1em',
            marginBottom: '1em',
            borderRadius: '4px'
        },
        lastSection: {
            marginBottom: '1.5em !important'
        },
        headerRight: {
            ...defaultStyles.flexRow
        },
        boldHeaderText: {
            marginRight: '1em',
            fontWeight: 800,
            color: defaultColors.darkBlue.light.color
        },
        headerText: {
            marginRight: '0.7em'
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center'
        },
        iconStyle: { height: '1.5rem', width: '1.5rem', marginTop: '-0.2rem' },
        invisible: { opacity: 0 },
        red: { color: defaultColors.red.light.color },
        grey: { color: defaultColors.grey.main.color },
        black: { color: 'black' },
        green: { color: defaultColors.green.main.color },
        textSubTextContainer: {
            ...defaultStyles.flexColumn,
            alignItems: 'flex-start'
        },
        subText: {
            fontSize: '0.85em',
            fontStyle: 'Italic',
            color: defaultColors.grey.main.color
        },
        sapChipWithValue: {
            borderRadius: '7px',
            backgroundColor: '#95e379',
            color: defaultColors.green.main.color,
            marginLeft: '0.2em',
            marginRight: '0.2em',
        },
        secondaryButtonForecast: {
            ...defaultStyles.secondaryButton,
            marginRight: '1em'
        },
        forecastCreatedText: {
            marginRight: '1em'
        },
        dealStepper: {
            backgroundColor: 'white'
        },
        stepper: { width: '90% ' },
        cancelledDeal: { width: '10% ' },
        dealStatusInformation: {
            padding: '0.5em',
            marginTop: '1em',
            marginBottom: '0.5em',
            display: 'flex'
        }
    })

export default withStyles(styles, muiOptions)(DealEdit)
