import { defaultColors } from '../../materialUiThemeProvider'

export const globalStyles = {
    bell: {
    },
    jiggleBell: {
        animation: '$jiggle 10000ms'
    },
    dropdown: {
        width: '25rem',
        boxSizing: 'border-box',
        boxShadow: '0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23)',
        borderRadius: '1.5%',
        color: defaultColors.grey.dark.color,
        ' & > div': {
            borderBottom: 'solid 1px #F8F8F8',
            '&:last-child': {
                borderBottom: 'none'
            }
        }
    },
    dropdownWithScrollBar: {
        height: '60em',
        overflowY: 'auto'
    },
    empty: {
        width: '100%',
        padding: '1em',
        fontSize: '1rem',
        fontWeight: 400,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-around'
    },
    line: {
        width: '100%',
        padding: '1em',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '1rem',
        boxSizing: 'border-box',
        cursor: 'pointer',
        position: 'relative',
        color: defaultColors.grey.dark.color,
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            '& > div': {
                height: '1.5em'
            }
        },
        '&:hover': {
            backgroundColor: '#FBFBFB'
        },
        '&.error': {
            borderLeft: 'solid 4px rgb(244, 67, 54, 0.8)'
        },
        '&.warning': {
            borderLeft: 'solid 4px rgb(255, 152, 0, 0.8)'
        },
        '&.info': {
            borderLeft: 'solid 4px rgb(185, 185, 185, 0.8)'
        }
    },
    lineLeft: {
        alignItems: 'flex-start',
    },
    lineLeftTitle: {},
    lineLeftSubTitle: {
        fontSize: '0.7em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '18em'
    },
    lineRight: {
        alignItems: 'flex-end',
        color: '#B7B7B7',
        fontSize: '0.8em',
        fontStyle: 'italic',
        fontWeight: 400
    },
    help: {
        position: 'absolute',
        right: '0',
        bottom: '0',
        color: '#B7B7B7',
        width: '0',
        height: '0',
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        transitionTimingFunction: 'linear',
        transitionProperty: 'height, width, padding, background-color',
        transitionDuration: '0s, 0s, 0s, .2s',
        transitionDelay: '.3s, .3s, .3s, 0.1s',
        '&:hover': {
            width: '100%',
            height: '100%',
            backgroundColor: '#FBFBFB',
            transitionDelay: '0s, 0s, 0s, 0s',
            padding: '1em'
        },
        '&:hover $helpIcon': {
            color: defaultColors.lightBlue.light.color,
            transform: 'scale(1.2, 1.2)'
        },
        '&:hover $helpText': {
            opacity: 1,
            visibility: 'visible',
            transitionDelay: '0.1s, 0.1s, 0.1s',
            height: '100%'
        }
    },
    helpText: {
        height: '100%',
        fontSize: '0.8em',
        fontWeight: 400,
        visibility: 'hidden',
        opacity: 0,
        transition: 'opacity .2s, visibility 0s, height 0s',
        transitionDelay: '0s, .3s, .3s',
        pointerEvents: 'none',
        textOverflow: 'clip',
        overflow: 'hidden',
        wordWrap: 'break-word'
    },
    helpIcon: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        marginBottom: '-.3em',
        marginRight: '.8em',
        transition: 'transform 0.2s',
        '& svg': {
            width: '.6em',
            height: '.6em'
        }
    },
    '@keyframes jiggle': {
        '0%': {
            transform: 'scale(1) rotate(0)'
        },
        '75%': {
            transform: 'scale(1) rotate(0)'
        },
        '80%': {
            transform: 'scale(1.2) rotate(20deg)',
        },
        '85%': {
            transform: 'scale(1.2) rotate(-20deg)',
        },
        '90%': {
            transform: 'scale(1.2) rotate(20deg)',
        },
        '95%': {
            transform: 'scale(1.2) rotate(-20deg)',
        },
        '100%': {
            transform: 'scale(1) rotate(0)'
        }
    }
} as any

export const badgeStyles = {
    container: {
        position: 'relative',
    },
    badge: {
        right: '0',
        top: '0.3em',
        position: 'absolute',
        width: '1.3em',
        height: '1.3em',
        borderRadius: '50%',
        pointerEvents: 'none',
        userSelect: 'none',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        color: 'white',
        '&.error': {
            backgroundColor: '#f44336'
        },
        '&.warning': {
            backgroundColor: '#ff9800'
        },
        '&.info': {
            backgroundColor: '#B9B9B9',
        }
    },
    badgeFont: {
        fontSize: '0.6rem',
        fontFamily: 'Roboto',
        fontWeight: 500
    },
    child: {
        zIndex: 1
    }
} as any