import React from 'react'
import { Switch } from '../../../../common/customComponents'
import { hasFeature } from '../../../../../infrastructure/feature'
import { MovementType } from '../../../stockModels'
import { StockMovementContainer } from '../../stockMovementStore'

type TemperatureRefObsSwitchProps = {
    unit: string | undefined
    className: any
    isMainSapFlow: boolean
}

export function TemperatureRefObsSwitch({ unit, className, isMainSapFlow }: TemperatureRefObsSwitchProps) {
    let store = StockMovementContainer.useContainer()
    let sapFlow = store.getSapFlow(isMainSapFlow)

    if (sapFlow.movementType == MovementType.Sale || sapFlow.movementType == MovementType.Borrow || sapFlow.movementType == MovementType.Loan
        || !hasFeature('GoodsMovementWithTemperatureObserved')
        || !hasFeature('ObservedQuantityTemperature')
        || !unit
        || unit == 'TO'
        || unit == 'KG')
        return (<></>)

    let offLabel = hasFeature('VolumesInLiter') ? unit.replace('M', 'L') : unit
    let onLabel = hasFeature('VolumesInLiter') ? 'L' : 'M3'


    let changeCallback = () => {
        store.setSapFlow(isMainSapFlow, { ...sapFlow, useObservedTemperatureInSap: !sapFlow.useObservedTemperatureInSap })
    }

    return (
        <div className={className ?? ''}>
            <Switch
                form
                changeCallback={changeCallback}
                isChecked={sapFlow.useObservedTemperatureInSap}
                offText={offLabel}
                onText={onLabel} />
        </div>
    )
}
