import React, { useState } from 'react'
import { withStyles, createStyles, Typography, Tooltip } from '@material-ui/core'
import moment from 'moment'
import * as Icons from '@material-ui/icons'
import { t } from '../../infrastructure/i18nextHelper'
import { vesselDialog } from './vesselEdit/vesselEditStore'
import { TableVesselListItem, VesselListItem, vesselDatesFieldsNames, vesselDatesOverridesFieldsNames, VesselEditChangedDate } from './vesselModels'
import { DataTable, ColumnDescriptor } from '../common/customComponents'
import { Claims } from '../../infrastructure/signIn/models'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { createVesselTransportDialog } from './createVesselTransport'
import { ActionDescriptor } from '../common/components/table/table'
import { VesselContainer } from './vesselStore'
import { defaultColors, defaultStyles, muiOptions, MuiProps } from '../../infrastructure/materialUiThemeProvider'
import { historyDialog } from '../common/history/dialog'
import { AddOutlined, RemoveOutlined } from '@material-ui/icons'
import { VesselStatusChip } from './statusChip'

type VesselTableProps = {
    loadVessels: () => void
    classes: any
} & MuiProps

function VesselTable(props: VesselTableProps) {
    let store = VesselContainer.useContainer()

    let onSelect = (vessel: VesselListItem) => {
        vesselDialog.open(vessel.id)
    }

    let linkVessels = async (selectedVessels: VesselListItem[]) => {
        if (selectedVessels.length === 0) return
        createVesselTransportDialog.open(selectedVessels)
    }

    let onDeleteVessels = (selectedVessels: VesselListItem[]) => {
        store.setToDeleteVessels(selectedVessels)
        store.setDeleteVesselConfirmIsOpen(true)
    }

    let onItemOver = (vessel: VesselListItem) =>
        vessel.vesselTransportId !== store.vesselTransportHighlighted
        && store.setVesselTransportHighlighted(vessel.vesselTransportId)

    let onDataGridLeave = () =>
        store.setVesselTransportHighlighted
        && store.setVesselTransportHighlighted(null)

    let formatDate = (date: string | null) =>
        !!date ? moment(date).format('MM/DD') : ''

    let shouldDisplayDateErrors = () =>
        !!store.filters.jetty && (!store.filters.productId || !store.filters.productId.length)

    let addOrRemoveDay = (nbDay: number, item: TableVesselListItem, fieldName: keyof VesselListItem) => {
        let currentDateIndex = vesselDatesFieldsNames.findIndex(x => x === fieldName)

        propagateOnFollowingDates(nbDay, currentDateIndex, item)
        changeCurrentDate(nbDay, currentDateIndex, item, fieldName)

        store.updateTableVesselListItem(item)
    }

    let propagateOnFollowingDates = (nbDay: number, currentDateIndex: number, item: TableVesselListItem) => {
        let vesselDatesCount = vesselDatesFieldsNames.length

        for (let i = currentDateIndex; i < vesselDatesCount - 1; ++i) {
            let nextDateFieldName = vesselDatesFieldsNames[i + 1]
            let nextDateOverrideFieldName = vesselDatesOverridesFieldsNames[i + 1]

            if (nextDateOverrideFieldName) {
                let nextDateIsOverride = item[nextDateOverrideFieldName]
                let nextDateValue = item[nextDateFieldName]

                if (nextDateIsOverride || !nextDateValue) break

                let nextDate = moment(nextDateValue as string)
                let dayToAdd = nbDay > 0 ? 1 : -1
                item[nextDateFieldName] = nextDate.add(dayToAdd, 'days').toISOString() as never
            }
        }
    }

    let changeCurrentDate = (nbDay: number, currentDateIndex: number, item: TableVesselListItem, fieldName: keyof VesselListItem) => {
        let currentDateOverrideFieldName = vesselDatesOverridesFieldsNames[currentDateIndex]
        let itemDateValue = item[fieldName]

        if (!itemDateValue) return

        item.isModified = true

        let newDate = moment(itemDateValue as string)
            .add(nbDay, 'days')
            .toISOString()

        if (currentDateOverrideFieldName)
            item[currentDateOverrideFieldName] = true as never

        let editedVesselItemIndex = store.editedVesselItems.findIndex(x => x.vesselId === item.id)
        let changedDate: VesselEditChangedDate = { field: firstLetterToUpperCase(fieldName), value: newDate }

        if (editedVesselItemIndex >= 0) {
            let editedVesselItem = store.editedVesselItems[editedVesselItemIndex]
            let existingDateIndex = editedVesselItem.Dates.findIndex(x => x.field === fieldName)

            if (existingDateIndex >= 0) editedVesselItem.Dates[existingDateIndex] = changedDate
            else editedVesselItem.Dates.push(changedDate)

        }
        else
            store.editedVesselItems.push({ vesselId: item.id, Dates: [changedDate] })


        store.setEditedVesselItems([...store.editedVesselItems])

        item[fieldName] = newDate as never
    }

    let firstLetterToUpperCase = (str: string) => {
        if (str)
            return str.charAt(0).toUpperCase() + str.slice(1)
        return ""
    }

    let readModeColumns: ColumnDescriptor<VesselListItem>[] = [
        {
            name: ' ', htmlFor: x => x.vesselTransportId
                ? <Icons.LinkOutlined style={{ 'color': store.vesselTransportHighlighted == x.vesselTransportId ? defaultColors.darkBlue.light.color : '' }} />
                : <></>
        },
        { name: t('vessels.label.product.referenceNumber'), value: x => x.referenceNumber },
        { name: t('vessels.table.products'), value: x => x.products },
        { name: t('vessels.label.name'), value: x => x.name },
        { name: t('vessels.label.jetty'), value: x => x.jetty },
        { name: t('vessels.table.quantityShort'), alignRight: true, value: x => x.quantity != null ? (x.quantity + '') : null },
        { name: t('vessels.table.laycanShort'), value: x => formatDate(x.revisedLaycanStartDate) + ' - ' + formatDate(x.revisedLaycanEndDate) },
        {
            name: t('vessels.table.berthingShort'), value: x => formatDate(x.berthingDate),
            htmlFor: x => {
                return shouldDisplayDateErrors() && store.vesselErrorDates.find(d => d.vesselId == x.id)?.isEtbOnError
                    ? <Typography className={props.classes.errorField}><Icons.ErrorOutlineOutlined />{formatDate(x.berthingDate)}</Typography>
                    : <Typography>{formatDate(x.berthingDate)}</Typography>
            }
        },
        {
            name: t('vessels.table.completionOfDischargeShort'), value: x => formatDate(x.completionOfDischargeDate),
            htmlFor: x => {
                return shouldDisplayDateErrors() && store.vesselErrorDates.find(d => d.vesselId == x.id)?.isCodOnError
                    ? <Typography className={props.classes.errorField}><Icons.ErrorOutlineOutlined />{formatDate(x.completionOfDischargeDate)}</Typography>
                    : <Typography>{formatDate(x.completionOfDischargeDate)}</Typography>
            }
        },
        { name: t('vessels.table.availabilityShort'), value: x => formatDate(x.availabilityDate) },
        { name: '', value: () => '', htmlFor: x => <VesselStatusChip status={x.status} fontSize='small' /> }
    ]

    let editModeColumns: ColumnDescriptor<TableVesselListItem>[] = [
        {
            name: ' ', htmlFor: x => x.vesselTransportId
                ? <Icons.LinkOutlined style={{ 'color': store.vesselTransportHighlighted == x.vesselTransportId ? defaultColors.darkBlue.light.color : '' }} />
                : <></>
        },
        { name: t('vessels.label.product.referenceNumber'), value: x => x.referenceNumber },
        { name: t('vessels.table.products'), value: x => x.products },
        { name: t('vessels.label.name'), value: x => x.name },
        { name: t('vessels.label.jetty'), value: x => x.jetty },
        { name: t('vessels.table.quantityShort'), alignRight: true, value: x => x.quantity != null ? (x.quantity + '') : null },
        {
            name: t('vessels.table.laycanStartShort'), value: x => formatDate(x.revisedLaycanStartDate),
            htmlFor: x => <InteractiveDate item={x} fieldName={"revisedLaycanStartDate"} addOrRemoveDay={addOrRemoveDay} classes={props.classes} />
        },
        {
            name: t('vessels.table.laycanEndShort'), value: x => formatDate(x.revisedLaycanEndDate),
            htmlFor: x => <InteractiveDate item={x} fieldName={"revisedLaycanEndDate"} addOrRemoveDay={addOrRemoveDay} classes={props.classes} />
        },
        {
            name: t('vessels.table.etaShort'), value: x => formatDate(x.etaDate),
            htmlFor: x => <InteractiveDate item={x} fieldName={"etaDate"} addOrRemoveDay={addOrRemoveDay} classes={props.classes} />
        },
        {
            name: t('vessels.table.berthingShort'), value: x => formatDate(x.berthingDate),
            htmlFor: x => <InteractiveDate item={x} fieldName={"berthingDate"} addOrRemoveDay={addOrRemoveDay} classes={props.classes} />
        },
        {
            name: t('vessels.table.completionOfDischargeShort'), value: x => formatDate(x.completionOfDischargeDate),
            htmlFor: x => <InteractiveDate item={x} fieldName={"completionOfDischargeDate"} addOrRemoveDay={addOrRemoveDay} classes={props.classes} />
        },
        {
            name: t('vessels.table.availabilityShort'), value: x => formatDate(x.availabilityDate),
            htmlFor: x => <InteractiveDate item={x} fieldName={"availabilityDate"} addOrRemoveDay={addOrRemoveDay} classes={props.classes} />
        },
    ]

    let multiActions: ActionDescriptor<VesselListItem>[] = [
        {
            name: "Link", action: linkVessels,
            icon: <Tooltip title={<Typography variant='body1'>{t('vessels.table.link')}</Typography>} placement='top'><Icons.LinkOutlined /></Tooltip>
        },
        {
            name: "Unlink", action: store.unlinkVessels,
            icon: <Tooltip title={<Typography variant='body1'>{t('vessels.table.unlink')}</Typography>} placement='top'><Icons.LinkOffOutlined /></Tooltip>
        },
        {
            name: "Delete", action: onDeleteVessels,
            icon: <Tooltip title={<Typography variant='body1'>{t('vessels.table.delete')}</Typography>} placement='top'><Icons.DeleteOutlined /></Tooltip>
        }]

    let lineActions: ActionDescriptor<VesselListItem>[] = [
        {
            isBodyAction: true,
            icon: <Tooltip title={<Typography variant='body1'>{t('vessels.table.localizeVessel')}</Typography>} placement='left'><Icons.Room /></Tooltip>,
            name: 'Localize Vessel',
            action: (x: VesselListItem) => window.open(`https://www.vesselfinder.com/?imo=${x.internationalMaritimeOrganizationNumber}`),
            disabled: (x: VesselListItem) => !x.internationalMaritimeOrganizationNumber
        },
        {
            isBodyAction: true, icon: <Icons.History />,
            name: 'History', action: (x: VesselListItem) =>
                historyDialog.open({ id: x.id, type: 'vessel', name: `${x.name ?? ''}` }),
        }
    ]

    let tableActions = hasClaim(Claims.VesselWriter) || hasClaim(Claims.VesselManager) ? multiActions.concat(lineActions) : []

    return (
        <DataTable
            tableId={'vessel-table'}
            items={store.vessels}
            idSelector={(x: VesselListItem) => x.id}
            onSelectionChange={(x: TableVesselListItem[]) => store.setSelectedVessels(x)}
            onClick={store.tableEditMode ? undefined : onSelect}
            columns={store.tableEditMode ? editModeColumns : readModeColumns}
            actions={tableActions}
            onItemMouseEnter={onItemOver}
            onMouseLeave={onDataGridLeave} />
    )
}

type InteractiveDatesProps = {
    item: TableVesselListItem
    fieldName: keyof VesselListItem
    addOrRemoveDay: (nbDay: number, item: TableVesselListItem, fieldName: keyof VesselListItem) => void
    classes: any
} & MuiProps
let InteractiveDate = (props: InteractiveDatesProps) => {
    let date = props.item[props.fieldName] as string
    let isOverrided = props.item[props.fieldName + 'Overwritten']

    if (!date) return null

    return (
        <div className={props.classes.statusIconContainer + ' ' + props.classes.lowerEditModeIcons}>
            <div className={props.classes.statusIconDateContainer}>
                <div className={props.classes.statusIconDateButton} onClick={_ => props.addOrRemoveDay(-1, props.item, props.fieldName)}>
                    <RemoveOutlined />
                </div>
                <span style={isOverrided ? { fontWeight: 'bold' } : undefined} >{date ? moment(date).format('MM/DD') : ''}</span>
                <div className={props.classes.statusIconDateButton} onClick={_ => props.addOrRemoveDay(1, props.item, props.fieldName)}>
                    <AddOutlined />
                </div>
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        errorField: {
            color: defaultColors.red.light.color,
            display: 'flex'
        },
        grey: { color: defaultColors.grey.main.color },
        black: { color: 'black' },
        green: { color: defaultColors.green.main.color },
        statusIconDateContainer: {
            ...defaultStyles.flexRow,
            justifyContent: 'start',
            alignItems: 'center',
            marginTop: '-.4em',
            color: defaultColors.grey.main.color,
            '& > span': {
                margin: '0 .3em .3em .3em'
            },
            '&:hover': {
                '& $statusIconDateButton': {
                    opacity: '1'
                }
            },
            '& svg': {
                width: '0.5em',
                height: '0.5em'
            }
        },
        statusIconDateButton: {
            opacity: 0,
            cursor: 'pointer',
            transition: 'transform 0.2s',
            color: 'grey',
            '& > *': {
                height: '.7rem',
                width: '.7rem',
                padding: 0,
                margin: 0,
            },
            '&:hover': {
                color: defaultColors.lightBlue.light.color,
                transform: 'scale(1.3, 1.3)'
            }
        },
        lowerEditModeIcons: {
            marginTop: '.3em',
            marginBottom: '-.3em'
        },
    })

export default withStyles(styles, muiOptions)(VesselTable)