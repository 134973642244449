import React, { useState, useEffect } from 'react'
import { withStyles, createStyles, Typography, Checkbox } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, Select, TableItem, NumberField, TextField } from '../../common/customComponents'
import { MasterDataItem, MasterDataShell, createExcelLines } from './masterDataShell'

function CompanyMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [companys, setCompanys] = useState<TableItem<Company>[]>([])
    let [sapAliases, setSapAliases] = useState<TableItem<string>[]>([])
    let [filters, setFilters] = useState<CompanyFilters>(noFilters)

    const tBase = 'admin.masterdata.company.'

    let load = async () => {
        let companys = api.get<TableItem<Company>[]>('masterdata/company')
        setCompanys(await companys)

        let sapAliases = api.get<TableItem<string>[]>('masterdata/company/sapAliases')
        setSapAliases(await sapAliases)
    }

    let getItems = () => applyFilters(companys, filters).map(toTableItem)

    useEffect(() => {
        load()
    }, [])

    let onSave = async (item: Company) => {
        await api.post('masterdata/company', item)
        await load()
        return true
    }

    let onDelete = async (codes: string[]) => {
        if (codes.length === 0) return false
        await api.del('masterdata/company', { codes })
        await load()
        return true
    }

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'Companys.xlsx',
            sheets: [{ name: 'Companys', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let getBooleanLabel = (value: boolean): string => value
        ? t(tBase + 'isYes')
        : t(tBase + 'isNo')

    let columns: ColumnDescriptor<TableItem<MasterDataItem<Company>>>[] = [
        {
            name: t(tBase + 'code'),
            value: x => x.code,
            columnFilter: { value: filters.code ?? '', onChange: (code: string) => setFilters({ ...filters, code }) }
        },
        {
            name: t(tBase + 'name'),
            value: x => x.name,
            columnFilter: { value: filters.name ?? '', onChange: (name: string) => setFilters({ ...filters, name }) }
        },
        {
            name: t(tBase + 'country'),
            value: x => x.country,
            columnFilter: { value: filters.country ?? '', onChange: (country: string) => setFilters({ ...filters, country }) }
        },
        {
            name: t(tBase + 'color'),
            value: x => x.color,
            columnFilter: { value: filters.color ?? '', onChange: (color: string) => setFilters({ ...filters, color }) }
        },
        {
            name: t(tBase + 'order'),
            value: x => x.order.toString()
        },
        {
            name: t(tBase + 'isThirdParty'),
            value: x => getBooleanLabel(x.isThirdParty),
        },
        {
            name: t(tBase + 'sapServer'),
            value: x => x.sapServer,
        },
        {
            name: t(tBase + 'standardTemperature'),
            value: x => x.standardTemperature,
        },
        {
            name: t(tBase + 'isArchived'),
            value: x => getBooleanLabel(x.archive),
        },
        {
            name: t(tBase + 'currency'),
            value: x => x.companyCurrency,
        },
    ]

    let isManager = hasClaim(Claims.MasterdataCompanyManager);

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'company-table'}
                headerLabel={t(tBase + 'companys')}
                itemLabel={t(tBase + 'company')}
                isManager={isManager}
                onExportExcel={exportExcel}
                onNew={emptyCompany}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <TextField label={t(tBase + 'code')}
                                disabled={!isManager || !selectedItem.isNew}
                                text={selectedItem.code}
                                onChange={event => setSelectedItem({ ...selectedItem, code: event.target.value })} />
                            <TextField label={t(tBase + 'name')}
                                disabled={!isManager}
                                text={selectedItem.name}
                                onChange={event => setSelectedItem({ ...selectedItem, name: event.target.value })} />
                            <TextField label={t(tBase + 'country')}
                                disabled={!isManager || !selectedItem.isNew}
                                text={selectedItem.country}
                                onChange={event => setSelectedItem({ ...selectedItem, country: event.target.value.toUpperCase() })} />
                            <TextField label={t(tBase + 'color')}
                                disabled={!isManager}
                                text={selectedItem.color}
                                onChange={event => setSelectedItem({ ...selectedItem, color: event.target.value })} />
                            <NumberField label={t(tBase + 'order')}
                                disabled={!isManager}
                                text={selectedItem.order ? selectedItem.order : 0}
                                onChange={order => setSelectedItem({ ...selectedItem, order })} />
                            <div className={classes.isBooleanContainer}>
                                <Typography variant='subtitle1'>{t(tBase + 'isThirdParty')}</Typography>
                                <Checkbox checked={selectedItem.isThirdParty} onChange={x => setSelectedItem({ ...selectedItem, isThirdParty: x.target.checked })} />
                            </div>
                            <Select label={t(tBase + 'sapServer')}
                                disabled={!isManager}
                                value={selectedItem.sapServer}
                                choices={sapAliases.map(x => ({ value: x, text: x }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, sapServer: val }) }} />
                            <NumberField label={t(tBase + 'standardTemperature')}
                                disabled={!isManager}
                                text={selectedItem.standardTemperature}
                                onChange={val => setSelectedItem({ ...selectedItem, standardTemperature: val })} />
                            <div className={classes.isBooleanContainer}>
                                <Typography variant='subtitle1'>{t(tBase + 'isArchived')}</Typography>
                                <Checkbox checked={selectedItem.archive} onChange={x => setSelectedItem({ ...selectedItem, archive: x.target.checked })} />
                            </div>
                            <TextField label={t(tBase + 'currency')}
                                disabled={!isManager}
                                text={selectedItem.companyCurrency}
                                onChange={event => setSelectedItem({ ...selectedItem, companyCurrency: event.target.value.toUpperCase() })} />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

type Company = {
    code: string
    name: string
    country: string
    color: string
    order: number
    isThirdParty: boolean
    sapServer: string | null
    standardTemperature: number | null
    archive: boolean
    companyCurrency: string
}

type IsNew = { isNew: boolean }

let emptyCompany = (): Company & IsNew => ({
    code: '',
    name: '',
    country: '',
    color: '',
    order: 1,
    isThirdParty: false,
    sapServer: '',
    standardTemperature: null,
    archive: false,
    isNew: true,
    companyCurrency: ''
})

let toTableItem = (company: Company): TableItem<MasterDataItem<Company>> => {
    return {
        id: company.code,
        code: company.code,
        name: company.name,
        country: company.country,
        color: company.color,
        order: company.order,
        isThirdParty: company.isThirdParty,
        sapServer: company.sapServer,
        standardTemperature: company.standardTemperature,
        archive: company.archive,
        isModified: false,
        companyCurrency: company.companyCurrency
    }
};

type CompanyFilters = {
    code: string | null,
    name: string | null,
    country: string | null,
    color: string | null,
}

let noFilters: CompanyFilters = {
    code: null,
    name: null,
    country: null,
    color: null,
}

const filterByInput = (prop: string, input: string) => (filter: any) => filter[prop].toLowerCase().contains(input.toLowerCase());

let applyFilters = (companys: Company[], filters: CompanyFilters): Company[] => {
    for (const input in filters) {
        if (filters[input])
            companys = companys.filter(filterByInput(input, filters[input]))
    }
    return companys
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        isBooleanContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-between',
            "& >span": { padding: '0' }
        }
    })
export default withStyles(styles, muiOptions)(CompanyMasterData)