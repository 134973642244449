import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { withStyles, createStyles, Paper } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import { Site, Company, ChannelCustomerSegment, MarketSalesFilters, SupplyProduct } from './models'
import { MultipleSelect, MonthPicker, GroupedMultiSelect } from '../common/customComponents'
import { MktSalesContainer } from './mktSalesStore'
import * as api from './../../infrastructure/api'
import { MktSaleDemandPlan } from './_mktSalesDemandPlan'
import { hasFeature } from '../../infrastructure/feature'
import { setInLocalStorage } from '../../infrastructure/localStorage'

function _MktSalesFilters({ classes }: MuiProps) {
    let store = MktSalesContainer.useContainer()
    let [availableCompanies, setAvailableCompanies] = useState<Company[]>([])
    let [availableChannels, setAvailableChannels] = useState<ChannelCustomerSegment[]>([])
    let [isFirstLoading, setIsFirstLoading] = useState<boolean>(true)

    let selectedChannels = store.channelCustomerSegments
        .filter(x => store.mktSalesFilters.customerSegments?.find(y => y === x.customerSegment))
        .map(x => x.channel)
        .distinct()

    let getCompaniesChoicesDependingOnSelectedChannels = (companies: Company[], channelCustomerSegments: ChannelCustomerSegment[]) => {
        if (!!store.mktSalesFilters.customerSegments && store.mktSalesFilters.customerSegments?.length > 0 && !!channelCustomerSegments) {
            let availableCompanies: Company[] = []
            selectedChannels.forEach(x => {
                channelCustomerSegments.filter(z => z.channel === x).forEach(y => {
                    let companys = companies.filter(c => c.code === y.company)
                    if (!!companys)
                        companys.forEach(r => availableCompanies.push(r))
                })
            })
            return availableCompanies.distinct()
        }
        return companies
    }

    let getChannelsAccordingToSelectedCompanies = (companies: Company[], channelCustomerSegments: ChannelCustomerSegment[]) => {
        if (!!store.mktSalesFilters.companies && store.mktSalesFilters.companies?.length > 0 && !!companies) {
            let availableChannels: ChannelCustomerSegment[] = []
            store.mktSalesFilters.companies.forEach(x => {
                companies.filter(z => z.code === x).forEach(y => {
                    let channels = channelCustomerSegments.filter(c => c.company === y.code)
                    if (!!channels)
                        channels.forEach(r => availableChannels.push(r))
                })
            })
            return availableChannels.distinct()
        }
        return channelCustomerSegments
    }

    let isSelectedChannelIsCorrectAccordingToSelectedCompanies = () => {
        let isOk = true
        if (!!store.mktSalesFilters.customerSegments && !!store.mktSalesFilters.companies
            && store.mktSalesFilters.companies?.length > 0 && store.mktSalesFilters.customerSegments?.length > 0) {
            let correctChannels = getChannelsAccordingToSelectedCompanies(store.companies, store.channelCustomerSegments)
            selectedChannels.forEach(x => {
                if (correctChannels.map(c => c.channel).indexOf(x) === -1) isOk = false
            })
        }
        return isOk
    }

    let isSelectedCompanyIsCorrectAccordingToSelectedChannel = () => {
        let isOk = true
        if (!!store.mktSalesFilters.companies && !!store.mktSalesFilters.customerSegments
            && store.mktSalesFilters.customerSegments?.length > 0 && store.mktSalesFilters.companies?.length > 0) {
            let correctCompanies = getCompaniesChoicesDependingOnSelectedChannels(store.companies, store.channelCustomerSegments)
            store.mktSalesFilters.companies.forEach(x => {
                if (correctCompanies.map(c => c.code).indexOf(x) === -1) isOk = false
            })
        }
        return isOk
    }

    let saveFiltersInLocalStorage = () => {
        let filtersLength = Object.keys(store.mktSalesFilters).length

        if (filtersLength > 0)
            setInLocalStorage('mktSalesFilters_v2', store.mktSalesFilters)
    }

    let setDefaultFiltersValues = (sites: Site[], products: SupplyProduct[], customerChannelSegments: ChannelCustomerSegment[], companies: Company[]) => {
        let changes: MarketSalesFilters = {}
        if (!store.mktSalesFilters.site && sites.length > 0)
            changes = { ...changes, site: sites.filter(x => x.siteGroup === sites[0].siteGroup).map(x => x.code) }
        if (!store.mktSalesFilters.productId && products.length > 0)
            changes = { ...changes, productId: [products.first().id] }
        if (!store.mktSalesFilters.companies && companies.length > 0)
            changes = { ...changes, companies: [companies.first().code] }
        if (!store.mktSalesFilters.customerSegments && customerChannelSegments.length > 0)
            changes = { ...changes, customerSegments: [] }
        if (Object.keys(changes).length > 0)
            store.setMktSalesFilters({ ...store.mktSalesFilters, ...changes })
    }

    let onChangeCompanies = (selectedCompaniesCode: string[]) => {
        let companiesCode = store.companies
            .filter(x => selectedCompaniesCode.indexOf(x.code) > -1)
            .map(x => x.code)
            .distinct()
        store.setMktSalesFilters({ ...store.mktSalesFilters, companies: companiesCode })
    }

    useEffect(() => {
        if (!isFirstLoading) {
            setAvailableCompanies(getCompaniesChoicesDependingOnSelectedChannels(store.companies, store.channelCustomerSegments))
            if (isSelectedCompanyIsCorrectAccordingToSelectedChannel() === false)
                store.setMktSalesFilters({ ...store.mktSalesFilters, companies: null })
        }
    }, [store.mktSalesFilters.customerSegments])

    useEffect(() => {
        if (!isFirstLoading) {
            setAvailableChannels(getChannelsAccordingToSelectedCompanies(store.companies, store.channelCustomerSegments))
            if (isSelectedChannelIsCorrectAccordingToSelectedCompanies() === false)
                store.setMktSalesFilters({ ...store.mktSalesFilters, customerSegments: null })
        }
    }, [store.mktSalesFilters.companies])

    useEffect(() => {
        saveFiltersInLocalStorage()
        store.loadMktSaleMovements()
    }, [store.mktSalesFilters])

    useEffect(() => {
        let isMounted = true
        load()
        return () => { isMounted = false }

        async function load() {
            let products = await api.get<SupplyProduct[]>('stock/movement/mktSale/product')
            let companies = await api.get<Company[]>('stock/movement/mktSale/company')
            let sites = await api.get<Site[]>('stock/movement/mktSale/site')
            let channelCustomerSegments = await api.get<ChannelCustomerSegment[]>('stock/movement/mktSale/channelCustomerSegment')

            store.setChannelCustomerSegments(channelCustomerSegments)
            store.setSites(sites)
            store.setCompanies(companies)
            store.setProducts(products)

            if (isMounted)
                ReactDOM.unstable_batchedUpdates(() => {
                    setDefaultFiltersValues(sites, products, channelCustomerSegments, companies)
                    setAvailableCompanies(companies)
                    setAvailableChannels(channelCustomerSegments)
                    setIsFirstLoading(false)
                })
        }
    }, [])

    return (
        <div className={classes.bar}>
            {hasFeature('ShortageManagementPlanned') && hasFeature('MktSalesDemandManagement') &&
                <Paper>
                    <MktSaleDemandPlan />
                </Paper >
            }
            <Paper className={classes.paperFilter}>
                <MonthPicker date={store.mktSalesFilters?.month || null} label={t('mktSales.forecast.month')} disablePast={false}
                    setDate={newDate => store.setMktSalesFilters({ ...store.mktSalesFilters, month: newDate })} disableNewStyle />

                <MultipleSelect label={t('mktSales.forecast.company')} classesOverride={{ form: classes.filterField }}
                    values={availableCompanies.filter(x => !!store.mktSalesFilters.companies && store.mktSalesFilters.companies.indexOf(x.code) > -1).map(x => x.code)}
                    choices={availableCompanies.map(x => ({ value: x.code, text: x.name }))} disableNewStyle
                    onChange={onChangeCompanies} />

                {store.products.every(x => !x.productGroup)
                    ? <MultipleSelect label={t('mktSales.forecast.product')} classesOverride={{ form: classes.filterField }}
                        values={store.products.filter(x => !!store.mktSalesFilters.productId && store.mktSalesFilters.productId.indexOf(x.id) > -1).map(x => x.id)}
                        choices={store.products.map(x => ({ value: x.id, text: x.code }))} disableNewStyle
                        allWhenEmpty={true} hideOnSingleChoice
                        onChange={x => store.setMktSalesFilters({ ...store.mktSalesFilters, productId: x })} />
                    : <GroupedMultiSelect label={t('mktSales.forecast.product')} classesOverride={{ form: classes.filterField }}
                        values={store.mktSalesFilters.productId ?? []} hideOnSingleChoice allWhenEmpty
                        choices={store.products.map(x => { return { value: x.id, text: x.code, group: x.productGroup! } })} disableNewStyle
                        onChange={val => store.setMktSalesFilters({ ...store.mktSalesFilters, productId: val })} />
                }

                <GroupedMultiSelect
                    label={t('mktSales.forecast.site')}
                    classesOverride={{ form: classes.filterField }}
                    values={store.mktSalesFilters.site ?? []}
                    choices={store.sites.map(x => { return { value: x.code, text: x.name, group: x.siteGroup } })}
                    allWhenEmpty={true} disableNewStyle
                    onChange={x => store.setMktSalesFilters({ ...store.mktSalesFilters, site: x })} />

                <GroupedMultiSelect
                    label={t('mktSales.forecast.channel')}
                    classesOverride={{ form: classes.filterField }}
                    values={store.mktSalesFilters.customerSegments ?? []}
                    choices={deepDistinct(availableChannels.map(x => { return { value: x.customerSegment, text: x.customerSegment, group: x.channel } }))}
                    allWhenEmpty={true} disableNewStyle
                    onChange={x => store.setMktSalesFilters({ ...store.mktSalesFilters, customerSegments: x })} />
            </Paper>
        </div>
    )
}

function deepDistinct<T>(array: T[]): T[] {
    return array.map(x => JSON.stringify(x)).distinct().map(x => JSON.parse(x))
}

let styles = _ =>
    createStyles({
        paperFilter: {
            height: 'auto',
            padding: '0.5em',
        },
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        },
        filterFieldDate: {
            width: '10.3em',
            margin: '0em 0.5em'
        },
        bar: {
            height: '4em',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            '& > *': {
                height: '100%',
                padding: '0em 0.3em',
                display: 'flex',
                alignItems: 'center',
                marginRight: '1em',
                '&:last-child': {
                    marginRight: '0em'
                }
            },
        }
    })

export let MktSalesFilters = withStyles(styles, muiOptions)(_MktSalesFilters)