import { useState, useEffect } from 'react'
import { Subject, interval } from 'rxjs'
import { switchMap, filter } from 'rxjs/operators'
import { debounce, map } from 'rxjs/operators'
import { createContainer } from 'unstated-next'
import moment from 'moment'
import { history } from '../../infrastructure/navigation'
import { Guid } from '../../infrastructure/guid'
import * as api from '../../infrastructure/api'
import { Claims } from '../../infrastructure/signIn/models'
import { hasClaim, UserContextContainer } from '../../infrastructure/signIn/userContext'
import {
    MovementListItem, MovementType, StockFilters, StockProduct, DutyStatus, Company, Site, StockProjectionResult,
    MovementTypeNameFromMovementType, StockProjectionMode, StockSimulation, SapFlowStepName, SapFlowType,
    defaultMovementTypeSelectionOptions, MovementStatus, Counterparty
} from './stockModels'
import { hasFeature } from '../../infrastructure/feature'
import { SupplyBalanceFilter } from '../supplyBalance/supplyBalanceModels'
import { SapTypes } from './movementEdit/movementForms/sap/createInSap/formRequirements'
import { t } from '../../infrastructure/i18nextHelper'
import { setInLocalStorage } from '../../infrastructure/localStorage'
import { compareAndFixPeriod } from '../common/components/datePicker/tools'

export const defaultStartDate = () => moment.utc().startOf('month').format()
export const todayDate = () => moment.utc().format()
export const defaultEndDate = () => moment.utc().startOf('month').add(1, 'M').add(-1, 'day').format()

let inValue = 'in'
let outValue = 'out'

let constructSimulationQueryPart = (simulation: StockSimulation[]): string => {
    let query = ""
    for (let i = 0; i < simulation.length; i++)
        query += `stockSimulations[${i}].date=${simulation[i].date}&stockSimulations[${i}].value=${simulation[i].value}&stockSimulations[${i}].movementType=${simulation[i].movementType}&`
    return query
}

let constructFiltersStockProjectionQuery = (filters: StockFilters, simulation: StockSimulation[]) => {
    let query = ''
    let filterProperties = Object.keys(filters)
    let initializedFilters = filterProperties.filter(x => (filters[x] !== undefined &&
        filters[x] !== null && x !== 'companies' && x !== 'dutyStatuses' && x !== 'sites' && x !== 'productIds'
        && x !== 'allSites' && x !== 'allProductIds' && x !== 'allDutyStatuses' && x !== 'allCompanies'))
    initializedFilters.forEach(x => query += `${x}=${filters[x]}&`)

    if (filters.sites !== null && filters.sites.length !== 0)
        filters.sites.forEach(x => query += `site=${x}&`)
    else
        filters.allSites?.forEach(x => query += `site=${x}&`)

    if (filters.productIds !== null && filters.productIds.length !== 0)
        filters.productIds.forEach(x => query += `productIds=${x}&`)
    else
        filters.allProductIds?.forEach(x => query += `productIds=${x}&`)

    if (filters.dutyStatuses !== null && filters.dutyStatuses.length !== 0)
        filters.dutyStatuses.forEach(x => query += `dutyStatus=${x}&`)
    else
        filters.allDutyStatuses?.forEach(x => query += `dutyStatus=${x}&`)

    if (filters.companies !== null && filters.companies.length !== 0)
        filters.companies.forEach(x => query += `company=${x}&`)
    else
        filters.allCompanies?.forEach(x => query += `company=${x}&`)

    filters.types?.forEach(x => query += `type=${x}&`)
    query += constructSimulationQueryPart(simulation)

    return query
}

let createStockQuery = ({ stockFilters, stockMode, stockSimulations }) =>
    `stock/projection?mode=${stockMode}&${constructFiltersStockProjectionQuery(stockFilters, stockSimulations)}`

let createMovementQuery = ({ stockFilters, switchFilter, movementStatus, movementType, meanOfTansportation, textFilter, stockSimulations, nomination, deal }) => {
    let query = constructFiltersStockProjectionQuery(stockFilters, stockSimulations)
    query += `in=${switchFilter == inValue}&out=${switchFilter == outValue}`
    if (movementStatus !== null) query += `&movementStatus=${movementStatus}`
    if (movementType !== null) query += `&movementType=${movementType}`
    if (meanOfTansportation !== null) query += `&meanOfTansportation=${meanOfTansportation}`
    if (textFilter !== null) query += `&textFilter=${textFilter}`
    if (hasFeature('MktSalesInMovementTable')) query += '&withMktSale=true'
    if (nomination) query += `&nomination=${nomination}`
    if (deal) query += `&deal=${deal}`

    return 'stock/movement?' + query
}

let overrideWithDefaultDates = (): StockFilters => overrideWithSavedFilters({
    start: defaultStartDate(),
    end: defaultEndDate(),
    monthRange: 2,
    resellersRestrictionStart: todayDate(),
    resellersRestrictionEnd: defaultEndDate(),
    companies: null,
    dutyStatuses: null,
    productIds: null,
    sites: null,
    resellersRestrictionPercentage: null,
    types: ['Purchase', 'MktSale', 'Untriggered'],
    usefulStock: false,
    stockPercentHigh: false,
    allSites: [],
    allProductIds: [],
    allDutyStatuses: [],
    allCompanies: []
})

function overrideWithSavedFilters(baseFilter: StockFilters): StockFilters {
    let filters = localStorage.getItem('filters')

    if (filters) {
        let filterObj = JSON.parse(filters)
        let filtersLength = Object.keys(filterObj).length

        if (filtersLength === 0) return baseFilter

        let parseArray = x => Array.isArray(x) ? x : null

        return {
            ...baseFilter,
            start: filterObj.start,
            end: filterObj.end,
            dutyStatuses: parseArray(filterObj.dutyStatuses),
            productIds: parseArray(filterObj.productIds),
            companies: parseArray(filterObj.companies),
            sites: parseArray(filterObj.sites),
            monthRange: filterObj.monthRange,
            usefulStock: filterObj.usefulStock ?? baseFilter.usefulStock
        }
    }
    return baseFilter
}

type StockFiltersProp = { stockFilters: StockFilters, stockSimulations: StockSimulation[] }
type StockRxFilters = StockFiltersProp & { stockMode: StockProjectionMode }
type MovementRxFilters = StockFiltersProp & {
    switchFilter: string,
    movementStatus: MovementStatus | null,
    movementType: MovementType | null,
    meanOfTansportation: string | null,
    textFilter: string | null,
    nomination: string | null,
    deal: string | null,
}

let defaultStockProjectionResult: StockProjectionResult = {
    minVolumes: null,
    lowVolumes: null,
    highVolumes: null,
    maxVolumes: null,
    minDays: null,
    securityStockLines: null,
    lastCalibratedDate: null,
    values: null,
    lines: null
}

function useStockBoard() {
    let [stockQuerySubject] = useState(new Subject<StockRxFilters>())
    let [movementQuerySubject] = useState(new Subject<MovementRxFilters>())
    let [showActualStockPopup, setShowActualStockDisplay] = useState<boolean>(false)
    let [switchFilter, setSwitchFilter] = useState<string>(inValue)
    let [movements, setMovements] = useState<MovementListItem[]>([])
    let [products, setProducts] = useState<StockProduct[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [companies, setCompanies] = useState<Company[]>([])
    let [dutyStatuses, setDutyStatuses] = useState<DutyStatus[]>([])
    let [stockProjectionResult, setStockProjectionResult] = useState<StockProjectionResult>(defaultStockProjectionResult)
    let [stockFilters, setStockFilters] = useState<StockFilters>(overrideWithDefaultDates())
    let [movementTypes, setMovementTypes] = useState<string[]>([])
    let [selectedMovementType, setSelectedMovementType] = useState<MovementType | null>(MovementType.Purchase)
    let [mots, setMots] = useState<string[]>([])
    let [selectedMot, setSelectedMot] = useState<string | null>('InTank')
    let [statuses, setStatuses] = useState<string[]>([])
    let [selectedStatus, setSelectedStatus] = useState<MovementStatus | null>(MovementStatus.Forecast)
    let [textFilter, setTextFilter] = useState<string | null>('')
    let [stockMode, setStockMode] = useState<StockProjectionMode>(StockProjectionMode.Sum)
    let [filterDatesOnError, setFilterDatesOnError] = useState<boolean>(false)
    let [simulations, setSimulations] = useState<StockSimulation[]>([])
    let [tempFilters, setTempFilters] = useState<StockFilters | null>(null)
    let [stockDateFilter, setStockDateFilter] = useState<string | null>(null)
    let [stockDateFilterMovements, setStockDateFilterMovements] = useState<MovementListItem[]>([])
    let [unit, setUnit] = useState<string | undefined>()
    let [displayedBlocks, setDisplayedBlocks] = useState<('stockTable' | 'stockChart' | 'movementTable' | 'messageTable')[]>(['stockTable', 'stockChart', 'movementTable'])
    let [nominationFilter, setNominationFilter] = useState<string>('')
    let [dealFilter, setDealFilter] = useState<string>('')
    let [counterpartys, setCounterpartys] = useState<Counterparty[]>([])

    const inMovements = [MovementType.Purchase, MovementType.Transfer, MovementType.StatusChange, MovementType.Rebranding, MovementType.Borrow, MovementType.Gains, MovementType.Untriggered]
    const outMovements = [MovementType.Sale, MovementType.MktSale, MovementType.Transfer, MovementType.StatusChange, MovementType.Rebranding, MovementType.Loan, MovementType.Losses]

    const movementMots = ['InTank', 'Pipe', 'Road', 'Ship', 'Train', 'X Pump', 'Barge']
    const movementStatuses = ['Forecast', 'Planned', 'Actual']

    let userContext = UserContextContainer.useContainer()

    useEffect(() => {
        if (userContext.isLoggedIn) return
        setSites([])
        setProducts([])
        setDutyStatuses([])
        setCompanies([])
    }, [userContext.isLoggedIn])

    useEffect(() => {
        clearSimulation()
    }, [history.location])

    useEffect(() => {
        let selectedProducts = stockFilters.productIds ?? []
        let units = products.filter(x => selectedProducts.includes(x.id)).map(x => x.unit).distinct()
        setUnit(units?.length == 1 ? units[0] : undefined)
    }, [stockFilters, products])

    let changeStockDateFilter = async (newDate: string | null, allMovements: MovementListItem[] | null = null) => {
        let filteredMovements = allMovements ?? movements

        setStockDateFilter(newDate)
        setStockDateFilterMovements(filteredMovements.filter(x => newDate === x.date))
    }

    let deleteStockDateFilter = () => {
        setStockDateFilterMovements([])
        setStockDateFilter(null)
    }

    let changeSwitchState = (newState: string): void => {
        let movements: string[] = []
        if (newState === inValue)
            movements = inMovements.map(x => MovementTypeNameFromMovementType(x))
        else if (newState === outValue)
            movements = outMovements.map(x => MovementTypeNameFromMovementType(x))

        movements.unshift('Type')
        setMovementTypes(movements)
        setSelectedMovementType(null)
        setSwitchFilter(newState)
    }

    let getMotMovements = () => {
        let motLabel = t('stock.label.movement.meanOfTransportation')
        movementMots.unshift(motLabel)
        setMots(movementMots)
        setSelectedMot(null)
    }

    let getMovementStatuses = () => {
        let movementStatusLabel = t('stock.label.movement.movementStatus')
        movementStatuses.unshift(movementStatusLabel)
        setStatuses(movementStatuses)
        setSelectedStatus(null)
    }

    let datesHasErrors = (stockFilters) => {
        if (!stockFilters.start || !stockFilters.end) return true

        let startDate = moment(stockFilters.start)
        let endDate = moment(stockFilters.end)
        let limitDate = moment("12/31/2018")

        if (startDate.format('MM/DD/YYYY') === endDate.format('MM/DD/YYYY')) return false

        return startDate.isAfter(endDate) || startDate.isBefore(limitDate)
    }

    let loadMovements = () => {
        movementQuerySubject.next({
            stockFilters, switchFilter,
            movementStatus: selectedStatus,
            movementType: selectedMovementType,
            meanOfTansportation: selectedMot,
            textFilter,
            stockSimulations: simulations,
            nomination: nominationFilter,
            deal: dealFilter
        })
    }

    let loadStocks = () => stockQuerySubject.next({ stockFilters, stockMode, stockSimulations: simulations })

    let load = async () => {
        if (!userContext.isLoggedIn || !hasClaim(Claims.StockManager)) return
        checkDates();
        loadStocks()
        loadMovements()
    }

    let checkDates = () => { if (datesHasErrors(stockFilters)) setFilterDatesOnError(true) }

    let setMovementLoaded = (movements: MovementListItem[]) => {
        let compareMovementsByDate = (a: MovementListItem, b: MovementListItem) => {
            if (!a.date) return -1
            if (!b.date) return 1
            if (moment(a.date).isBefore(moment(b.date))) return -1
            return 1
        }
        setMovements(movements.sort(compareMovementsByDate))
    }

    let addOrUpdateSimulation = (simulation: StockSimulation) => {
        let actualSimulations = [...simulations]
        let simulationIndex = actualSimulations.findIndex(x => x.date == simulation.date && x.movementType == simulation.movementType)
        if (simulationIndex >= 0)
            actualSimulations[simulationIndex] = simulation
        else
            actualSimulations.push(simulation)
        setSimulations(actualSimulations)
    }

    let removeSimulation = (date: string, movementType: string): boolean => {
        let actualSimulations = [...simulations]
        let simulationIndex = actualSimulations.findIndex(x => x.date == date && x.movementType == movementType)
        if (simulationIndex >= 0)
            actualSimulations.splice(simulationIndex, 1)
        else
            return false
        setSimulations(actualSimulations)
        return true
    }

    let clearSimulation = () => {
        setSimulations([])
    }

    let changeDates = (startDate: string | null, endDate: string | null) => {
        let filters: StockFilters = { ...stockFilters }

        let newPeriod = compareAndFixPeriod(stockFilters.start, startDate, stockFilters.end, endDate)
        filters['start'] = newPeriod.start
        filters['end'] = newPeriod.end

        setFilters(filters)
        setFilterDatesOnError(false)
    }

    let setFilters = (newFilters: StockFilters) => {
        deleteStockDateFilter()

        if (simulations.length === 0)
            setStockFilters(newFilters)
        else
            setTempFilters(newFilters)
    }

    let confirmSetFilters = () => {
        if (tempFilters != null)
            setStockFilters(tempFilters)
        setSimulations([])
        setTempFilters(null)
    }

    let impactFilters = (filters: SupplyBalanceFilter) => {
        let newFilters = {
            ...stockFilters, sites: filters.sites, productIds: filters.products, dutyStatuses: filters.dutyStatuses, companies: filters.companys,
            usefulStock: filters.usefulStock, allSites: filters.allSites, allProductIds: filters.allProducts, allDutyStatuses: filters.allDutyStatuses,
            allCompanies: filters.allCompanys
        }
        setInLocalStorage('filters', newFilters)
        setFilters(newFilters)
    }

    function mapFlowType(sapSteps: SapFlowStepName[], movementType: MovementType | null): SapFlowType {
        if (movementType === MovementType.StatusChange || movementType === MovementType.Transfer) {
            if (sapSteps.length === 1)
                return 'direct301'
            return 'sto'
        }
        return null
    }

    function mapToSapType(sapStepName: SapFlowStepName, movementType: MovementType | null, sapFlowType: SapFlowType): SapTypes | null {
        switch (movementType) {
            case MovementType.Sale:
                {
                    switch (sapStepName) {
                        case 'SO':
                            return 'sale'
                    }
                }
            case MovementType.Purchase:
                {
                    switch (sapStepName) {
                        case 'PO':
                            return 'purchase501'
                        case 'RE':
                            return 'poRelease'
                        case 'GR':
                            return 'poMigo'
                    }
                }
            case MovementType.Rebranding:
                return 'rebranding309'
            case MovementType.Gains:
                return 'gains'
            case MovementType.Losses:
                return 'losses'
            case MovementType.Transfer:
            case MovementType.StatusChange:
                {
                    if (sapFlowType === 'direct301') {
                        return 'directTransfer'
                    }
                    switch (sapStepName) {
                        case 'STO':
                            return 'sto'
                        case 'DN':
                            return 'stoDn'
                        case 'GI':
                            return 'stoGoodsIssue'
                        case 'GT':
                            return 'migo'
                    }
                }
        }
        return null
    }

    function isReleaseCompleted(targetMovementId: Guid): boolean {
        let mvtInTable = movements.find(mvt => mvt.id === targetMovementId);
        return mvtInTable ? mvtInTable!.mainSapFlowListItem.steps.some(step => step.name === 'RE' && step.isCompleted) : false
    }

    useEffect(() => {
        checkDates()
        loadStocks()
    }, [stockFilters, stockMode, simulations])

    useEffect(() => {
        changeStockDateFilter(stockDateFilter, movements)
    }, [movements])

    useEffect(() => {
        let ifNoDateError = ({ stockFilters }: StockFiltersProp) => !datesHasErrors(stockFilters)
        let ifFieldsHasValues = ({ stockFilters }: StockFiltersProp) =>
            !!stockFilters.start && !!stockFilters.end
            && ((!!stockFilters.productIds && stockFilters.productIds.length !== 0) || (!!stockFilters.allProductIds && stockFilters.allProductIds.length !== 0))
            && ((!!stockFilters.sites && stockFilters.sites.length !== 0) || (!!stockFilters.allSites && stockFilters.allSites.length !== 0))
        let waitBeforeCall = () => hasFeature('StockProjectionMaterializationInApp') ? 1 : 500

        let stockQuerySubscription = stockQuerySubject.asObservable()
            .pipe(
                filter(ifNoDateError),
                filter(ifFieldsHasValues),
                map(createStockQuery),
                debounce(() => interval(waitBeforeCall())),
                switchMap(async query => {
                    try { return await api.get<StockProjectionResult>(query) }
                    catch { return Promise.resolve(undefined) }
                }),
                filter(x => x !== undefined),
                map(x => x as StockProjectionResult)
            )
            .subscribe(setStockProjectionResult)

        let movementQuerySubscription = movementQuerySubject.asObservable()
            .pipe(
                filter(ifNoDateError),
                filter(ifFieldsHasValues),
                map(createMovementQuery),
                debounce(() => interval(waitBeforeCall())),
                switchMap(async query => {
                    try { return await api.get<MovementListItem[]>(query) }
                    catch { return Promise.resolve(undefined) }
                }),
                filter(x => x !== undefined)
            )
            .subscribe(setMovementLoaded)

        changeSwitchState(inValue)
        getMotMovements()
        getMovementStatuses()

        return () => {
            movementQuerySubscription.unsubscribe()
            stockQuerySubscription.unsubscribe()
        }
    }, [])

    let movementTypeOptions = {
        ...defaultMovementTypeSelectionOptions,
        showInOutSwitch: true,
        showStatusChange: false,
        showRebranding: false,
        showBorrow: false,
        showGain: false,
        showLoan: false,
        showLosses: false,
        showUniqueRecurrentSwitch: false
    }

    let isStockChartOrTableDisplayed = () =>
        displayedBlocks.includes('stockChart') || displayedBlocks.includes('stockTable')

    let isTableMovementFullWidth = () => displayedBlocks.includes('movementTable')
        && !displayedBlocks.includes('messageTable')
        && (!displayedBlocks.includes('stockChart') || !displayedBlocks.includes('stockTable'))

    let loadCounterpartys = async () => {
        let counterpartysPromise = api.get<Counterparty[]>('stock/movement/counterparty')
        setCounterpartys(await counterpartysPromise)
    }

    return {
        load, loadMovements, checkDates,
        inValue, outValue, defaultStartDate, defaultEndDate, movementTypes,
        showActualStockPopup, setShowActualStockDisplay,
        switchFilter, changeSwitchState,
        movements, setMovements,
        stockProjectionResult, setStockProjectionResult,
        stockFilters, setFilters, confirmSetFilters,
        tempFilters, setTempFilters,
        filterDatesOnError, setFilterDatesOnError,
        products, setProducts,
        stockMode, setStockMode,
        dutyStatuses, setDutyStatuses,
        companies, setCompanies,
        sites, setSites,
        selectedMovementType, setSelectedMovementType,
        simulations, addOrUpdateSimulation,
        removeSimulation, clearSimulation,
        changeStockDateFilter, stockDateFilter,
        deleteStockDateFilter, stockDateFilterMovements,
        unit, changeDates, impactFilters,
        displayedBlocks, setDisplayedBlocks,
        mapToSapType, mapFlowType, isReleaseCompleted,
        selectedMot, setSelectedMot, mots, setMots,
        selectedStatus, setSelectedStatus, statuses, setStatuses,
        setTextFilter, textFilter, movementTypeOptions, isTableMovementFullWidth, isStockChartOrTableDisplayed,
        nominationFilter, setNominationFilter,
        dealFilter, setDealFilter, counterpartys, loadCounterpartys
    }
}

export let StockBoardContainer = createContainer(useStockBoard)