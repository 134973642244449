import moment from 'moment'
import React, { useEffect, MutableRefObject, useRef, useState } from 'react'
import { withStyles, createStyles, Paper, Typography, Chip, Tooltip, IconButton, Link, Badge } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank, GavelOutlined, Link as LinkIcon } from '@material-ui/icons'
import * as Icons from '@material-ui/icons'
import { muiOptions, MuiProps, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import * as api from '../../infrastructure/api'
import { LoaderStatusContainer } from '../../infrastructure/loader'
import { formatFilename } from '../../infrastructure/excelExport'
import guid, { Guid } from '../../infrastructure/guid'
import { Claims } from '../../infrastructure/signIn/models'
import { hasClaim, hasDealClaim } from '../../infrastructure/signIn/userContext'
import { hasFeature, FeatureContainer } from '../../infrastructure/feature'
import { historyDialog } from '../common/history/dialog'
import { ColumnDescriptor, CustomDialog, DataTable, Switch, Menu, NumberField, SearchField } from '../common/customComponents'
import { VesselEditDialogContainer } from '../vessels/vesselEdit/vesselEditStore'
import { StockMovementContainer, movementDialog } from './movementEdit/stockMovementStore'
import { StockBoardContainer } from './stockBoardStore'
import {
    MovementListItem, MovementStatus, MovementTypeNameFromMovementType, MovementTypeFromMovementTypeName,
    DuplicateMovementCommand, MovementForm, DealLabelId, MovementType, MeanOfTransportationType,
    MovementStatusNameFromMovementStatus, MovementStatusFromMovementStatusName, SapFlow, SapFlowStepStatus
} from './stockModels'
import { useActionDebounceWithArgs } from '../common/debounce'
import { MovementTypeIcon, MovementMotIcon, meanOfTransportationPopupType, StatusIcon } from './_movementIcon'
import { CustomButton } from '../common/components/button'
import { handleSapCall } from './movementEdit/movementForms/sap/sapHandlers'
import { isDisplayable, isEnabled, SapTypes } from './movementEdit/movementForms/sap/createInSap/formRequirements'
import { snackbars } from '../../infrastructure/snackbars'
import { MovementStatusIcon } from './MovementStatusIcon'
import { MovementMessageContainer } from './movementMessageStore'
import { popupNavigator } from '../../infrastructure/popupNavigator'
import { ActionDescriptor, GroupDescriptor } from '../common/components/table/table'
import { ConfirmDealAssignation, SelectAssignableDeals } from '../common/assign/assignDealPopup'
import { Deal } from '../deals/dealModels'

let dialogRef: MutableRefObject<{ open: () => void } | null>

const EnterKeyCode = 13

type MovementTableProps = {
    className?: string
    movementPopupHalfBottom?: boolean
}

function _MovementTable({ classes, className, movementPopupHalfBottom }: MuiProps & MovementTableProps) {
    let store = StockBoardContainer.useContainer()
    let stockEdit = StockMovementContainer.useContainer()
    let vesselEdit = VesselEditDialogContainer.useContainer()
    let loader = LoaderStatusContainer.useContainer()
    let features = FeatureContainer.useContainer()
    let movementMessageStore = MovementMessageContainer.useContainer()

    let [selectedMovements, setSelectedMovements] = useState<MovementListItem[]>([])
    let [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)
    let [currentMovement, setCurrentMovement] = useState<MovementListItem | null>(null)
    let [openAssignDealPopup, setOpenAssignDealPopup] = useState<boolean>(false)
    let [assignableDealsFromMovement, setAssignableDealsFromMovement] = useState<Deal[] | null>(null)
    let [dealIdToAssign, setDealIdToAssign] = useState<Guid | null>(null)
    let [isConfirmDealOpen, setIsConfirmDealOpen] = useState<boolean>(false)
    let [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false)

    let changeMovementQuantity = (function () {
        let [isOpen, setIsOpen] = useState<boolean>(false)
        let [movementId, setMovementId] = useState<string | null>(null)
        let [quantity, setQuantity] = useState<number | null>(0)

        let ref = useRef<{ focus: () => void, select: () => void } | null>(null);

        let open = (movement: MovementListItem) => {
            setQuantity(movement.volume)
            setMovementId(movement.id);
            setIsOpen(true)
            setImmediate(() => { ref?.current?.focus(); ref?.current?.select() });
        }

        let save = async () => {
            if (!quantity || !movementId) return
            setIsOpen(false)
            await api.post('stock/movement/quantity', { movementId, quantity })
            await store.load()
        }

        let close = () => setIsOpen(false)

        return { ref, isOpen, quantity, open, close, change: setQuantity, save }
    })()

    dialogRef = useRef<{ open: () => void } | null>(null)

    useEffect(() => { if (!stockEdit.isOpen && !vesselEdit.isOpen) store.load() }, [stockEdit.isOpen, vesselEdit.isOpen])

    useEffect(() => {
        store.loadCounterpartys()
    }, [])

    let onSelect = (movement: MovementListItem) => {
        movement.movementType != MovementType.MktSale && movementDialog.open(movement.id, movementPopupHalfBottom)
    }

    let openMovementCreationPopup = () => movementDialog.open(null, movementPopupHalfBottom)

    let toggleSwitch = () => {
        store.changeSwitchState(store.switchFilter == store.outValue ? store.inValue : store.outValue)
    }

    let deleteMovements = async () => {
        setIsConfirmDeleteOpen(false)
        let notMktSalesMovements = selectedMovements.filter(x => x.movementType != MovementType.MktSale)
        if (notMktSalesMovements?.length === 0) return
        let request = { movementIds: notMktSalesMovements.map(x => x.id) }
        await api.post('stock/movement/batchDelete', request)
        store.load()
        movementMessageStore.setSelectedMovementIds([])
        setSelectedMovements([])
    }

    let duplicateMovement = async (selectedMovement: MovementListItem): Promise<void> => {
        let command: DuplicateMovementCommand = {
            sourceId: selectedMovement.id,
            duplicateId: guid.createNew()
        }
        await api.post('stock/movement/duplicate', command)
        movementDialog.open(command.duplicateId)
    }

    let handleDuplicateDebouncer = useActionDebounceWithArgs(duplicateMovement)

    let getTotalFilteredMovementsVolume = () => {
        let currentMovements = store.stockDateFilter
            ? store.stockDateFilterMovements
            : store.movements
        return currentMovements.reduce((acc, cur) => acc + (cur.volume ?? 0), 0)
    }

    let getTotalFilteredMovementsNominatedVol = () => {
        let currentMovements = store.stockDateFilter
            ? store.stockDateFilterMovements
            : store.movements
        return currentMovements.reduce((acc, cur) => acc + (cur.nominatedVolume ?? 0), 0)
    }

    let getSapFlow = (sapType: SapTypes, movement: MovementForm): SapFlow => {

        if (movement.movementType == MovementType.Purchase || movement.movementType == MovementType.Sale)
            if (sapType == 'sto' || sapType == 'stoDn' || sapType == 'stoGoodsIssue' || sapType == 'directTransfer' || sapType == 'migo')
                return movement.secondSapFlow!

        return movement.mainSapFlow
    }

    let handleClickOnSapButton = async (sapType: SapTypes, movementId: Guid, features): Promise<void> => {
        let movementForm = await api.get<MovementForm>(`stock/movement/${movementId}`)
        let associatedDeal = await api.get<DealLabelId | null>(`deal/idLabel/fromMovement/${movementId}`)
        if (!isDisplayable(sapType, features, !!associatedDeal)) {
            snackbars.error(t('stock.label.movement.sapAction.noAssociatedDeal'));
            return;
        }

        if (!isEnabled(sapType, movementForm, getSapFlow(sapType, movementForm))) {
            snackbars.error(t('stock.label.movement.sapAction.invalidMovementForm'));
            return;
        }

        try {
            await handleSapCall(sapType, movementForm, async () => {
                await store.load();
            })
        }
        catch (err) {
            throw err
        }
        finally {
            if (sapType !== 'poRelease')
                await store.load()
        }
    }

    let getMeanOfTransportationId = (movement: MovementListItem): string | null => {
        switch (movement.meanOfTransportation) {
            case 'Ship':
                return movement.associatedVesselId
            case 'Road':
                return movement.associatedTruckId
            default:
                return null
        }
    }

    let showNomination = store.isTableMovementFullWidth()
        && hasFeature('ExtendedMovementTable')
        && (hasFeature('DailyNomination') || hasFeature('WeeklyNomination'))

    let showTobs = store.isTableMovementFullWidth()
        && hasFeature('ExtendedMovementTable')
        && hasFeature('UseObservedTemperatureByDefault')

    let showExternalNumber = store.isTableMovementFullWidth()
        && hasFeature('ExtendedMovementTable')
        && hasFeature('MovementExternalNumber')

    let sapColumnGroup = 'sapDetails'

    let getMovementTableColumns = () => {
        let initialMovementColumns: ColumnDescriptor<MovementListItem>[] = [
            { name: t('stock.label.movement.date'), value: x => x.date ? moment(x.date).format('MM/DD') : '' },
            { name: t('stock.label.movement.type'), htmlFor: x => <MovementTypeIcon movementType={x.movementType ?? MovementType.Unknown} /> },
            { name: t('stock.label.movement.referenceExt'), value: x => x.reference, wrapText: true },
            {
                name: t('stock.label.originDestination'), htmlFor: x =>
                    <div className={classes.textSubTextContainer}>
                        <div>{x.text}</div>
                        <div className={classes.subText}>{x.subText}</div>
                    </div>
            },
            {
                name: t('stock.movementTable.ediIn'), value: x => x.destinationExternalNumber,
                hidden: !showExternalNumber
            },
            {
                name: t('stock.movementTable.ediOut'), value: x => x.originExternalNumber,
                hidden: !showExternalNumber
            },
            {
                name: t('stock.movementTable.tobs'), value: x => x.observedTemperature,
                hidden: !showTobs,
                alignRight: true
            },
            {
                name: t('stock.movementTable.volumeTobs'), value: x => x.observedQuantity,
                hidden: !showTobs,
                alignRight: true
            },
            {
                name: t('stock.label.movement.mot'), htmlFor: x => {
                    let meanOfTransportationId = getMeanOfTransportationId(x)
                    let tooltip: string | null = null
                    if (x.meanOfTransportation === 'Ship')
                        tooltip = x.vesselReference
                    else if (x.meanOfTransportation === 'Road')
                        tooltip = x.truckReference

                    return (<IconButton style={{ color: !!meanOfTransportationId ? defaultColors.lightBlue.main.color : '' }}>
                        <MovementMotIcon meanOfTransportation={x.meanOfTransportation ?? ''}
                            tooltip={tooltip}
                            onClick={(event) => {
                                if (meanOfTransportationId) {
                                    event.stopPropagation()
                                    popupNavigator.open(meanOfTransportationPopupType(x.meanOfTransportation!), meanOfTransportationId)
                                }
                            }}
                        />
                    </IconButton>)
                }
            },
            {
                name: t('stock.label.movement.nomination'),
                hidden: !showNomination,
                htmlFor: x => (x.nomination
                    ? <CheckBox />
                    : <CheckBoxOutlineBlank />
                )
            },
            {
                name: t('stock.movementTable.nominatedVolume', { unit: store.unit }),
                value: x => x.nominatedVolume ? Math.round(x.nominatedVolume) : null,
                hidden: !showNomination,
                alignRight: true,
                total: getTotalFilteredMovementsNominatedVol()
            },
            {
                name: t('stock.label.movement.volume', { unit: store.unit }),
                alignRight: true,
                total: getTotalFilteredMovementsVolume(),
                value: x => x.volume ? Math.round(x.volume).toString() : null
            },
            {
                name: t('stock.label.movement.status'), value: x => MovementStatus[x.movementStatus ?? -1],
                htmlFor: x => <MovementStatusIcon movementItem={x} classes={classes} />
            },
            {
                name: t('stock.movementTable.dealNumber'),
                value: x => x.dealNumber,
                hidden: !store.isTableMovementFullWidth() || !hasFeature('ExtendedMovementTable'),
                htmlFor: x => (
                    <Link onClick={(e) => { e.stopPropagation(); popupNavigator.open('deal', x.associatedDealId) }}>
                        {x.dealNumber}
                    </Link>
                )
            }
        ]

        if (hasClaim(Claims.StockManager)) {
            let isMultiSapFlow = (x: MovementListItem) => x.secondSapFlowListItem != null && x.secondSapFlowListItem.steps.length > 0

            let steps = (x: MovementListItem): SapFlowStepStatus[] => {
                if (isMultiSapFlow(x))
                    return [x.mainSapFlowListItem.steps[0], x.secondSapFlowListItem!.steps[0]]
                else
                    return x.mainSapFlowListItem.steps
            }

            initialMovementColumns.push(
                {
                    name: t('stock.label.movement.sap'), value: x => MovementStatus[x.movementStatus ?? -1],
                    defaultGroup: sapColumnGroup,
                    htmlFor: x => {
                        if (x.nomination) return <></>
                        let buttonIndex = x.mainSapFlowListItem?.steps.findIndex(y => !y.isCompleted)
                        return (<>
                            {steps(x).map((stepStatus, index) => {
                                let stepNames = x.mainSapFlowListItem?.steps.map(stepStatus => stepStatus.name)
                                let flowType = store.mapFlowType(stepNames, x.movementType)
                                let mappedSapType = store.mapToSapType(stepStatus.name, x.movementType!, flowType)
                                if ((!isMultiSapFlow(x) && buttonIndex == index || isMultiSapFlow(x) && !stepStatus.isCompleted)
                                    && store.isTableMovementFullWidth()
                                    && mappedSapType !== null
                                    && isDisplayable(mappedSapType!, features))
                                    return (
                                        <CustomButton
                                            key={index}
                                            className={classes.sapButton}
                                            classes={{ label: classes.noPadding }}
                                            label={stepStatus.name}
                                            onClick={(event) => { event.stopPropagation(); handleClickOnSapButton(mappedSapType!, x.id, features) }}
                                            debouncerDelay={5000}
                                        />)
                                else
                                    return <Chip key={index}
                                        className={stepStatus.isCompleted ? classes.sapChipWithValue : classes.sapChip}
                                        classes={{ label: classes.noPadding }}
                                        label={store.isTableMovementFullWidth() && stepStatus.isCompleted ? stepStatus.value : stepStatus.name} />
                            }
                            )}
                        </>)
                    }
                })
        }

        if (hasFeature('ExtendedMovementTable') && store.isTableMovementFullWidth()) {
            let sapColumns = [
                {
                    name: t('stock.movementTable.sapVendor'),
                    value: (x: MovementListItem) => x.movementType === MovementType.Purchase ? x.dealSapVendor : '',
                    groupedBy: sapColumnGroup
                },
                {
                    name: t('stock.movementTable.sapShipTo'),
                    value: (x: MovementListItem) => x.movementType === MovementType.Sale ? x.dealSapShipTo : '',
                    groupedBy: sapColumnGroup
                },
                {
                    name: t('stock.movementTable.originPlant'),
                    value: (x: MovementListItem) => x.mainSapFlowListItem.originPlant,
                    groupedBy: sapColumnGroup
                },
                {
                    name: t('stock.movementTable.destinationPlant'),
                    value: (x: MovementListItem) => x.mainSapFlowListItem.destinationPlant,
                    groupedBy: sapColumnGroup
                },
                {
                    name: t('stock.movementTable.originStorageLocation'),
                    value: (x: MovementListItem) => x.mainSapFlowListItem.originStorageLocation,
                    groupedBy: sapColumnGroup
                },
                {
                    name: t('stock.movementTable.destinationStorageLocation'),
                    value: (x: MovementListItem) => x.mainSapFlowListItem.destinationStorageLocation,
                    groupedBy: sapColumnGroup
                },
                {
                    name: t('stock.movementTable.materialDocument'),
                    value: (x: MovementListItem) => x.material,
                    groupedBy: sapColumnGroup
                }
            ]

            initialMovementColumns = [...initialMovementColumns, ...sapColumns]
        }

        return initialMovementColumns
    }

    let movementTableGroups: GroupDescriptor[] = [
        { name: 'details', title: '', colSpan: 15 },
        { name: sapColumnGroup, title: t('deals.label.sapDetails'), withHiddenColumns: true },
    ]

    let filterOnMovementType = async (movementTypeName: string) => {
        store.deleteStockDateFilter()
        store.setSelectedMovementType(MovementTypeFromMovementTypeName(movementTypeName))
    }

    let onMotChange = async (meanOfTransportation: string) => {
        store.setSelectedMot(MeanOfTransportationType(meanOfTransportation))
    }

    let onStatusChange = async (status: string) => {
        store.setSelectedStatus(MovementStatusFromMovementStatusName(status))
    }

    let getMovementItems = () => {
        return store.stockDateFilter
            ? store.stockDateFilterMovements
            : store.movements
    }

    let getMenuItemYesNo = () =>
        [{ value: '', text: t('stock.movementTable.menuChoiceAll') },
        { value: 'true', text: t('stock.movementTable.menuChoiceYes') },
        { value: 'false', text: t('stock.movementTable.menuChoiceNo') }]

    let exportMovements = async () => {
        let filename = formatFilename('Movement.xlsx')
        let filters = store.stockFilters
        filters['in'] = store.switchFilter == store.inValue
        filters['out'] = store.switchFilter == store.outValue
        filters['movementType'] = store.selectedMovementType
        filters['meanOfTansportation'] = store.selectedMot
        filters['company'] = store.stockFilters.companies
        filters['dutyStatus'] = store.stockFilters.dutyStatuses
        await api.post(`stock/movement/export`, {
            filename: filename,
            movementIds: selectedMovements.map(x => x.id),
            filters: {
                ...store.stockFilters,
                withMktSale: hasFeature('MktSalesInMovementTable')
            }
        })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    function reduceToActivatedFeatureToggles(value: string): boolean {
        if (value === MovementTypeNameFromMovementType(MovementType.StatusChange))
            return hasFeature('StatusChangeMovement')
        else if (value === MovementTypeNameFromMovementType(MovementType.Gains)
            || value === MovementTypeNameFromMovementType(MovementType.Losses))
            return hasFeature('MovementGainsAndLosses')
        return true
    }

    let OnQueryChanged = (e) => {
        if (e.key === 'Enter')
            return onValidatedQuery()
        else
            movementMessageStore.setSearchText(e.target.value)
    }

    let onValidatedQuery = () => store.setTextFilter(movementMessageStore.searchText)

    let isMktSaleMovement = (x: MovementListItem) => x.movementType === MovementType.MktSale

    let openAssignableDeals = async (movement: MovementListItem) => {
        setCurrentMovement(movement)

        let assignableDeals = hasDealClaim()
            ? await api.get<Deal[]>(`stock/movement/${movement.id}/deal/assignable`)
            : []

        setAssignableDealsFromMovement(assignableDeals)
        if (assignableDeals.length === 0)
            snackbars.info(t('stock.label.movement.noAssignableDealFound'))
        else
            setOpenAssignDealPopup(true)
    }

    let assignDealFromMovement = async () => {
        await api.post('deal/linkMovement', { dealId: dealIdToAssign, movementId: currentMovement?.id })
        store.loadMovements()
        snackbars.success(t('httpSuccess.dealAssigned'))
    }

    let actions: ActionDescriptor<MovementListItem>[] = [
        {
            name: 'Deal', action: (x: MovementListItem) => popupNavigator.open('deal', x.associatedDealId),
            icon:
                <Tooltip title={<Typography variant='body1'>{t('vessels.label.purchaseMovement.goToDeal')}</Typography>} placement='top'>
                    <GavelOutlined className='movement-open-deal-icon' />
                </Tooltip>,
            isBodyAction: true,
            disabled: (x: MovementListItem) => !x.associatedDealId
        },
        {
            name: 'LinkDeal',
            action: (x: MovementListItem) => openAssignableDeals(x),
            isBodyAction: true,
            disabled: (x: MovementListItem) => x.associatedDealId != null || !hasFeature('AssignDealFromMovement') || isMktSaleMovement(x),
            icon:
                <Tooltip title={<Typography variant='body1'>{t('stock.movementTable.linkDeal')}</Typography>} placement='top'>
                    <Badge badgeContent={<LinkIcon />}><GavelOutlined /></Badge>
                </Tooltip >
        },
        {
            name: 'History', action: (x: MovementListItem) =>
                historyDialog.open({
                    id: x.id,
                    type: 'movement',
                    name: `${MovementTypeNameFromMovementType(x.movementType)} ${x.subText} ${x.volume} M20, ${x.date
                        ? moment(x.date).format('MM/DD/YYYY') : ''}`,
                    sapFlowId: x!.mainSapFlowId!
                }),
            isBodyAction: true,
            disabled: (x: MovementListItem) => isMktSaleMovement(x),
            icon: <Icons.History />
        },
        {
            name: 'Duplicate', action: (x: MovementListItem) => { setCurrentMovement(x); setConfirmDialogOpen(true) },
            icon:
                <Tooltip title={<Typography variant='body1'>{t('stock.movementTable.duplicateMovement')}</Typography>} placement='top'>
                    <Icons.FileCopyOutlined />
                </Tooltip>,
            isBodyAction: true,
            disabled: (x: MovementListItem) => isMktSaleMovement(x)
        },
        {
            name: 'Edit', action: (x: MovementListItem) => changeMovementQuantity.open(x),
            icon:
                <Tooltip title={<Typography variant='body1'>{t('stock.movementTable.editQuantity')}</Typography>} placement='top'>
                    <Icons.EditOutlined />
                </Tooltip>,
            disabled: (x: MovementListItem) => x.movementStatus === MovementStatus.Actual || isMktSaleMovement(x) || x.nomination,
            isBodyAction: true
        }
    ]

    return (
        <Paper className={classes.container + ' stockBoardPaper' + (className ? ' ' + className : '')}>
            <div className={classes.firstRow}>
                <div className={classes.tableHeaderRow}>
                    <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                        {t('stock.movementTable.title')}
                    </Typography>
                    <Switch form
                        isChecked={store.switchFilter == store.outValue}
                        disabled={loader.isActive}
                        changeCallback={() => toggleSwitch()}
                        offText={t('stock.label.movement.in')} onText={t('stock.label.movement.out')} />
                </div>
                <div className={classes.tableHeaderRow + ' ' + classes.headerButtons}>
                    <SearchField
                        label={t('deals.label.search')}
                        onChange={OnQueryChanged}
                        onClick={onValidatedQuery}
                        text={movementMessageStore.searchText} />
                    {
                        store.isTableMovementFullWidth() && hasFeature('ExtendedMovementTable') &&
                        <div className={classes.mvtsFilters}>
                            <Typography variant='subtitle1'>{t('stock.movementTable.dealFilter')}</Typography>
                            {store.dealFilter === 'true' &&
                                <Typography variant='subtitle1'>{': ' + t('stock.movementTable.menuChoiceYes')}</Typography>}
                            {store.dealFilter === 'false' &&
                                <Typography variant='subtitle1'>{': ' + t('stock.movementTable.menuChoiceNo')}</Typography>}
                            <Menu
                                icon={<Icons.FilterListOutlined />}
                                selectedItemValue={store.dealFilter}
                                items={getMenuItemYesNo()}
                                onChange={(x: string) => { store.setDealFilter(x) }} >
                            </Menu>
                        </div>
                    }
                    {
                        store.isTableMovementFullWidth() && hasFeature('ExtendedMovementTable') &&
                        <div className={classes.mvtsFilters}>
                            <Typography variant='subtitle1'>{t('stock.movementTable.nominationFilter')}</Typography>
                            {store.nominationFilter === 'true' &&
                                <Typography variant='subtitle1'>{': ' + t('stock.movementTable.menuChoiceYes')}</Typography>}
                            {store.nominationFilter === 'false' &&
                                <Typography variant='subtitle1'>{': ' + t('stock.movementTable.menuChoiceNo')}</Typography>}
                            <Menu
                                icon={<Icons.FilterListOutlined />}
                                items={getMenuItemYesNo()}
                                selectedItemValue={store.nominationFilter}
                                onChange={(x: string) => store.setNominationFilter(x)}>
                            </Menu>
                        </div>
                    }
                    {
                        store.stockDateFilter
                            ? <Chip
                                data-tour='movementTableDateFilter'
                                onDelete={store.deleteStockDateFilter}
                                label={moment(store.stockDateFilter).format('MM/DD/YY')} />
                            : null
                    }
                    <div className={classes.mvtsFilters}>
                        {store.selectedStatus !== null && store.selectedStatus !== undefined
                            ? <StatusIcon movementStatus={store.selectedStatus ?? ''} classes={classes} />
                            : <Typography variant='subtitle1'>{t('stock.label.status')}</Typography>}
                        <Menu
                            icon={<Icons.FilterListOutlined />}
                            selectedItemValue={MovementStatusNameFromMovementStatus(store.selectedStatus)}
                            onChange={(x: string) => onStatusChange(x)}
                            items={store.statuses?.map(x =>
                            ({
                                text: x,
                                icon: <StatusIcon movementStatus={MovementStatusFromMovementStatusName(x) ?? MovementStatus.Unknown} classes={classes} />,
                                textPadding: '40px'
                            })
                            )}
                        >
                        </Menu>
                    </div>
                    <div className={classes.mvtsFilters}>
                        {store.selectedMot !== null && store.selectedMot !== undefined
                            ? <MovementMotIcon meanOfTransportation={store.selectedMot ?? ''} />
                            : <Typography variant='subtitle1'>{t('stock.label.mot')}</Typography>}
                        <Menu
                            icon={<Icons.FilterListOutlined />}
                            selectedItemValue={store.selectedMot ?? ''}
                            onChange={(x: string) => onMotChange(x)}
                            items={store.mots?.map(x => ({ text: x, icon: <MovementMotIcon meanOfTransportation={x ?? ''} /> }))}>
                        </Menu>
                    </div>
                    <div className={classes.mvtsFilters}>
                        {store.selectedMovementType !== null && store.selectedMovementType !== undefined
                            ? <MovementTypeIcon movementType={store.selectedMovementType ?? -1} />
                            : <Typography variant='subtitle1'>{t('stock.label.type')}</Typography>}
                        <Menu
                            icon={<Icons.FilterListOutlined />}
                            selectedItemValue={MovementTypeNameFromMovementType(store.selectedMovementType)}
                            onChange={(x: string) => filterOnMovementType(x)}
                            items={store.movementTypes.filter(reduceToActivatedFeatureToggles).map(x =>
                                ({ text: x, icon: <MovementTypeIcon movementType={MovementTypeFromMovementTypeName(x) ?? MovementType.Unknown} /> }))}
                        >
                        </Menu>
                    </div>
                    <CustomButton
                        img={<Icons.Add />}
                        className={classes.primaryButton}
                        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                        onClick={openMovementCreationPopup} />
                    <CustomDialog isOpen={confirmDialogOpen} title={t('stock.movementTable.confirmationTitle')}
                        contentText={t('stock.movementTable.confirmDuplicate')}
                        confirmButtonText={t('stock.movementTable.duplicate')}
                        onConfirm={async () => {
                            setConfirmDialogOpen(false)
                            if (currentMovement) await handleDuplicateDebouncer.execute(currentMovement)
                        }}
                        onClose={() => setConfirmDialogOpen(false)}
                        onCancel={() => setConfirmDialogOpen(false)} />
                    <CustomDialog isOpen={changeMovementQuantity.isOpen} title={t('stock.movementTable.editQuantityTitle')}
                        content={
                            <NumberField text={changeMovementQuantity.quantity} precision={0} disableNewStyle
                                inputRef={x => changeMovementQuantity.ref.current = x}
                                onKeyDown={evt => {
                                    if (evt.keyCode == EnterKeyCode) {
                                        changeMovementQuantity.save()
                                        evt.preventDefault()
                                    }
                                }}
                                onChange={val => changeMovementQuantity.change(val)} />
                        }
                        onConfirm={() => changeMovementQuantity.save()}
                        onClose={() => changeMovementQuantity.close()}
                        onCancel={() => changeMovementQuantity.close()} />
                    {!store.isTableMovementFullWidth() &&
                        <Menu
                            icon={<Icons.MoreVert />}
                            items={[{
                                text: t('stock.movementTable.export'),
                                icon: <Icons.GetAppOutlined className={classes.iconStyle} />,
                                onClick: exportMovements
                            }, {
                                text: t('stock.movementTable.delete'),
                                icon: <Icons.DeleteOutlined className={classes.iconStyle} />,
                                disabled: selectedMovements?.length === 0,
                                onClick: () => setIsConfirmDeleteOpen(true)
                            }]}
                            classesOverride={{ button: classes.menuButton }} />}
                    {store.isTableMovementFullWidth() &&
                        <CustomButton
                            label={t('stock.movementTable.export')}
                            img={<Icons.GetAppOutlined className={classes.iconStyle} />}
                            className={classes.secondary}
                            onClick={exportMovements} />}
                    {store.isTableMovementFullWidth() &&
                        <CustomButton
                            label={t('stock.movementTable.delete')}
                            img={<Icons.DeleteOutlined className={classes.iconStyle} />}
                            disabled={selectedMovements?.length === 0}
                            onClick={() => setIsConfirmDeleteOpen(true)} />}
                </div>
            </div>
            <div className={classes.tableContainer}>
                <DataTable tableId={'movement-table'}
                    items={getMovementItems()}
                    idSelector={(x: MovementListItem) => x.id}
                    onClick={onSelect}
                    columns={getMovementTableColumns()}
                    actionsDisabled={loader.isActive}
                    onSelectionChange={(x: MovementListItem[]) => {
                        setSelectedMovements(x)
                        let notMktSaleMovements = x.filter(x => x.movementType !== MovementType.MktSale)
                        movementMessageStore.setSelectedMovementIds(notMktSaleMovements.map(x => x.id))
                    }}
                    actions={actions}
                    groups={hasFeature('ExtendedMovementTable') && store.isTableMovementFullWidth() ? movementTableGroups : undefined} />
            </div>
            {hasFeature('AssignDealFromMovement') &&
                <>
                    <SelectAssignableDeals
                        setOpenAssignDealPopup={setOpenAssignDealPopup}
                        setDealId={setDealIdToAssign}
                        setIsConfirmDealOpen={setIsConfirmDealOpen}
                        products={store.products.map(x => { return { code: x.code, id: x.id } })}
                        counterpartys={store.counterpartys.map(x => { return { name: x.name, id: x.id } })}
                        openAssignDealPopup={openAssignDealPopup}
                        assignableDealsFromMovement={assignableDealsFromMovement} />
                    <ConfirmDealAssignation
                        setIsConfirmDealOpen={setIsConfirmDealOpen}
                        isConfirmDealOpen={isConfirmDealOpen}
                        setOpenAssignDealPopup={setOpenAssignDealPopup}
                        setDealIdToAssign={setDealIdToAssign}
                        trySave={() => { }}
                        openAssignDealPopup={openAssignDealPopup}
                        assignableDealsFromMovement={assignableDealsFromMovement}
                        assignDealFromMovement={assignDealFromMovement}
                        dealIdToAssign={dealIdToAssign}
                    />
                </>}
            <CustomDialog isOpen={isConfirmDeleteOpen}
                title={t('stock.movementTable.confirmDeleteTitle')}
                contentText={t('stock.movementTable.confirmDeleteText')}
                confirmButtonText={t('deals.table.simpleConfirmDelete.confirm')}
                onConfirm={deleteMovements}
                onCancel={() => setIsConfirmDeleteOpen(false)}
                onClose={() => setIsConfirmDeleteOpen(false)}
            />

        </Paper >)
}

const sapChipStyle = {
    minWidth: '2.5em',
    height: '2.5em',
    color: defaultColors.grey.main.color,
    marginLeft: '0.2em',
    marginRight: '0.2em',
    borderRadius: '7px',
}

let styles = _ =>
    createStyles({
        errorField: {
            color: defaultColors.red.light.color,
            display: 'flex'
        },
        iconStyle: { height: '1.5rem', width: '1.5rem', marginTop: '-0.2rem' },
        invisible: { opacity: 0 },
        red: { color: defaultColors.red.light.color },
        grey: { color: defaultColors.grey.main.color },
        black: { color: 'black' },
        green: { color: defaultColors.green.main.color },
        firstRow: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
            justifyContent: 'space-between',
            margin: '0.2em 1em',
            marginRight: '0',
            height: '3.2em'
        },
        container: {
            height: '100%',
            ...defaultStyles.flexColumn,
            alignItems: 'stretch'
        },
        tableHeaderRow: {
            ...defaultStyles.flexRow,
            marginRight: "2px"
        },
        tableContainer: {
            overflow: 'auto',
            "& td": {
                padding: "3px 8px"
            }
        },
        headerButtons: {
            '& > *': {
                marginRight: '1rem',
                '&:last-child': {
                    marginRight: '0em'
                }
            }
        },
        paperTitle: {
            color: defaultColors.red.main.color,
        },
        primaryButton: {
            ...defaultStyles.primaryButton,
            width: '5px',
            height: '40px',
            borderRadius: '7px'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton
        },
        sapButtonContainer: {
            display: 'inline-block',
            marginLeft: '0.2em',
            marginRight: '0.2em'
        },
        sapButton: {
            ...defaultStyles.secondaryButton,
            height: '2.5em',
            width: '2.5em',
            minWidth: '2.5em',
            padding: '0px',
        },
        sapChip: {
            ...sapChipStyle
        },
        sapChipWithValue: {
            ...sapChipStyle,
            backgroundColor: '#95e379',
            color: defaultColors.green.main.color,
        },
        textSubTextContainer: {
            ...defaultStyles.flexColumn,
            alignItems: 'flex-start'
        },
        subText: {
            fontSize: '0.85em',
            fontStyle: 'Italic',
            color: defaultColors.grey.main.color
        },
        movementTypeChip: {
            color: defaultColors.darkBlue.main.color,
            border: '1px solid',
            borderColor: defaultColors.darkBlue.main.color,
            cursor: 'pointer',
            height: '2em',
            width: '7em',
            margin: '0px 0.3em',
            backgroundColor: 'transparent',
            '&:hover': {
                color: defaultColors.darkBlue.dark.color,
                backgroundColor: 'transparent',
            },
            '&:active': {
                color: defaultColors.darkBlue.light.color,
                backgroundColor: 'transparent',
            },
        },
        movementTypeChipSelected: {
            color: defaultColors.darkBlue.main.color,
            border: '1px solid',
            borderColor: defaultColors.darkBlue.main.color,
            cursor: 'pointer',
            height: '2em',
            width: '7em',
            margin: '0px 0.3em',
            backgroundColor: defaultColors.lightBlue.light.color + '55',
            '&:hover': {
                color: defaultColors.darkBlue.dark.color,
                backgroundColor: defaultColors.lightBlue.light.color + '55',
            },
            '&:focus': {
                color: defaultColors.darkBlue.dark.color,
                backgroundColor: defaultColors.lightBlue.light.color + '55',
            },
            '&:active': {
                color: defaultColors.darkBlue.light.color,
                backgroundColor: defaultColors.lightBlue.light.color + '55',
            },
        },
        mvtsFilters: {
            ...defaultStyles.flexRow,
            fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif"
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center'
        },
        noPadding: { padding: '0px' },
        buttonRoot: { minWidth: '40px' },
        buttonLabel: {
            width: '100%',
            display: 'inline-flex',
            flexDirection: 'column'
        },
        exportButton: {
            marginRight: '5px'
        }
    })

export let MovementTable = withStyles(styles, muiOptions)(_MovementTable)