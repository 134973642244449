import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, TableItem, TextField, NumberField, Select, MultipleSelect } from '../../common/customComponents'
import { MasterDataItem, MasterDataShell, createExcelLines } from './masterDataShell'
import { Site } from './models'

function JettyMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [jettys, setJettys] = useState<TableItem<Jetty>[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [filters, setFilters] = useState<JettyFilters>(noFilters)
    let [dealTypes, setDealTypes] = useState<string[]>([])

    const tBase = 'admin.masterdata.jetty.'

    let load = async () => {
        let jettys = api.get<TableItem<Jetty>[]>('masterdata/jetty')
        let jettysPromise  = await jettys
        setJettys(jettysPromise);
        let sites = api.get<Site[]>('masterdata/jetty/site')
        let countrySites = await sites
        setSites(countrySites)
        let dealTypes = api.get<string[]>('deal/type')
        let dealTypesPromise  = await dealTypes
        setDealTypes(dealTypesPromise)
    }

    let getItems = () => applyFilters(jettys, filters).map(toTableItem)

    useEffect(() => {
        load()
    }, [])

    let onSave = async (item: Jetty & IsNew) => {
        await api.post('masterdata/jetty', { jetty: item, isNew: item.isNew })
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        let toDelete = jettys.filter(x => ids.find(id => id === generateIdString(x)))
        if (toDelete.length === 0) return false
        await api.del('masterdata/jetty', { toDelete })
        await load()
        return true
    }

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'Jetty.xlsx',
            sheets: [{ name: 'Jettys', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let getDefaultSiteOnChange = (selectedSites : string [], defaultSite: string) => 
        selectedSites.includes(defaultSite)
            ? defaultSite : selectedSites.first()

    let getSites  = (sites : string[]) => sites.join(', ')

    let columns: ColumnDescriptor<TableItem<MasterDataItem<Jetty>>>[] = [
        {
            name: t(tBase + 'jettyCode'),
            value: x => x.code,
            columnFilter: { value: filters.code ?? '', onChange: (code: string) => setFilters({ ...filters, code }) }
        },
        {
            name: t(tBase + 'sites'),
            value: x => getSites(x.sites),
            columnFilter: { value: filters.sites ?? '', onChange: (sites: string) => setFilters({ ...filters, sites }) }
        },
        {
            name: t(tBase + 'defaultSite'),
            value: x => x.defaultSite,
            columnFilter: { value: filters.defaultSite ?? '', onChange: (defaultSite: string) => setFilters({ ...filters, defaultSite }) }
        },
        {
            name: t(tBase + 'dealType'),
            value: x => x.dealType,
            columnFilter: { value: filters.dealType ?? '', onChange: (dealType: string) => setFilters({ ...filters, dealType }) }
        },
        {
            name: t(tBase + 'country'),
            value: x => x.country
        },
        {
            name: t(tBase + 'layTime'),
            value: x => x.laytime.toString()
        }
    ]

    let isManager = hasClaim(Claims.MasterdataJettyManager);
    let isWriter = hasClaim(Claims.MasterdataJettyWriter);

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'Jetty-table'}
                headerLabel={t(tBase + 'jettys')}
                itemLabel={t(tBase + 'jetty')}
                isManager={isManager}
                onExportExcel={exportExcel}
                onNew={emptyJetty}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <TextField label={t(tBase + 'jettyCode')}
                                disabled={!selectedItem.isNew}
                                text={selectedItem.code}
                                onChange={event => setSelectedItem({ ...selectedItem, code: event.target.value })} />
                            <MultipleSelect label={t(tBase + 'sites')}
                                chips={true}
                                allWhenEmpty={false}
                                disabled={!isManager && !isWriter}
                                values={selectedItem.sites}
                                choices={sites.map(x => ({ value: x.code, text: x.name }))}
                                    onChange={sites => setSelectedItem({ ...selectedItem, sites: sites, 
                                                defaultSite: getDefaultSiteOnChange(sites, selectedItem.defaultSite)})} />
                            <Select label={t(tBase + 'defaultSite')}
                                disabled={!isManager && !isWriter}
                                value={selectedItem.defaultSite}
                                choices={selectedItem.sites}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, defaultSite: val }) }} />
                            <Select label={t(tBase + 'dealType')}
                                disabled={!isManager && !isWriter}
                                value={selectedItem.dealType}
                                choices={dealTypes.sort().map(x => ({ value: x, text: x }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, dealType: val }) }} />
                            <NumberField label={t(tBase + 'layTime')}
                                disabled={!isManager && !isWriter}
                                text={selectedItem.laytime}
                                onChange={event => setSelectedItem({ ...selectedItem, laytime: event ?? 0 })} />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

type IsNew = { isNew: boolean }

let emptyJetty = (): Jetty & IsNew => ({
    code: '',
    country: '',
    sites: [],
    defaultSite: '',
    dealType: '',
    laytime: 0,
    isNew: true
})

let generateIdString = (item: Jetty | TableItem<Jetty>): string => item.code

let toTableItem = (jetty: Jetty): TableItem<MasterDataItem<Jetty>> => {
    return {
        id: generateIdString(jetty),
        code: jetty.code,
        country: jetty.country,
        sites: jetty.sites,
        defaultSite: jetty.defaultSite,
        dealType: jetty.dealType,
        laytime: jetty.laytime,
        isModified: false
    }
};

type Jetty = {
    code: string,
    country: string,
    sites: string[]
    defaultSite: string
    dealType: string,
    laytime: number
}

type JettyFilters = {
    code: string | null,
    country: string | null,
    sites: string | null,
    defaultSite: string | null,
    dealType: string | null
}

let noFilters: JettyFilters = {
    code: null,
    country: null,
    sites: null,
    defaultSite: null,
    dealType: null
}

const filterByInput = (prop: keyof Jetty, input: string) => (filter: Jetty) => {
    if (Array.isArray(filter[prop])) {
        return (filter[prop] as string[]).some(x => x.includes(input));
    } else {
        return filter[prop].toString().toLowerCase().includes(input.toLowerCase());
    }
}

let applyFilters = (jettys: Jetty[], filters: JettyFilters): Jetty[] => {
    for (const input in filters) {
        if (filters[input])
            jettys = jettys.filter(filterByInput(input as keyof Jetty, filters[input]))
    }
    return jettys
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' }
    })

export default withStyles(styles, muiOptions)(JettyMasterData)