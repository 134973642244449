import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, DatePicker, Select, TableItem, TextField } from '../../common/customComponents'
import { Company } from './models'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'
import guid, { Guid } from '../../../infrastructure/guid'
import { ProductSapCode } from 'src/app/deals/dealModels'
import { SnackbarContainer } from '../../../infrastructure/snackbars'
import moment from 'moment'

type Date = string

type SupplyPlantAssignment = {
    id: Guid
    company: string
    forecastPlant: string
    forecastPlantName: string
    destination: string
    sapProduct: string
    productName?: string | null
    plant: string
    assignedShipTo: string
    assignedShipToName: string | null
    validFrom: Date | null
}

type Product = {
    productId: Guid
    product: string
    name: string
}

type Plant = {
    id: Guid
    company: string
    product: string
    site: string
    dutyStatus: string
    plantCode: string
    plantName: string
    sapCompany: string
    isDefault: boolean
    defaultStorageLocation: string | null
}

type Destination = string
const defaultDestination = 'ISP'

type IsNew = { isNew: boolean }

let empty = (): SupplyPlantAssignment & IsNew => {
    return {
        id: guid.empty,
        company: '',
        forecastPlant: '',
        forecastPlantName: '',
        destination: defaultDestination,
        sapProduct: '',
        productName: '',
        plant: '',
        assignedShipTo: '',
        assignedShipToName: null,
        validFrom: null,
        isNew: true
    }
}

type SupplyPlantAssignmentFilters = {
    company: string | null
    forecastPlant: string | null
    forecastPlantName: string | null
    destination: string | null
    sapProduct: string | null
    productName: string | null
    plant: string | null
    assignedShipTo: string | null
    assignedShipToName: string | null
    validFrom: string | null
}

let nullFilters: SupplyPlantAssignmentFilters = {
    company: null,
    forecastPlant: null,
    forecastPlantName: null,
    destination: null,
    sapProduct: null,
    productName: null,
    plant: null,
    assignedShipTo: null,
    assignedShipToName: null,
    validFrom: null
}

let toTableItem = (item: SupplyPlantAssignment): TableItem<MasterDataItem<SupplyPlantAssignment>> => {
    return {
        id: item.id,
        company: item.company,
        forecastPlant: item.forecastPlant,
        forecastPlantName: item.forecastPlantName,
        destination: item.destination,
        sapProduct: item.sapProduct,
        productName: item.productName,
        plant: item.plant,
        assignedShipTo: item.assignedShipTo,
        assignedShipToName: item.assignedShipToName,
        validFrom: item.validFrom,
        isModified: false
    }
};

function SupplyPlantAssignmentMasterData({ classes }: MuiProps) {
    let snackbar = SnackbarContainer.useContainer()
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [supplyPlantAssignments, setSupplyPlantAssignments] = useState<TableItem<SupplyPlantAssignment>[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [products, setProducts] = useState<Product[]>([])
    let [productSapCodes, setProductSapCodes] = useState<ProductSapCode[]>([])
    let [destinations, setDestinations] = useState<Destination[]>([])
    let [plants, setPlants] = useState<Plant[]>([])
    let [filters, setFilters] = useState<SupplyPlantAssignmentFilters>(nullFilters)

    let SupplyPlantAssignmentLabel = t('admin.masterdata.supplyPlantAssignment.supplyPlantAssignment')
    let isManager = hasClaim(Claims.MasterdataSupplyPlantAssignmentManager)

    let init = async () => {
        let companyPromise = api.get<Company[]>('masterdata/supplyPlantAssignment/companies')
        let products = api.get<Product[]>('masterdata/supplyPlantAssignment/products')
        let productSapCodes = api.get<ProductSapCode[]>('masterdata/supplyPlantAssignment/productSapCodes')
        let plantsPromise = api.get<Plant[]>('masterdata/supplyPlantAssignment/plants')
        let destinationsPromise = api.get<Destination[]>('masterdata/supplyPlantAssignment/destinations')
        setCompanys(await companyPromise)
        setProducts(await products)
        setProductSapCodes(await productSapCodes)
        setPlants(await plantsPromise)
        setDestinations(await destinationsPromise)
    }

    let load = async () => {
        let SupplyPlantAssignments = await api.get<TableItem<SupplyPlantAssignment>[]>('masterdata/supplyPlantAssignment')
        setSupplyPlantAssignments(SupplyPlantAssignments.map(x => ({ ...x, productName: getProduct(x.sapProduct)?.name })))
    }

    let displayMissingField = async (field: String) => {
        snackbar.warning(t(`admin.masterdata.supplyPlantAssignment.warning.${field}`))
    }

    let onSave = async (item: SupplyPlantAssignment) => {
        if (!item.company) {
            displayMissingField('company')
            return false
        }
        if (!item.forecastPlant) {
            displayMissingField('forecastPlant')
            return false
        }
        if (!item.forecastPlantName) {
            displayMissingField('forecastPlantName')
            return false
        }
        if (!item.sapProduct) {
            displayMissingField('sapProduct')
            return false
        }
        if (!item.plant) {
            displayMissingField('plant')
            return false
        }
        if (!item.assignedShipTo) {
            displayMissingField('assignedShipTo')
            return false
        }
        if (!item.validFrom) {
            displayMissingField('validFrom')
            return false
        }

        await api.post('masterdata/supplyPlantAssignment', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        if (ids.length === 0) return false
        await api.del(`masterdata/supplyPlantAssignment`, ids)
        await load()
        return true
    }
    let onCopy = (item: TableItem<MasterDataItem<SupplyPlantAssignment>>): TableItem<MasterDataItem<SupplyPlantAssignment>> & IsNew =>
        ({ ...item, validFrom: null, isNew: true })

    const filterByInput = (prop: string, input: string) => (filter: any) => filter[prop].toLowerCase().contains(input.toLowerCase());

    let applyFilters = (supplyPlantAssignment: SupplyPlantAssignment[], filters: SupplyPlantAssignmentFilters): SupplyPlantAssignment[] => {
        for (const input in filters) {
            if (filters[input])
                supplyPlantAssignment = supplyPlantAssignment.filter(filterByInput(input, filters[input]))
        }
        return supplyPlantAssignment
    }

    let getItems = () => applyFilters(supplyPlantAssignments, filters).map(toTableItem)

    let getProduct = (sapProduct: string): Product | undefined =>
        products.find(x => x.product === productSapCodes.find(x => x.sapCode == sapProduct)?.productCode)

    useEffect(() => { init() }, [])
    useEffect(() => { if (products.length !== 0 && productSapCodes.length !== 0) load() }
        , [products, productSapCodes])

    let columns: ColumnDescriptor<TableItem<MasterDataItem<SupplyPlantAssignment>>>[] = [
        {
            name: t('admin.masterdata.supplyPlantAssignment.companyCode'), value: x => x.company,
            columnFilter: { value: filters.company ?? '', onChange: (company: string) => setFilters({ ...filters, company: company }) }
        },
        {
            name: t('admin.masterdata.supplyPlantAssignment.forecastPlant'), value: x => x.forecastPlant,
            columnFilter: { value: filters.forecastPlant ?? '', onChange: (forecastPlant: string) => setFilters({ ...filters, forecastPlant: forecastPlant }) }
        },
        {
            name: t('admin.masterdata.supplyPlantAssignment.forecastPlantName'), value: x => x.forecastPlantName,
            columnFilter: { value: filters.forecastPlantName ?? '', onChange: (forecastPlantName: string) => setFilters({ ...filters, forecastPlantName: forecastPlantName }) }
        },
        {
            name: t('admin.masterdata.supplyPlantAssignment.destination'), value: x => x.destination,
            columnFilter: { value: filters.destination ?? '', onChange: (destination: string) => setFilters({ ...filters, destination: destination }) }
        },
        {
            name: t('admin.masterdata.supplyPlantAssignment.sapProduct'), value: x => x.sapProduct,
            columnFilter: { value: filters.sapProduct ?? '', onChange: (sapProduct: string) => setFilters({ ...filters, sapProduct: sapProduct }) }
        },
        {
            name: t('admin.masterdata.supplyPlantAssignment.product'), value: x => x.productName ?? '',
            columnFilter: { value: filters.productName ?? '', onChange: (productName: string) => setFilters({ ...filters, productName: productName }) }
        },
        {
            name: t('admin.masterdata.supplyPlantAssignment.plant'), value: x => x.plant,
            columnFilter: { value: filters.plant ?? '', onChange: (plant: string) => setFilters({ ...filters, plant: plant }) }
        },
        {
            name: t('admin.masterdata.supplyPlantAssignment.assignedShipTo'), value: x => x.assignedShipTo,
            columnFilter: { value: filters.assignedShipTo ?? '', onChange: (assignedShipTo: string) => setFilters({ ...filters, assignedShipTo: assignedShipTo }) }
        },
        {
            name: t('admin.masterdata.supplyPlantAssignment.assignedShipToName'), value: x => x.assignedShipToName,
            columnFilter: { value: filters.assignedShipToName ?? '', onChange: (assignedShipToName: string) => setFilters({ ...filters, assignedShipToName: assignedShipToName }) }
        },
        {
            name: t('admin.masterdata.supplyPlantAssignment.validFrom'), value: x => moment(x.validFrom).format('MM/DD/YYYY'),
            columnFilter: { value: filters.validFrom ?? '', onChange: (validFrom: string) => setFilters({ ...filters, validFrom: validFrom }) }
        }
    ]

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'SupplyPlantAssignment.xlsx',
            sheets: [{
                name: 'SupplyPlantAssignment',
                lines: createExcelLines(getItems(), columns)
            }]
        })
    }

    let importExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/supplyPlantAssignment/import'
        api.upload(uploadUrl, file, 'import').then(_ => load())
    }

    let plantsForChoice = (sapProduct: string): { value: any, text: string }[] | string[] => {
        let product = getProduct(sapProduct)
        if (!product)
            return []
        else
            return plants.filter(x => x.product == product?.product).map(x => x.plantCode)
    }

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'supply-plant-assignment'}
                headerLabel={SupplyPlantAssignmentLabel}
                itemLabel={SupplyPlantAssignmentLabel}
                isManager={isManager}
                onExportExcel={exportExcel}
                onNew={empty}
                onDelete={onDelete}
                onSave={onSave}
                onCopy={onCopy}
                onImportExcel={importExcel}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t('admin.masterdata.supplyPlantAssignment.companyCode')}
                                disabled={!isManager || !selectedItem.isNew}
                                value={selectedItem.company}
                                choices={companys.map(x => x.code)}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, company: val }) }} />
                            <TextField label={t('admin.masterdata.supplyPlantAssignment.forecastPlant')}
                                disabled={!isManager || !selectedItem.isNew}
                                text={selectedItem.forecastPlant}
                                onChange={event => setSelectedItem({ ...selectedItem, forecastPlant: event.target.value })} />
                            <TextField label={t('admin.masterdata.supplyPlantAssignment.forecastPlantName')}
                                disabled={!isManager}
                                text={selectedItem.forecastPlantName}
                                onChange={event => setSelectedItem({ ...selectedItem, forecastPlantName: event.target.value })} />
                            <Select label={t('admin.masterdata.supplyPlantAssignment.destination')}
                                disabled={!isManager}
                                value={selectedItem.destination}
                                choices={destinations}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, destination: val }) }} />
                            <Select label={t('admin.masterdata.supplyPlantAssignment.sapProduct')}
                                disabled={!isManager || !selectedItem.isNew}
                                value={selectedItem.sapProduct}
                                choices={productSapCodes.map(x => x.sapCode)}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, sapProduct: val, productName: getProduct(val)?.name }) }} />
                            <TextField label={t('admin.masterdata.supplyPlantAssignment.product')}
                                disabled={true}
                                text={selectedItem.productName} />
                            <Select label={t('admin.masterdata.supplyPlantAssignment.plant')}
                                disabled={!isManager}
                                value={selectedItem.plant}
                                choices={plantsForChoice(selectedItem.sapProduct)}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, plant: val }) }} />
                            <TextField label={t('admin.masterdata.supplyPlantAssignment.assignedShipTo')}
                                disabled={!isManager || !selectedItem.isNew}
                                text={selectedItem.assignedShipTo}
                                onChange={event => setSelectedItem({ ...selectedItem, assignedShipTo: event.target.value })} />
                            <TextField label={t('admin.masterdata.supplyPlantAssignment.assignedShipToName')}
                                disabled={!isManager}
                                text={selectedItem.assignedShipToName}
                                onChange={event => setSelectedItem({ ...selectedItem, assignedShipToName: event.target.value })} />
                            <DatePicker label={t('admin.masterdata.supplyPlantAssignment.validFrom')}
                                disabled={!isManager}
                                date={selectedItem.validFrom}
                                setDate={newDate => setSelectedItem({ ...selectedItem, validFrom: newDate })} />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

let styles = (_) => createStyles({ container: { height: '100%' } })
export default withStyles(styles, muiOptions)(SupplyPlantAssignmentMasterData)