import { createStyles, withStyles } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { t } from '../../../infrastructure/i18nextHelper'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import * as api from '../../../infrastructure/api'
import moment from 'moment'
import guid, { Guid } from '../../../infrastructure/guid'
import { hasClaim, UserContextContainer } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, DatePicker, NumberField, TableItem, TextField, Select } from '../../common/customComponents'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'
import { PriceIndex } from '../../pricings/pricingModels'

type RegulatedPricePeriod = {
    id: Guid,
    productId: Guid
    country: string,
    code: string,
    name: string,
    longName: string,
    startDate: string,
    endDate: string,
    exchangeRate: number | null
    actualPrice: number | null,
    exchangeRateUnit: string,
    quantityPriceUnit: string
}

type Product = {
    productId: Guid,
    name: string
}

type RegulatedPriceFilters = {
    product: string | null
    code: string | null
    period: string | null
    exchangeRateUnit: string | null
    quantityPriceUnit: string | null
}

let emptyFilters: RegulatedPriceFilters = {
    product: null,
    code: null,
    period: null,
    exchangeRateUnit: null,
    quantityPriceUnit: null
}

let zero = (country: string): RegulatedPricePeriod => {
    return {
        id: guid.empty,
        productId: guid.empty,
        country: country,
        code: '',
        name: '',
        longName: '',
        startDate: '',
        endDate: '',
        exchangeRate: null,
        actualPrice: null,
        exchangeRateUnit: '',
        quantityPriceUnit: ''
    }
}

let toTableItem = (regulatedPrice: RegulatedPricePeriod): TableItem<MasterDataItem<RegulatedPricePeriod>> => {
    return {
        id: regulatedPrice.id,
        productId: regulatedPrice.productId,
        country: regulatedPrice.country,
        code: regulatedPrice.code,
        name: regulatedPrice.name,
        longName: regulatedPrice.longName,
        startDate: regulatedPrice.startDate,
        endDate: regulatedPrice.endDate,
        exchangeRate: regulatedPrice.exchangeRate,
        actualPrice: regulatedPrice.actualPrice,
        exchangeRateUnit: regulatedPrice.exchangeRateUnit,
        quantityPriceUnit: regulatedPrice.quantityPriceUnit,
        isModified: false
    }
}

let label = (index: string) => t('admin.masterdata.regulatedPrice.' + index)

function RegulatedPriceMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()
    let userContext = UserContextContainer.useContainer()
    let [regulatedPrices, setRegulatedPrices] = useState<TableItem<RegulatedPricePeriod>[]>([])
    let [products, setProducts] = useState<Product[]>([])
    let [regulatedPriceIndexes, setRegulatedPriceIndexes] = useState<PriceIndex[]>([])
    let [filters, setFilters] = useState<RegulatedPriceFilters>(emptyFilters)

    let isManager = hasClaim(Claims.MasterdataRegulatedPriceManager)

    let load = async () => {
        let regulatedPrice = await api.get<RegulatedPricePeriod[]>('masterdata/regulatedPrice')
        let products = await api.get<Product[]>('masterdata/regulatedPrice/products')
        let indexes = await api.get<PriceIndex[]>('masterdata/regulatedPrice/indexes')

        setRegulatedPrices(regulatedPrice.map(toTableItem))
        setProducts(products)
        setRegulatedPriceIndexes(indexes)
    }

    let getItems = () => applyFilters(regulatedPrices, filters).map(toTableItem)

    let getProductName = (productId: Guid) => products.find(y => y.productId == productId)?.name

    let getPeriodString = (regulatedPricePeriod: RegulatedPricePeriod) =>
        [moment(regulatedPricePeriod.startDate).format('MM/DD/YYYY'), moment(regulatedPricePeriod.endDate).format('MM/DD/YYYY')]
            .join(' -> ')

    let applyFilters = (regulatedPricePeriods: RegulatedPricePeriod[], filters: RegulatedPriceFilters): RegulatedPricePeriod[] => {
        if (filters.product)
            regulatedPricePeriods = regulatedPricePeriods
                .filter(x => getProductName(x.productId)?.toLowerCase().contains(filters.product!.toLowerCase()))
        if (filters.code)
            regulatedPricePeriods = regulatedPricePeriods
                .filter(x => x.code?.toLowerCase().contains(filters.code!.toLowerCase()))
        if (filters.period)
            regulatedPricePeriods = regulatedPricePeriods
                .filter(x => getPeriodString(x)?.toLowerCase().contains(filters.period!.toLowerCase()))
        if (filters.exchangeRateUnit)
            regulatedPricePeriods = regulatedPricePeriods
                .filter(x => x.exchangeRateUnit?.toLowerCase().contains(filters.exchangeRateUnit!.toLowerCase()))
        if (filters.quantityPriceUnit)
            regulatedPricePeriods = regulatedPricePeriods
                .filter(x => x.quantityPriceUnit?.toLowerCase().contains(filters.quantityPriceUnit!.toLowerCase()))
        return regulatedPricePeriods
    }

    useEffect(() => { let effect = async () => { await load() }; effect() }, [])

    let onDeleteRegulatedPrice = async (ids: string[]) => {
        if (ids.length === 0) return false
        await api.del('masterdata/regulatedPrice', ids)
        await load()
        return true
    }

    let onSaveRegulatedPrice = async (item: RegulatedPricePeriod) => {
        await api.post('masterdata/regulatedPrice', item)
        await load()
        return true
    }

    let getIndex = (indexes: PriceIndex[], code: string): PriceIndex | undefined => {
        return indexes.find(x => x.medecoCode === code)
    }

    let quantityPriceUnit = (index: PriceIndex) => {
        return `${index.currency}/${index.unitOfMeasurement}`
    }

    let exchangeRateUnit = (indexCurrency: string) => {
        return indexCurrency === 'USD' ? '' : `${indexCurrency}/USD`
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<RegulatedPricePeriod>>>[] = [
        { name: label('country'), value: x => x.country },
        {
            name: label('product'), value: x => getProductName(x.productId) ?? '',
            columnFilter: { value: filters.product ?? '', onChange: (value: string) => setFilters({ ...filters, product: value }) }
        },
        {
            name: label('code'), value: x => x.code,
            columnFilter: { value: filters.code ?? '', onChange: (value: string) => setFilters({ ...filters, code: value }) }
        },
        {
            name: label('period'), value: x => getPeriodString(x),
            columnFilter: { value: filters.period ?? '', onChange: (value: string) => setFilters({ ...filters, period: value }) }
        },
        { name: label('exchangeRate'), value: x => x.exchangeRate },
        {
            name: label('exchangeRateUnit'), value: x => x.exchangeRateUnit,
            columnFilter: { value: filters.exchangeRateUnit ?? '', onChange: (value: string) => setFilters({ ...filters, exchangeRateUnit: value }) }
        },
        { name: label('actualPrice'), value: x => x.actualPrice },
        {
            name: label('quantityPriceUnit'), value: x => x.quantityPriceUnit,
            columnFilter: { value: filters.quantityPriceUnit ?? '', onChange: (value: string) => setFilters({ ...filters, quantityPriceUnit: value }) }
        }
    ]

    let exportExcel = async () => {
        excelGenerator.generate({
            filename: 'RegulatedPrices.xlsx',
            sheets: [{ name: 'RegulatedPrices', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let onImportExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/regulatedPrice/import'
        api.upload(uploadUrl, file, 'regulatedPrice', { withReport: 'dialog' }).then(_ => load())
    }

    let regulatedPriceName = label('name')

    return (
        <div className={classes.container}>
            <MasterDataShell
                headerLabel={regulatedPriceName}
                itemLabel={regulatedPriceName}
                isManager={isManager}
                onExportExcel={exportExcel}
                onImportExcel={onImportExcel}
                onNew={() => zero(userContext.currentCountry)}
                onDelete={onDeleteRegulatedPrice}
                onSave={onSaveRegulatedPrice}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <TextField label={''} disabled={true} text={selectedItem.country} />
                            <Select label={label('product')}
                                value={selectedItem.productId}
                                choices={products.map(x => ({ value: x.productId, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, productId: val }) }} />
                            <Select label={label('indexName')}
                                value={selectedItem.code}
                                choices={regulatedPriceIndexes.map(x => ({ value: x.medecoCode, text: `${x.name} (${x.currency}/${x.unitOfMeasurement})` }))}
                                onChange={val => {
                                    if (val) {
                                        let index = getIndex(regulatedPriceIndexes, val)
                                        setSelectedItem({
                                            ...selectedItem,
                                            code: val,
                                            name: index?.name ?? '',
                                            longName: index?.longName ?? '',
                                            exchangeRateUnit: !!index ? exchangeRateUnit(index!.currency) : '',
                                            quantityPriceUnit: !!index ? quantityPriceUnit(index) : ''
                                        })
                                    }
                                }} />
                            <TextField label={label('code')} disabled={true} text={selectedItem.code} />
                            <TextField label={label('longName')} disabled={true} text={selectedItem.longName} />
                            <TextField label={label('quantityPriceUnit')} disabled={true} text={selectedItem.quantityPriceUnit} />
                            <DatePicker date={selectedItem.startDate}
                                label={label('start')}
                                setDate={newDate => setSelectedItem({ ...selectedItem, startDate: newDate })} />
                            <DatePicker date={selectedItem.endDate}
                                label={label('end')}
                                setDate={newDate => setSelectedItem({ ...selectedItem, endDate: newDate })} />
                            <NumberField label={label('actualPrice')}
                                text={selectedItem.actualPrice}
                                onChange={x => setSelectedItem({ ...selectedItem, actualPrice: x })} />
                            <TextField label={label('exchangeRateUnit')} disabled={true} text={selectedItem.exchangeRateUnit} />
                            <NumberField label={label('exchangeRate')}
                                text={selectedItem.exchangeRate}
                                onChange={x => setSelectedItem({ ...selectedItem, exchangeRate: x })} />
                        </>
                    )}
            </MasterDataShell>
        </div>)
}


let styles = (_) => createStyles({ container: { height: '100%' } })

export default withStyles(styles, muiOptions)(RegulatedPriceMasterData)