import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, createStyles, withStyles, Typography, Tooltip, IconButton } from '@material-ui/core'
import { defaultColors, defaultStyles, muiOptions } from '../../infrastructure/materialUiThemeProvider'
import { api } from '../../infrastructure/api'
import { t } from '../../infrastructure/i18nextHelper'
import MUIRichTextEditor from 'mui-rte'
import moment from 'moment'
import { convertToRaw, convertFromRaw } from 'draft-js'
import { Button } from './customComponents'

function _CommentDialog({ classes, ...props }: CommentDialogProps) {
    let [isDialogOpened, setIsDialogOpened] = React.useState<boolean>(false)
    let [comment, setComment] = React.useState<CommentItem | null>(null)
    let [content, setContent] = React.useState<Draft.EditorState | null>(null)
    let [plainText, setPlainText] = React.useState<string | null>(null)
    let [isCommentEmpty, setIsCommentEmpty] = React.useState<boolean>(false)

    const controls = ["title", "bold", "italic", "underline",
        "strikethrough", "highlight", "undo", "redo", "link",
        "numberList", "bulletList", "quote", "code", "clear", "media"]

    React.useEffect(() => { loadComment() }, [props.associationKey])

    const onSave = async () => {
        if (!content) return

        await api.post('comment', {
            associationKey: props.associationKey,
            content: JSON.stringify(convertToRaw(content.getCurrentContent()))
        })
        await loadComment()
        setIsDialogOpened(false)
    }

    async function loadComment() {
        if (!props.associationKey) return

        let commentFromApi = await api.get<CommentItem | null>(`comment?associationKey=${props.associationKey}`)
        setComment(commentFromApi)

        checkIfCommentIsEmpty(commentFromApi?.content)
    }

    function checkIfCommentIsEmpty(content: string | undefined) {
        if (content) {
            let parsedComment = convertFromRaw(JSON.parse(content))
            let plainTxt = parsedComment.getPlainText()
            setPlainText(plainTxt)
            setIsCommentEmpty(!plainTxt)
        }
        else setIsCommentEmpty(true)
    }

    function getClass() { return comment && !isCommentEmpty ? '' : props.className ? props.className : '' }

    return (
        <div className={getClass()}>
            <IconButton size={'small'} onClick={() => setIsDialogOpened(true)}>
                {comment && !isCommentEmpty
                    ? <Tooltip onClick={() => setIsDialogOpened(true)} title={< Typography variant='body1' > {plainText}</Typography >} placement='top' >
                        <img src='/static/images/chat_icon.svg' className={classes.commentIcon} />
                    </Tooltip >
                    : <img src='/static/images/empty_chat_icon.svg' className={classes.commentIcon} width="10px" height="10px" />}
            </IconButton>

            <Dialog
                open={isDialogOpened}
                onClose={() => setIsDialogOpened(false)}
                scroll={'paper'}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'>
                <DialogTitle className={classes.dialogContainer}>
                    <div className={classes.popupHeader}>
                        {t('comment.dialogTitle')}
                        {comment
                            ? <Typography className={classes.commentInfo}>
                                {t('comment.infoLabel', {
                                    user: comment.user,
                                    date: moment(comment?.createdAt).format('MM/DD/YYYY')
                                })}
                            </Typography>
                            : null}
                    </div>

                </DialogTitle>
                <DialogContent className={classes.dialogContentContainer} dividers={true}>
                    <div className={classes.contentContainer}>
                        <MUIRichTextEditor
                            defaultValue={comment?.content}
                            onChange={e => setContent(e)}
                            inheritFontSize={true}
                            classes={{}}
                            label={t('comment.placeHolder')}
                            controls={controls} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.closeButton}
                        label={t('comment.closeButton')}
                        onClick={() => { setIsDialogOpened(false) }}
                        color='primary' />
                    <Button className={classes.saveButton}
                        label={t('comment.saveButton')}
                        onClick={onSave}
                        color='primary' />
                </DialogActions>
            </Dialog>
        </div>)
}

let styles = _ =>
    createStyles({
        saveButton: { ...defaultStyles.dialogPrimaryButton },
        closeButton: { ...defaultStyles.dialogCloseButton },
        contentContainer: { minHeight: '15em', width: '45em' },
        line: { ...defaultStyles.flexRow, alignItems: 'flex-end' },
        title: { marginRight: '0.5em' },
        value: { fontWeight: 'lighter' },
        commentInfo: {
            fontSize: '0.7em',
            fontStyle: 'italic',
            color: defaultColors.grey.light.color
        },
        dialogContainer: {
            paddingTop: '10px',
            paddingBottom: '10px'
        },
        dialogContentContainer: {
            paddingTop: '0px',
            paddingBottom: '10px'
        },
        popupHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        commentIcon: {
            height: '1em',
            width: '1em',
        },
        divider: {

        }
    })

export let CommentDialog = withStyles(styles, muiOptions)(_CommentDialog)

type CommentDialogProps = {
    associationKey: string
    className?: string
    classes?: any
}

type CommentItem = {
    associationKey: string
    content: string
    user: string
    createdAt: Date
}
