import React from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions } from '../../materialUiThemeProvider'
import { NotificationStore } from './container'
import { badgeStyles } from './styles'

let _WithBadge = (props: React.PropsWithChildren<{ state: NotificationStore }> & { classes }) => {
    let badgeAlertLevel = React.useMemo(() =>
        props.state.list.some(x => x.alertLevel === 'error') ? 'error'
            : props.state.list.some(x => x.alertLevel === 'warning') ? 'warning'
                : 'info', [props.state.list])

    let notificationCount = props.state.list.length > 99 ? '99+' : props.state.list.length

    return props.state.list.length > 0
        ? <div className={props.classes.container}>
            <div className={props.classes.child}>
                {props.children}
            </div>
            <div className={props.classes.badge + ' ' + badgeAlertLevel}>
                <span className={props.classes.badgeFont}>{notificationCount}</span>
            </div>
        </div>
        : <>{props.children}</>
}

let styles = theme =>
    createStyles(badgeStyles)

export let WithBadge = withStyles(styles, muiOptions)(_WithBadge)