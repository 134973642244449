import React from 'react'
import { withStyles, createStyles, Paper } from '@material-ui/core'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { hasFeature } from '../../../infrastructure/feature'
import { StockBoardFilters } from './_stockMainFilters'
import { ResellerRestrictionFilter } from './_stockResellerRestriction'
import { StockPercentUsefulStockFilters } from './_stockPercentUsefulStockFilters'
import { StockBoardContainer } from '../stockBoardStore'

type Props = { classes?: any, isStockChartOrTableDisplayed: boolean }

function _Filters(props: Props) {
    return (
        <div className={props.classes.bar}>
            {hasFeature('StockPercentUsage') && props.isStockChartOrTableDisplayed
                && <Paper>
                    <StockPercentUsefulStockFilters />
                </Paper>}
            {hasFeature('RestrictionOnReseller')
                && <Paper>
                    <ResellerRestrictionFilter />
                </Paper>}
            <Paper>
                <StockBoardFilters />
            </Paper>
        </div>
    )
}

let styles = () =>
    createStyles({
        bar: {
            height: '4em',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            '& > *': {
                height: '100%',
                padding: '0em 0.3em',
                display: 'flex',
                alignItems: 'center',
                marginRight: '1em',
                '&:last-child': {
                    marginRight: '0em'
                }
            },
        }
    })

export let Filters = withStyles(styles, muiOptions)(_Filters)