import React from 'react'
import { muiOptions, defaultStyles, defaultColors } from '../../materialUiThemeProvider'
import { t } from '../../i18nextHelper'
import { createStyles, withStyles, Tooltip, Typography } from '@material-ui/core'
import { HelpButton } from '../../../app/common/components/helpButton'
import { Button } from '../../../app/common/customComponents'

type Props = {
    label?: string
    tooltip?: string
    disabled?: boolean
    validated?: boolean
    hide?: boolean
    className?: string
    classes?: any
    onClick: (() => Promise<void>)
    onHelpClick?: () => void
}

function _sapButton(props: Props) {
    if (props.hide) return (<></>)

    let btnClassName = props.classes.sapButton + ' ' +
        (props.disabled && !props.validated ? props.classes.disabled + ' ' : '') +
        (props.validated ? props.classes.validated : '')

    return (
        <div className={btnClassName}>
            <HelpButton title={t('stock.label.movement.sapAction.tooltipTitle')} text={props.tooltip ?? ''} onClick={props.onHelpClick}>
                <Tooltip
                    disableHoverListener={!props.tooltip || props.validated}
                    title={<Typography variant='body1'>{t('stock.label.movement.sapAction.pleaseFillForm')}</Typography>} placement='top'>
                    <div>
                        <Button onClick={props.onClick}
                            label={props.label}
                            disabled={!!props.disabled}
                            className={btnClassName} />
                    </div>
                </Tooltip>
            </HelpButton>
        </div>
    )
}

let styles = _ =>
    createStyles({
        sapButton: {
            '& button': {
                ...defaultStyles.primaryButton,
                minWidth: '18em',
                margin: '0.5em'
            }
        },
        disabled: {
            '& button': {
                backgroundColor: 'silver',
                cursor: 'default'
            }
        },
        validated: {
            '& button': {
                cursor: 'default',
                color: defaultColors.green.light.text,
                backgroundColor: defaultColors.green.light.color
            }
        }
    })

export let SapButton = withStyles(styles, muiOptions)(_sapButton)