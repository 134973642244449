
import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { api } from '../../../infrastructure/api'
import { ColumnDescriptor, TableItem, TextField, NumberField } from '../../common/customComponents'
import { MasterDataItem, MasterDataShell } from './masterDataShell'

function ProductMasterData({ classes }: MuiProps) {
    let [product, setProduct] = useState<TableItem<Product>[]>([])
    let [filters, setFilters] = useState<ProductFilters>(noFilters)

    const tBase = 'admin.masterdata.product.'

    let load = async () => {
        let products = api.get<TableItem<Product>[]>('masterdata/product')
        setProduct(await products)
    }

    let getItems = () => applyFilters(product, filters).map(toTableItem)

    useEffect(() => {
        load()
    }, [])

    let onSave = async (item: Product & IsNew) => {
        await api.post('masterdata/product', item)
        await load()
        return true
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<Product>>>[] = [
        {
            name: t(tBase + 'code'),
            value: x => x.code,
            columnFilter: { value: filters.code ?? '', onChange: (code: string) => setFilters({ ...filters, code }) }
        },
        {
            name: t(tBase + 'color'),
            value: x => x.color,
            tooltipText: t(tBase + 'colorTooltip'),
            htmlFor: x => (<div className={classes.colorContainer}>{x.color}<div className={classes.colorViewer} style={{ background: x.color }} /></div>)
        },
        {
            name: t(tBase + 'order'),
            tooltipText: t(tBase + 'orderTooltip'),
            value: x => x.order
        }
    ]

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'product-table'}
                headerLabel={t(tBase + 'product')}
                itemLabel={t(tBase + 'product')}
                isManager={hasClaim(Claims.MasterdataProductManager)}
                onNew={emptyProduct}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <TextField label={t(tBase + 'code')}
                                disabled={!selectedItem.isNew}
                                text={selectedItem.code}
                                onChange={event => setSelectedItem({ ...selectedItem, code: event.target.value })} />
                            <div className={classes.colorContainer}>
                                <TextField label={t(tBase + 'color')}
                                    text={selectedItem.color}
                                    onChange={event => setSelectedItem({ ...selectedItem, color: event.target.value })} />
                                <div className={classes.colorViewer} style={{ background: selectedItem.color }} />
                            </div>
                            <NumberField label={t(tBase + 'order')}
                                text={selectedItem.order}
                                precision={2}
                                onChange={value => setSelectedItem({ ...selectedItem, order: value })} />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

type Product = {
    id: string
    code: string
    color: string
    order: number
}

type IsNew = { isNew: boolean }

let emptyProduct = (): Product & IsNew => ({
    id: '',
    code: '',
    color: '#000000',
    order: 1,
    isNew: true
})

let toTableItem = (product: Product): TableItem<MasterDataItem<Product>> => {
    return {
        ...product,
        id: product.code,
        isModified: false
    }
};

type ProductFilters = {
    code: string | null,
}

let noFilters: ProductFilters = {
    code: null,
}

const filterByInput = (prop: string, input: string) => (filter: any) => filter[prop].toLowerCase().contains(input.toLowerCase());

let applyFilters = (products: Product[], filters: ProductFilters): Product[] => {
    for (const input in filters) {
        if (filters[input])
            products = products.filter(filterByInput(input, filters[input]))
    }
    return products
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        colorContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-around',
            "& >span": { padding: '0' }
        },
        colorViewer: {
            height: '1em',
            width: '1em',
            borderRadius: '20%'
        }
    })
export default withStyles(styles, muiOptions)(ProductMasterData)