import React, { useEffect, useState } from "react"
import { createStyles, withStyles } from "@material-ui/core"
import { MuiProps, muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { Guid } from '../../../infrastructure/guid'
import { MasterDataItem, MasterDataShell, createExcelLines } from "./masterDataShell"
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { Claims } from '../../../infrastructure/signIn/models'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { t } from '../../../infrastructure/i18nextHelper'
import { SnackbarContainer } from '../../../infrastructure/snackbars'
import { ColumnDescriptor, TableItem, TextField } from '../../common/customComponents'
import * as api from '../../../infrastructure/api'

export type QuantityUnitPrice = {
    id: Guid | null
    quantity: string
    unitPrice: string
    currency: string
    order: number
}

type QuantityUnitPriceFilters = {
    quantity: string | null,
    unitPrice: string | null,
    currency: string | null
}

let noFilters: QuantityUnitPriceFilters = {
    quantity: null,
    unitPrice: null,
    currency: null
}

let applyFilters = (quantityUnitPrices: QuantityUnitPrice[], filters: QuantityUnitPriceFilters): QuantityUnitPrice[] => {
    if (filters.quantity)
        quantityUnitPrices = quantityUnitPrices.filter(x => x.quantity.toLowerCase().contains(filters.quantity!.toLowerCase()))

    if (filters.unitPrice)
        quantityUnitPrices = quantityUnitPrices.filter(x => x.unitPrice.toLowerCase().contains(filters.unitPrice!.toLowerCase()))

    if (filters.currency)
        quantityUnitPrices = quantityUnitPrices.filter(x => x.currency.toLowerCase().contains(filters.currency!.toLocaleLowerCase()))

    return quantityUnitPrices
}

let toTableItem = (quantityUnitPrice: QuantityUnitPrice): TableItem<MasterDataItem<QuantityUnitPrice>> => {
    return {
        id: quantityUnitPrice.id!,
        quantity: quantityUnitPrice.quantity,
        unitPrice: quantityUnitPrice.unitPrice,
        currency: quantityUnitPrice.currency,
        order: quantityUnitPrice.order,
        isModified: false
    }
}

let emptyItem = () => ({
    id: '',
    quantity: '',
    unitPrice: '',
    currency: '',
    order: 1
})


function QuantityUnitPriceMasterdata({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()
    let snackbar = SnackbarContainer.useContainer()
    let [quantityUnitPrices, setQuantityUnitPrices] = useState<QuantityUnitPrice[]>([])
    let [filters, setFilters] = useState<QuantityUnitPriceFilters>(noFilters)

    let tBase = 'admin.masterdata.quantityUnitPrice.'

    let isManager = hasClaim(Claims.MasterdataQuantityUnitPriceManager)

    let load = async () => {
        let dtos = await api.get<QuantityUnitPrice[]>('masterdata/quantityunitprice')
        setQuantityUnitPrices(dtos)
    }

    useEffect(() => {
        load()
    }, [])

    let getItems = () => applyFilters(quantityUnitPrices, filters).map(toTableItem)

    let onSaveHandler = async (item: QuantityUnitPrice) => {
        if (!item.quantity || !item.unitPrice || !item.currency) {
            snackbar.warning(t(tBase + 'mandatoryFields'))
            return false
        }

        if (!item.order)
            item.order = 1

        await api.post('masterdata/quantityunitprice', item)
        load()
        return true
    }

    let onDeleteHandler = async (ids: string[]) => {
        await api.del('masterdata/quantityUnitPrice', { ids: ids })
        load()
        return true
    }

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'QuantityUnitPrices.xlsx',
            sheets: [{ name: 'QuantityUnitPrices', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<QuantityUnitPrice>>>[] = [
        {
            name: t(tBase + 'quantity'),
            value: x => x.quantity,
            columnFilter: { value: filters.quantity ?? '', onChange: (quantity: string) => setFilters({ ...filters, quantity }) }
        },
        {
            name: t(tBase + 'unitPrice'),
            value: x => x.unitPrice,
            columnFilter: { value: filters.unitPrice ?? '', onChange: (unitPrice: string) => setFilters({ ...filters, unitPrice }) }
        },
        {
            name: t(tBase + 'currency'),
            value: x => x.currency,
            columnFilter: { value: filters.currency ?? '', onChange: (currency: string) => setFilters({ ...filters, currency }) }
        },
        {
            name: t(tBase + 'order'),
            value: x => x.order
        }
    ]

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId="dealType-table"
                headerLabel={t(tBase + 'quantityUnitPrice')}
                itemLabel={t(tBase + 'quantityUnitPrice')}
                isManager={isManager}
                columns={columns}
                items={getItems()}
                onSave={onSaveHandler}
                onNew={emptyItem}
                onDelete={onDeleteHandler}
                onExportExcel={exportExcel}
            >{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <TextField label={t(tBase + 'quantity')}
                                disabled={!isManager}
                                text={selectedItem.quantity}
                                onChange={event => setSelectedItem({ ...selectedItem, quantity: event.target.value })} />
                            <TextField label={t(tBase + 'unitPrice')}
                                disabled={!isManager}
                                text={selectedItem.unitPrice}
                                onChange={event => setSelectedItem({ ...selectedItem, unitPrice: event.target.value })} />
                            <TextField label={t(tBase + 'currency')}
                                disabled={!isManager}
                                text={selectedItem.currency}
                                onChange={event => setSelectedItem({ ...selectedItem, currency: event.target.value })} />
                            <TextField label={t(tBase + 'order')}
                                disabled={!isManager}
                                text={selectedItem.order}
                                onChange={event => setSelectedItem({ ...selectedItem, order: event.target.value })} />
                        </>
                    )
                }
            </MasterDataShell>
        </div>
    )
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' }
    })

export default withStyles(styles, muiOptions)(QuantityUnitPriceMasterdata)