import React, { useState, useEffect } from 'react'
import { AddOutlined } from '@material-ui/icons'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { withStyles, createStyles, Checkbox, Typography, Grid, Accordion, AccordionDetails, AccordionSummary, Chip, Badge, IconButton } from '@material-ui/core'
import { defaultColors, defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { api } from '../../../infrastructure/api'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { t } from '../../../infrastructure/i18nextHelper'
import { Claims } from '../../../infrastructure/signIn/models'
import guid from '../../../infrastructure/guid'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { MasterDataShell, MasterDataItem, createExcelLines } from './masterDataShell'
import { ColumnDescriptor, Select, MultipleSelect, TableItem, TextField } from '../../common/customComponents'


type Site = {
    code: string
    name: string
}

type Company = {
    code: string
    name: string
}

type ChannelCustomerSegment = {
    customerSegment: string
}

type Claim = {
    name: string
    category: string
    scope: string | null
}

type PersonaClaim = {
    id: string
    personaId: string
    claim: string
    scope: string[]
}

type Persona = {
    id: string
    name: string
    description: string
    country: string | null
    claims: PersonaClaim[]
}

type PersonaFilter = {
    name: string | null
    country: string | null
}

let noFilters: PersonaFilter = {
    name: null,
    country: null
}

let zero = (): Persona => {
    return {
        id: guid.empty,
        name: '',
        description: '',
        country: null,
        claims: []
    }
}

let toTableItem = (persona: Persona): TableItem<MasterDataItem<Persona>> => {
    return {
        id: persona.id,
        name: persona.name,
        description: persona.description,
        country: persona.country,
        claims: persona.claims,
        isModified: false
    }
}

type PersonaEditorProps = {
    isManager: boolean
    allCountrys: string[]
    allClaims: Claim[]
    selectedPersona: Persona
    setSelectedPersona: (item: Persona) => void
} & MuiProps

function PersonaEditor({ isManager, allCountrys, allClaims, selectedPersona, setSelectedPersona, classes }: PersonaEditorProps) {
    let [allSites, setAllSites] = useState<Site[]>([])
    let [allCompanys, setAllCompanys] = useState<Company[]>([])
    let [allChannelCustomerSegments, setAllChannelCustomerSegments] = useState<ChannelCustomerSegment[]>([])
    let [expanded, setExpanded] = useState<string | null>(null)
    let [localInput, setLocalInput] = useState<string>('')

    let init = async () => {
        let claims = api.get<PersonaClaim[]>('masterdata/persona/claim/' + selectedPersona.id)

        await load(selectedPersona.country)

        setSelectedPersona({ ...selectedPersona, claims: (await claims) })
        setExpanded(null)
    }

    let load = async (country: string | null) => {
        let args = country ? `/${country}` : ''

        let allSites = api.get<Site[]>('masterdata/persona/site' + args)
        let allCompanys = api.get<Company[]>('masterdata/persona/company' + args)
        let allChannelCustomerSegments = api.get<ChannelCustomerSegment[]>('masterdata/persona/channelCustomerSegment' + args)

        setAllSites((await allSites).sort((a, b) => a.name.localeCompare(b.name)))
        setAllCompanys((await allCompanys).sort((a, b) => a.name.localeCompare(b.name)))
        setAllChannelCustomerSegments((await allChannelCustomerSegments)
            .distinctBy((a, b) => a.customerSegment === b.customerSegment)
            .sort((a, b) => a.customerSegment.localeCompare(b.customerSegment)))
    }

    useEffect(() => { let effect = async () => { await init() }; effect() }, [selectedPersona.id])

    let setCountry = async (country: string) => {
        await load(country)

        let claims = selectedPersona.claims.map(x => ({ ...x, scope: [] }))
        setSelectedPersona({ ...selectedPersona, claims: claims, country: country })
    }

    let setScope = async (claim: string, scope: string[]) => {
        let claims = selectedPersona.claims.map(x => x.claim === claim ? ({ ...x, scope: scope }) : x)
        setSelectedPersona({ ...selectedPersona, claims: claims })
    }

    let toggleClaim = (claim: Claim) => {
        let claims =
            !!selectedPersona.claims.find(x => x.claim === claim.name)
                ? selectedPersona.claims.filter(x => x.claim != claim.name)
                : selectedPersona.claims.concat([{ id: guid.empty, claim: claim.name, scope: [], personaId: selectedPersona.id }])

        setSelectedPersona({ ...selectedPersona, claims: claims })
    }

    let toggleCategory = (category) => (event, isExpanded) => {
        setExpanded(isExpanded ? category : false);
    }

    let isSiteScope = (claim: Claim) => claim.scope === 'Site'
    let isCompanyScope = (claim: Claim) => claim.scope === 'Company'
    let isCompanyDutyStatusScope = (claim: Claim) => claim.scope === 'CompanyDutyStatus'

    let saveCompanyDutyStatus = (claim: string, checked: PersonaClaim | undefined) => {
        let values = localInput.split('/')
        if (values.length !== 2) return
        let company = allCompanys.find(x => x.name === values[0])
        if (!company) return
        let value = `${company.code}/${values[1]}`
        setScope(claim, (checked ? checked.scope : []).concat([value]))
        setLocalInput('')
    }

    let formatCompanyDutyStatus = (value: string) => {
        let values = value.split('/')
        if (values.length !== 2) return 'ERROR: ' + value
        let company = allCompanys.find(x => x.code === values[0])
        if (!company) return 'ERROR: ' + value
        return `${company.name}/${values[1]}`
    }

    let claimsEditor = (selectedPersona: Persona) => allClaims
        .groupBy(claim => claim.category, claim => claim)
        .map(([category, claims]) => {
            return (
                <Accordion key={category} expanded={expanded === category} onChange={toggleCategory(category)} classes={{ root: classes.categoryContainer }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        {claims.filter(claim => selectedPersona.claims?.map(xx => xx.claim).includes(claim.name)).length > 0
                            ? <Badge variant="dot" classes={{ badge: classes.redBadge }}><Typography className={classes.categoryTitle}>{category}</Typography></Badge>
                            : <Typography className={classes.categoryTitle}>{category}</Typography>}
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.claimsContainer }}>
                        {claims.map((claim) => {
                            let checked = selectedPersona.claims?.find(x => x.claim === claim.name)
                            return (
                                <Grid key={claim.name} container spacing={0} alignItems='center' className={classes.claimLine}>
                                    <Grid item xs='auto'>
                                        <Checkbox disabled={!isManager} style={{ color: !!checked ? defaultColors.red.main.color : '' }} checked={!!checked} onChange={() => toggleClaim(claim)} />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography className={classes.clickable} onClick={() => toggleClaim(claim)}>{claim.name}</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        {checked && claim.scope && isCompanyDutyStatusScope(claim) &&
                                            <div>
                                                <TextField
                                                    label={'Company/DutyStatus'}
                                                    text={localInput}
                                                    onChange={event => setLocalInput(event.target.value)}
                                                    onKeyDown={evt => {
                                                        const enterKey = 13
                                                        if (evt.keyCode == enterKey && localInput?.length > 1) {
                                                            saveCompanyDutyStatus(claim.name, checked)
                                                            evt.preventDefault()
                                                        }
                                                    }} />
                                                <IconButton onClick={() => saveCompanyDutyStatus(claim.name, checked)}>
                                                    <AddOutlined />
                                                </IconButton>
                                                <br />
                                                <br />
                                                {(checked ? checked.scope : []).map(x =>
                                                    <Chip key={x} label={formatCompanyDutyStatus(x)}
                                                        onDelete={_ => checked && setScope(claim.name, checked.scope.filter(y => y !== x))} />)}

                                            </div>}
                                        {checked && claim.scope && !isCompanyDutyStatusScope(claim) &&
                                            <MultipleSelect
                                                label={isSiteScope(claim)
                                                    ? t('admin.masterdata.persona.siteScope')
                                                    : isCompanyScope(claim)
                                                        ? t('admin.masterdata.persona.companyScope')
                                                        : t('admin.masterdata.persona.channelCustomerSegmentScope')}
                                                chips={false}
                                                allWhenEmpty={false}
                                                disabled={!isManager || !checked}
                                                values={checked ? checked.scope : []}
                                                choices={isSiteScope(claim)
                                                    ? allSites.map(x => ({ value: x.code, text: x.name }))
                                                    : isCompanyScope(claim)
                                                        ? allCompanys.map(x => ({ value: x.code, text: x.name }))
                                                        : allChannelCustomerSegments.map(x => ({ value: x.customerSegment, text: x.customerSegment }))}
                                                onChange={val => setScope(claim.name, val)} />}
                                    </Grid>
                                </Grid>)
                        })}
                    </AccordionDetails>
                </Accordion>)
        })

    return (
        <>
            <div>
                <TextField label={t('admin.masterdata.persona.name')}
                    disabled={!isManager}
                    text={selectedPersona.name}
                    onChange={event => setSelectedPersona({ ...selectedPersona, name: event.target.value })} />

                <TextField label={t('admin.masterdata.persona.description')}
                    disabled={!isManager}
                    text={selectedPersona.description}
                    onChange={event => setSelectedPersona({ ...selectedPersona, description: event.target.value })} />

                <Select label={t('admin.masterdata.persona.country')}
                    disabled={!isManager}
                    isClearable={true}
                    clearableLabel={t('admin.masterdata.persona.noCountry')}
                    value={selectedPersona.country}
                    choices={allCountrys.map(x => ({ value: x, text: x }))}
                    onChange={val => { setCountry(val) }} />
            </div>
            <>{claimsEditor(selectedPersona)}</>
        </>
    )
}

function PersonaMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [allClaims, setAllClaims] = useState<Claim[]>([])
    let [allCountrys, setAllCountrys] = useState<string[]>([])
    let [allPersonas, setAllPersonas] = useState<Persona[]>([])
    let [filters, setFilters] = useState<PersonaFilter>(noFilters)

    let applyFilters = (personas: Persona[]) => {
        if (filters.name)
            personas = personas.filter(persona => persona.name.toLowerCase().contains(filters.name!.toLowerCase()))

        if (filters.country)
            personas = personas.filter(persona => persona.country?.toLowerCase().contains(filters.country!.toLowerCase()))

        return personas
    }

    let init = async () => {
        let allClaims = api.get<Claim[]>('masterdata/persona/claim')
        let allCountrys = api.get<string[]>('masterdata/persona/country')

        load()

        setAllClaims(await allClaims)
        setAllCountrys(await allCountrys)
    }

    let load = async () => {
        let allPersonas = api.get<Persona[]>('masterdata/persona')
        setAllPersonas(await allPersonas)
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<Persona>>>[] = [
        {
            name: t('admin.masterdata.persona.name'), value: x => x.name,
            columnFilter: { value: filters.name ?? '', onChange: (name: string) => setFilters({ ...filters, name }) }
        },
        {
            name: t('admin.masterdata.persona.country'), value: x => x.country,
            columnFilter: { value: filters.country ?? '', onChange: (country: string) => setFilters({ ...filters, country }) }
        },
        {
            name: t('admin.masterdata.persona.description'), value: x => x.description
        }
    ]

    let getItems = () => applyFilters(allPersonas).map(toTableItem)

    useEffect(() => { let effect = async () => { await init() }; effect() }, [])

    let personaLabel = t('admin.masterdata.persona.persona')

    let isManager = hasClaim(Claims.PersonaManager)

    let exportExcel = async () => {
        excelGenerator.generate({
            filename: 'personas.xlsx',
            sheets: [{ name: 'Personas', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let onDuplicate = async (item: Persona) => {
        await api.post('masterdata/persona/duplicate', item)
        await load()
        return true
    }

    let onSave = async (item: Persona) => {
        await api.post('masterdata/persona', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        if (ids.length === 0) return false
        await api.del('masterdata/persona', { ids: ids })
        await load()
        return true
    }

    return (
        <MasterDataShell
            largeDrawer={true}
            drawerActions={[
                { name: 'Duplicate', onClick: onDuplicate }
            ]}
            headerLabel={personaLabel}
            itemLabel={personaLabel}
            isManager={isManager}
            onExportExcel={exportExcel}
            onNew={zero}
            onDelete={onDelete}
            onSave={onSave}
            items={getItems()}
            columns={columns}>{
                (selectedItem, setSelectedItem) =>
                (<PersonaEditor
                    isManager={isManager}
                    allCountrys={allCountrys}
                    allClaims={allClaims}
                    selectedPersona={selectedItem}
                    setSelectedPersona={setSelectedItem}
                    classes={classes} />)
            }
        </MasterDataShell>
    )
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        group: {
            ...defaultStyles.flexRow,
            justifyContent: 'start',
            alignItems: 'center',
            padding: '0em 1%',
            width: '100%'
        },
        clickable: { cursor: 'pointer !important' },
        claimLine: { margin: '0 0 0.2em 0 !important' },
        categoryTitle: {
            color: defaultColors.red.main.color
        },
        claimsContainer: {
            display: 'block'
        },
        categoryContainer: {
            margin: '0 0 1em 0 !important'
        },
        redBadge: {
            backgroundColor: defaultColors.red.main.color
        }
    })

export default withStyles(styles, muiOptions)(PersonaMasterData)