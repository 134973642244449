import React, { useEffect, useState } from 'react'
import {
    withStyles, createStyles, TableHead,
    TableRow, TableCell, Checkbox,
    Table, TableBody, Tooltip,
    Typography, TableFooter, IconButton,
    Radio
} from '@material-ui/core'
import { CSSProperties } from '@material-ui/styles'
import { muiOptions, MuiProps, defaultColors } from '../../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../../infrastructure/i18nextHelper'
import { useInputDebounce } from '../../debounce'
import { SearchField } from '../../customComponents'
import * as Icons from '@material-ui/icons'

type ItemOptions = { isModified: boolean }

export type TableItem<T> = T & ItemOptions

export type ColumnFilter = {
    value: string,
    onChange: (filter: string | null) => void
}

export type ColumnDescriptor<T> = {
    name: string
    tourKey?: string
    nameHtml?: JSX.Element
    tooltipText?: string
    tooltipValue?: (x: T) => string | null
    value?: (x: T) => string | number | string[] | null | JSX.Element
    type?: string
    valueForExport?: (x: T) => string | number | null
    alignRight?: boolean
    htmlFor?: (x: T) => JSX.Element
    columnFilter?: ColumnFilter
    total?: string | number | null
    totalLabel?: string | null
    wrapText?: boolean,
    cellAction?: (x: T) => JSX.Element
    hidden?: boolean
    leftBorder?: boolean
    defaultGroup?: string
    groupedBy?: string
    wrapTextMax?: boolean,
}

export type GroupDescriptor = {
    name: string
    title: string
    colSpan?: number
    withHiddenColumns?: boolean
}

export type ActionDescriptor<T> = {
    name: string
    action: (selected: T[] | T) => unknown
    icon: JSX.Element
    isBodyAction?: boolean,
    isHidden?: boolean,
    disabled?: (selected: T[] | T) => boolean
    noMarginOnIcon?: boolean
}

export type DatagridProps<T> = {
    className?: string
    items: (TableItem<T> | T)[]
    tableId?: string
    idSelector: (x: T) => string
    isSelectable?: boolean,
    isMultiSelectable?: boolean
    columns: ColumnDescriptor<T>[]
    groups?: GroupDescriptor[]
    actions?: ActionDescriptor<T>[]
    actionsDisabled?: boolean
    maxLinesOnScreen?: number
    maxLinesTotal?: number
    onItemMouseEnter?: (item: T) => void
    onMouseLeave?: () => void
    onSelectionChange?: (items: T[] | T) => void
    onClick?: (item: T) => void
} & MuiProps

function _DataGrid<T>(props: DatagridProps<T>) {
    let { classes, columns, groups, items, idSelector, onClick, actions, actionsDisabled: actionDisabled = false, isSelectable = true, isMultiSelectable = true, className } = props
    let [selectedIds, setSelectedIds] = useState<string[]>([])
    let [openedGroups, setOpenedGroups] = useState<{ [key: string]: boolean }>({});
    let headerActions = actions?.filter(x => !x.isBodyAction)
    let bodyActions = actions?.slice().reverse().filter(x => x.isBodyAction ?? false)

    let act = (action: ActionDescriptor<T>) => {
        let itemsSelected = items.filter(x => selectedIds.indexOf(idSelector(x)) >= 0)
        action.action(itemsSelected)
    }

    let isHidden = (c) => c.hidden || (c.groupedBy && !openedGroups[c.groupedBy])

    let toggleGroup = (groupName: string) => {
        setOpenedGroups(prev => ({ ...prev, [groupName]: !prev[groupName] }));
    };

    useEffect(() => {
        if (props.onSelectionChange)
            props.onSelectionChange(items.filter(x => selectedIds.indexOf(idSelector(x)) >= 0))
    }, [selectedIds])

    let groupColSpan = (groupName: string) =>
        openedGroups[groupName] ? columns.filter(x => x.groupedBy === groupName || x.defaultGroup === groupName).length : 1

    let columnTableCellClass = (c: ColumnDescriptor<T>): string => (!!c.alignRight ? classes.cellRightPadding : classes.cellPadding) + ' ' + (c.wrapText ? classes.wrapCell : '')
        + ' ' + (c.leftBorder ? classes.separator : ' ') + ' ' + (c.wrapTextMax ? classes.wrapCellMax : '')
        + ' ' + (c.groupedBy ? classes.toggleGroups + ' body' : '')

    return (<Table className={className} stickyHeader aria-label='sticky table' id={props.tableId ?? ''} onMouseLeave={_ => props.onMouseLeave && props.onMouseLeave()}>
        <TableHead className={classes.table + ' ' + className}>
            {groups
                ? <TableRow>
                    {groups.map(x =>
                        <TableCell
                            key={x.name}
                            className={classes.cellGroupHeader + ' ' + (x.name && classes.toggleGroups)}
                            colSpan={x.colSpan ?? groupColSpan(x.name) ?? 1}>
                            {x.withHiddenColumns
                                ? <span onClick={() => toggleGroup(x.name)}>
                                    {x.title}
                                    {openedGroups[x.name] ? <Icons.KeyboardArrowDown /> : <Icons.KeyboardArrowRight />}
                                </span>
                                : x.title}
                        </TableCell>)}
                </TableRow>
                : undefined
            }
            <TableRow>
                {isSelectable
                    ? <TableCell className={classes.cellPadding} padding='checkbox' data-exclude='true'>
                        {isMultiSelectable ?
                            (<Checkbox
                                indeterminate={selectedIds.length > 0 && selectedIds.length < items.length}
                                checked={selectedIds.length === items.length && items.length !== 0}
                                onChange={x => setSelectedIds(x.target.checked ? items.map(x => idSelector(x)) : [])}
                                classes={{ checked: classes.checkboxChecked, root: classes.noPadding }}
                                color='default'
                            />) :
                            undefined
                        }
                    </TableCell> : null
                }
                {columns.map(c => isHidden(c)
                    ? <TableCell key={c.name} data-tour={c.tourKey} className={classes.hiddenCell}>{c.name}</TableCell>
                    : c.tooltipText
                        ? <Tooltip key={c.name} title={<Typography variant='body1'>{c.tooltipText}</Typography>} placement='top'>
                            <TableCell className={c.alignRight ? classes.cellRightPadding : classes.cellPadding} data-tour={c.tourKey} >{c.name}</TableCell>
                        </Tooltip>
                        : <TableCell className={(c.alignRight ? classes.cellRightPadding : classes.cellPadding) + ' ' + (c.leftBorder ? classes.separator : '')}
                            key={c.name} data-tour={c.tourKey}>
                            {c.nameHtml ? c.nameHtml : c.name}
                        </TableCell>
                )}
                {actions &&
                    <TableCell className={(actionDisabled ? ' ' + classes.disableClick : '') + classes.noPaddingCell + ' ' + classes.noWidthCell} align={'right'}>
                        <div className={classes.actionHeader}>
                            {!!headerActions && headerActions.map(action =>
                                <div style={{ cursor: onClick ? 'pointer' : 'default' }} className={action.isHidden ? classes.hide : classes.show}
                                    id={action.name} key={action.name}>
                                    <IconButton className={classes.noSize} onClick={() => { act(action); setSelectedIds([]) }}>
                                        {action.icon}
                                    </IconButton>
                                </div>)}
                        </div>
                    </TableCell>}
            </TableRow>
            {columns.filter(x => x.columnFilter != undefined).length > 0 ?
                <TableRow data-exclude='true'>
                    <TableCell className={classes.filterHeadCell} />
                    {columns.map(c => isHidden(c)
                        ? <TableCell className={classes.hiddenCell} />
                        : <TableCell className={classes.filterHeadCell} key={c.name} >
                            {c.columnFilter != undefined
                                ? <ColumnFilterField filter={c.columnFilter!} classes={classes} />
                                : undefined}
                        </TableCell>)}
                    {actions ? <TableCell className={classes.noPaddingCell + ' ' + classes.noWidthCell} /> : undefined}
                </TableRow>
                : undefined}
        </TableHead>
        <TableBody>
            {items.map((item, index) => {
                return (
                    <TableRow style={({ cursor: onClick ? 'pointer' : 'default' })}
                        data-exclude={selectedIds.length > 0 && selectedIds.indexOf(idSelector(item)) === -1}
                        className={(typeof item === 'object' && item != null && 'isModified' in item && item.isModified) ? classes.modifiedRow : '' + ' ' + bodyActions ? classes.lineHover : ''}
                        key={idSelector(item)} >
                        {
                            isSelectable
                                ? <TableCell className={classes.cellPadding} padding='checkbox' data-exclude='true'
                                    onMouseEnter={_ => props.onItemMouseEnter && props.onItemMouseEnter(item)}>
                                    {isMultiSelectable ?
                                        (<Checkbox
                                            checked={selectedIds.indexOf(idSelector(item)) !== -1}
                                            onChange={e => setSelectedIds(e.target.checked
                                                ? selectedIds.concat([idSelector(item)])
                                                : selectedIds.filter(x => x !== idSelector(item)))}
                                            classes={{ checked: classes.checkboxChecked, root: classes.noPadding }}
                                            color='default'
                                        />) :
                                        (<Radio
                                            checked={selectedIds.indexOf(idSelector(item)) !== -1}
                                            onChange={() => setSelectedIds([idSelector(item)])}
                                            classes={{ checked: classes.radioChecked, root: classes.noPadding }}
                                            color="default"
                                        />)
                                    }
                                </TableCell> : null}
                        {columns.map(c => {
                            let cellContent = isHidden(c)
                                ? <TableCell className={classes.hiddenCell}
                                    key={c.name} data-tour={`${c.tourKey}-row-${index}`}>
                                    {c.value && c.value(item)}
                                </TableCell>
                                : <TableCell
                                    className={columnTableCellClass(c)}
                                    key={c.name} data-tour={`${c.tourKey}-row-${index}`}
                                    style={({ cursor: onClick ? 'pointer' : 'default' })} onClick={() => onClick && onClick(item)}
                                    onMouseEnter={_ => props.onItemMouseEnter && props.onItemMouseEnter(item)}>
                                    {c.cellAction
                                        ? <div className={classes.cellContent}>
                                            <span className={classes.cellAction}>
                                                {c.cellAction(item)}
                                            </span>
                                            {c.htmlFor != undefined ? c.htmlFor(item) : c.value && c.value(item)}
                                        </div>
                                        : c.htmlFor != undefined ? c.htmlFor(item) : c.value && c.value(item)}
                                </TableCell>

                            let tooltipValue = c.tooltipValue ? c.tooltipValue(item) : null

                            return tooltipValue
                                ? <Tooltip key={c.name} title={<Typography variant='body1'>{tooltipValue}</Typography>} placement='top'>
                                    {cellContent}
                                </Tooltip>
                                : cellContent
                        })
                        }
                        {actions &&
                            <TableCell className={classes.noPaddingCell + ' ' + classes.noWidthCell}
                                onMouseEnter={_ => props.onItemMouseEnter && props.onItemMouseEnter(item)}>
                                <div className={classes.lineActionsContainer}>
                                    <div className={classes.lineActions + ' ' + (actionDisabled ? classes.disableClick : '')}>
                                        {!!bodyActions && bodyActions.map(action =>
                                            action.disabled && action.disabled(item) ? undefined
                                                : <div style={{ cursor: onClick ? 'pointer' : 'default' }} key={action.name}>
                                                    <IconButton size={'small'} onClick={() => { action.action(item) }}>{action.icon}</IconButton>
                                                </div>)}
                                    </div>
                                </div>
                            </TableCell>}
                    </TableRow>
                )
            })}
        </TableBody>
        {items.length > 1 && columns.some(x => x.total) ?
            <TableFooter>
                <TableRow>
                    {isSelectable ? <TableCell><b>{t('components.table.total')}</b></TableCell> : null}
                    {columns.map(c =>
                        c.total !== null && c.total !== undefined && !isHidden(c)
                            ? <TableCell className={(c.alignRight ? classes.cellRightPadding : classes.cellPadding) + ' ' + (c.leftBorder ? classes.separator : ' ')} key={c.name}>
                                {typeof c.total == 'number' ? <b>{Math.round(c.total)}</b> : c.total}
                                {!!c.totalLabel ? `(${c.totalLabel})` : null}
                            </TableCell>
                            : !isHidden(c) && <TableCell key={c.name + 'empty'}></TableCell>
                    )}
                    {actions && <TableCell className={classes.noPaddingCell + ' ' + classes.noWidthCell}></TableCell>}
                </TableRow>
            </TableFooter> : null}
    </ Table>)
}

export let ColumnFilterField = ({ filter, classes }: { filter: ColumnFilter, classes: any }) => {
    let inputDebouncer = useInputDebounce(
        filter.value,
        val => {
            filter.onChange(val)
            inputDebouncer.setValue(val)
        },
        300)

    return (
        <SearchField label={''} overrideStyle={{ root: classes.filterField }} onChange={val => inputDebouncer.onChange(val.target.value)}
            text={inputDebouncer.value ? inputDebouncer.value : ''} />
    )
}

let cellPadding: React.CSSProperties = {
    padding: '6px 8px',
    whiteSpace: 'nowrap'
}

export let tableStyles = {
    tablecontainer: {
        width: '100%',
        height: '10%'
    },
    tableRow: {
        padding: '5px 16px'
    },
    modifiedRow: {
        backgroundColor: defaultColors.orange.veryLight.color,
        '&:hover': {
            backgroundColor: '#faeddc !important',
            '& $lineActions': {
                opacity: '1 !important'
            },
            '& $cellAction': {
                opacity: '1 !important'
            }
        }
    },
    cellPadding: { ...cellPadding },
    cellRightPadding: {
        ...cellPadding,
        textAlign: 'right'
    },
    noPaddingCell: { padding: '0 !important', },
    noWidthCell: { width: 0 },
    cellCenterPadding: {
        ...cellPadding,
        textAlign: 'center'
    },
    cellGroupHeader: {
        ...cellPadding,
        fontSize: '1.2em',
        minHeight: '4em'
    },
    actionHeader: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        height: 0,
        position: 'relative',
        '& > *': {
            marginRight: '0.4em'
        }
    },
    separator: {
        borderLeftColor: '#e0e0e0',
        borderLeftWidth: '1px',
        borderLeftStyle: 'solid',
    },
    show: {
        visibility: 'visible',
        opacity: '1',
        transition: 'opacity 0.3s linear',
    } as CSSProperties,
    hide: {
        visibility: 'hidden',
        opacity: '0',
        transition: 'visibility 0s 0.3s, opacity 0.3s linear'
    } as CSSProperties,
    lineActionsContainer: {
        direction: 'rtl',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    lineActions: {
        whiteSpace: 'nowrap',
        direction: 'rtl',
        float: 'right',
        position: 'absolute',
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        background: 'white',
        borderRadius: '10px',
        opacity: 0,
        '& > *': {
            marginRight: '0.1em',
            '&:first-child': {
                marginRight: 0
            }
        }
    },
    lineHover: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#FBFBFB',
            '& $lineActions': {
                opacity: '1 !important'
            },
            '& $cellAction': {
                opacity: '1 !important'
            },
        }
    },
    cellAction: {
        background: 'transparent',
        opacity: 0,
        height: '0.5em',
        display: 'flex',
        alignItems: 'center',
    },
    cellContent: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center'
    },
    hiddenCell: {
        display: 'none',
    },
    wrapCell: {
        whiteSpace: 'normal',
        maxWidth: '9em',
        overflowWrap: 'break-word',
        fontSize: '0.8em',
    },
    noSize: {
        top: '-1em'
    },
    table: {
        backgroundColor: 'white'
    },
    noPadding: {
        padding: '0px',
    },
    checkboxChecked: {
        color: defaultColors.red.main.color,
    },
    disableClick: {
        pointerEvents: 'none'
    },
    filterHeadCell: {
        padding: '0px 15px',
        whiteSpace: 'nowrap'
    },
    filterField: {
        '& > div': {
            fontSize: '0.875rem'
        }
    },
    wrapCellMax: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '10em'
    },
    toggleGroups: {
        '& > span': {
            cursor: 'pointer',
            '& > svg': {
                verticalAlign: 'bottom'
            }
        },
        '&.body': {
            backgroundColor: defaultColors.grey.veryLight.color
        }
    }
} as Record<string, CSSProperties>
// Footer styles are in materialUiThemeProvider.tsx

let styles = _ => createStyles(tableStyles)

export let DataTable = withStyles(styles, muiOptions)(_DataGrid)