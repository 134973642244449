import { createStyles, withStyles } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import React, { useEffect, useState } from 'react'
import { ColumnDescriptor, Select, TableItem, TextField } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import * as api from '../../../infrastructure/api'
import { Guid } from '../../../infrastructure/guid'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { MasterDataItem, MasterDataShell, createExcelLines } from './masterDataShell'
import { ChannelCustomerSegment } from 'src/app/truck/truckModels'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'

type ShipTo = {
    id: Guid
    shipTo: string
    shipToName: string
    company: string
    customerSegment: string
    soldTo: string | null
    soldToName: string | null
    destination: string | null
    assignedShipTo: string | null
}

type ShipToFilter = {
    shipTo: string | null
    shipToName: string | null
    company: string | null
    customerSegment: string | null
    soldTo: string | null
    soldToName: string | null
    destination: string | null
    assignedShipTo: string | null
}

let noFilters: ShipToFilter = {
    shipTo: null,
    shipToName: null,
    company: null,
    customerSegment: null,
    soldTo: null,
    soldToName: null,
    destination: null,
    assignedShipTo: null
}

let toTableItem = (shipTo: ShipTo): TableItem<MasterDataItem<ShipTo>> => {
    return {
        id: shipTo.id,
        shipTo: shipTo.shipTo,
        shipToName: shipTo.shipToName,
        company: shipTo.company,
        customerSegment: shipTo.customerSegment,
        soldTo: shipTo.soldTo,
        soldToName: shipTo.soldToName,
        destination: shipTo.destination,
        assignedShipTo: shipTo.assignedShipTo,
        isModified: false
    }
}

let applyFilters = (sapCustomers: ShipTo[], filters: ShipToFilter): ShipTo[] => {
    if (filters.shipTo)
        sapCustomers = sapCustomers.filter(x => x.shipTo.toLowerCase().contains(filters.shipTo!.toLowerCase()))

    if (filters.shipToName)
        sapCustomers = sapCustomers.filter(x => x.shipToName.toLowerCase().contains(filters.shipToName!.toLowerCase()))

    if (filters.company)
        sapCustomers = sapCustomers.filter(x => x.company.toLowerCase().contains(filters.company!.toLowerCase()))

    if (filters.customerSegment)
        sapCustomers = sapCustomers.filter(x => x.customerSegment.toLowerCase().contains(filters.customerSegment!.toLowerCase()))

    if (filters.soldTo)
        sapCustomers = sapCustomers.filter(x => x.soldTo?.toLowerCase().contains(filters.soldTo!.toLowerCase()))

    if (filters.soldToName)
        sapCustomers = sapCustomers.filter(x => x.soldToName?.toLowerCase().contains(filters.soldToName!.toLowerCase()))

    if (filters.destination)
        sapCustomers = sapCustomers.filter(x => x.destination?.toLowerCase().contains(filters.destination!.toLowerCase()))

    if (filters.assignedShipTo)
        sapCustomers = sapCustomers.filter(x => x.assignedShipTo?.toLowerCase().contains(filters.assignedShipTo!.toLowerCase()))

    return sapCustomers
}

function SapShipToMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [filters, setFilters] = useState<ShipToFilter>(noFilters)
    let [shipTos, setShipTos] = useState<ShipTo[]>([])

    let [customerSegments, setCustomerSegments] = useState<ChannelCustomerSegment[]>([])

    let isManager = hasClaim(Claims.MasterdataSapShipToManager)

    let load = async () => {
        let shipTos = api.get<ShipTo[]>('masterdata/shipTo')
        if (isManager) {
            let customerSegments = api.get<ChannelCustomerSegment[]>('stock/movement/mktsale/channelCustomerSegment')
            setCustomerSegments(await customerSegments)
        }
        setShipTos(await shipTos)
    }

    useEffect(() => {
        let effect = async () => {
            await load()
        }
        effect()
    }, [])

    let columns: ColumnDescriptor<TableItem<MasterDataItem<ShipTo>>>[] = [
        {
            name: t('admin.masterdata.sapShipTo.companyCode'), value: x => x.company,
            columnFilter: { value: filters.company ?? '', onChange: (company: string) => setFilters({ ...filters, company }) }
        },
        {
            name: t('admin.masterdata.sapShipTo.shipToCode'), value: x => x.shipTo,
            columnFilter: { value: filters.shipTo ?? '', onChange: (shipTo: string) => setFilters({ ...filters, shipTo }) }
        },
        {
            name: t('admin.masterdata.sapShipTo.shipToName'), value: x => x.shipToName,
            columnFilter: { value: filters.shipToName ?? '', onChange: (shipToName: string) => setFilters({ ...filters, shipToName }) }
        },
        {
            name: t('admin.masterdata.sapShipTo.soldTo'), value: x => x.soldTo,
            columnFilter: { value: filters.soldTo ?? '', onChange: (soldTo: string) => setFilters({ ...filters, soldTo }) }
        },
        {
            name: t('admin.masterdata.sapShipTo.soldToName'), value: x => x.soldToName,
            columnFilter: { value: filters.soldToName ?? '', onChange: (soldToName: string) => setFilters({ ...filters, soldToName }) }
        },
        {
            name: t('admin.masterdata.sapShipTo.destination'), value: x => x.destination,
            columnFilter: { value: filters.destination ?? '', onChange: (destination: string) => setFilters({ ...filters, destination }) }
        },
        {
            name: t('admin.masterdata.sapShipTo.assignedShipTo'), value: x => x.assignedShipTo,
            columnFilter: { value: filters.assignedShipTo ?? '', onChange: (assignedShipTo: string) => setFilters({ ...filters, assignedShipTo }) }
        },
        {
            name: t('admin.masterdata.sapShipTo.customerSegment'), value: x => x.customerSegment,
            columnFilter: { value: filters.customerSegment ?? '', onChange: (customerSegment: string) => setFilters({ ...filters, customerSegment }) }
        }
    ]

    let onSave = async (item: ShipTo) => {
        await api.post('masterdata/shipTo/customerSegmentAndAssignedShipTo',
            { shipToId: item.id, customerSegment: item.customerSegment, assignedShipTo: item.assignedShipTo })
        await load()
        return true
    }

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'ShipTos.xlsx',
            sheets: [{ name: 'Companys', lines: createExcelLines(applyFilters(shipTos, filters).map(toTableItem), columns) }]
        })
    }

    return (<div className={classes.container}>
        <MasterDataShell
            headerLabel={t('admin.masterdata.sapShipTo.sapShipTos')}
            itemLabel={t('admin.masterdata.sapShipTo.sapShipTo')}
            isManager={isManager}
            items={applyFilters(shipTos, filters).map(toTableItem)}
            onSave={onSave}
            columns={columns}
            onExportExcel={exportExcel}
        >
            {(selectedItem, setSelectedItem) => (<>
                <TextField label={t('admin.masterdata.sapShipTo.assignedShipTo')}
                    text={selectedItem.assignedShipTo}
                    onChange={x => setSelectedItem({ ...selectedItem, assignedShipTo: x.target.value })}
                    disabled={selectedItem.destination !== 'SOC'} />

                <Select label={t('admin.masterdata.sapShipTo.customerSegment')}
                    disabled={!isManager}
                    value={selectedItem.customerSegment}
                    choices={customerSegments
                        .filter((x: ChannelCustomerSegment) => x.company == selectedItem.company)
                        .map(x => ({ value: x.customerSegment, text: x.customerSegment }))
                    }
                    onChange={val => { if (val) setSelectedItem({ ...selectedItem, customerSegment: val }) }} />
            </>)}
        </MasterDataShell>
    </div>)
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
    })

export default withStyles(styles, muiOptions)(SapShipToMasterData)