import { PopupNavigatorType } from '../../popupNavigator'
import { Guid } from '../../guid'
import { t } from '../../i18nextHelper'
import { Notification, NotificationView } from './models'

export function createViewMapper(popupNavigator: PopupNavigatorType) {
    return {
        mapToView: (notification: Notification): NotificationView => ({
            title: t('notification.' + notification.type + '.title'),
            subtitle: notification.description,
            action: t('notification.' + notification.type + '.action'),
            daysAgo: getDaysAgo(notification.date),
            help: t('notification.' + notification.type + '.help'),
            alertLevel: notification.alertLevel,
            open: getOpen(notification.type, notification.relatedId, popupNavigator)
        })
    }
}

function getDaysAgo(date: Date) {
    let alertDate = new Date(date)
    let today = new Date()
    let daysDiff = (today.getTime() - alertDate.getTime()) / oneDay

    return daysDiff < 1 ? t('notification.today') :
        daysDiff > 1 && daysDiff < 2
            ? Math.round(daysDiff).toString() + ' ' + t('notification.dayAgo')
            : Math.round(daysDiff).toString() + ' ' + t('notification.daysAgo')
}

function getOpen(type: Notification['type'], relatedId: Guid, popupNavigator: PopupNavigatorType) {
    let open = (type) => () => popupNavigator.open(type, relatedId)

    return type.contains('Movement') ? open('movement')
        : type.contains('Deal') ? open('deal')
            : type.contains('Vessel') ? open('vessel')
                : noop
}

const oneDay = 24 * 60 * 60 * 1000
const noop = () => { }