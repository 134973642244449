import React, { useState } from 'react'
import { AppBar, Toolbar, withStyles, createStyles, Fade, LinearProgress, Typography, IconButton } from '@material-ui/core'
import { LoaderStatusContainer } from '../loader'
import * as Icons from '@material-ui/icons'
import { MuiProps, muiOptions, defaultStyles, defaultColors } from '../materialUiThemeProvider'
import { CustomDialog } from '../../app/common/customComponents'
import { t } from './../../infrastructure/i18nextHelper'
import { UserContextContainer } from '../signIn/userContext'
import CountrySelector from './selectors/countrySelector'
import { NotificationBell } from './notifications/bell'
import { getEnvironment } from '../environment'
import { userSettingsDialog } from './userSettings/userSettingsDialog'

const helpWikiUrl = 'https://totalworkplace.sharepoint.com/sites/LYNKS2/SitePages/Help.aspx'

function Header({ classes, toggleDrawer }: MuiProps & { toggleDrawer: () => void }) {
    let loader = LoaderStatusContainer.useContainer()
    let userContext = UserContextContainer.useContainer()
    let [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false)

    let openLink = (url: string) => {
        window.open(url, '_blank')
    }

    let logout = () => {
        userContext.disconnect()
        window.location.href = '/signin'
    }

    let headerBackgroundColorByEnv = () => {
        switch (getEnvironment()) {
            case 'Local': return 'pink'
            case 'Production': return 'white'
            case 'Dev': return 'lightgreen'
            case 'Staging': return 'yellow'
            case 'Qualification': return 'lightskyblue'
            case 'Integration': return 'navy'
            case 'Test': return 'navy'
            case 'Other':
                return 'gray'
        }
    }

    return (
        <AppBar>
            <Toolbar className={classes.toolbar} style={{ backgroundColor: headerBackgroundColorByEnv() }}>
                <div className={classes.rowContainer}>
                    <div className={classes.marginLeft16px + ' ' + classes.content + ' headerContent'}>
                        <Icons.Menu
                            className={classes.menuIcon}
                            aria-label='Open menu'
                            onClick={toggleDrawer} />
                        <img className={classes.logo} src='../../../static/images/Logo.svg' />
                    </div>
                    <div className={classes.content + ' headerContent'}>
                        <Typography className={classes.userName}>{userContext.profile?.firstName} {userContext.profile?.lastName}</Typography>

                        <CountrySelector />

                        <NotificationBell />

                        <IconButton onClick={() => openLink(helpWikiUrl)}>
                            <Icons.ContactSupportOutlined />
                        </IconButton>

                        <IconButton onClick={() => userSettingsDialog.open()}>
                            <Icons.SettingsOutlined />
                        </IconButton>

                        <IconButton className={classes.marginRight16px + ' headerContent'} onClick={() => setOpenLogoutDialog(true)}>
                            <Icons.PowerSettingsNewOutlined />
                        </IconButton>
                    </div>
                </div>
            </Toolbar>
            <Fade in={loader.isActive} unmountOnExit>
                <LinearProgress classes={{ barColorPrimary: classes.progressBar }}
                    className={classes.loader}
                />
            </Fade>
            <CustomDialog isOpen={openLogoutDialog} title={t('components.logoutDialogTitle')}
                contentText={t('components.logoutDialogContent')} confirmButtonText={t('components.logoutDialogConfirm')} onConfirm={() => logout()}
                onClose={() => setOpenLogoutDialog(false)} onCancel={() => setOpenLogoutDialog(false)} />
        </AppBar>
    )
}

let styles = _ =>
    createStyles({
        toolbar: {
            ...defaultStyles.flexColumn,
            justifyContent: 'flex-end',
            alignItems: 'center',
            minHeight: '2.5em',
            maxHeight: '2.5em',
            padding: 0
        },
        rowContainer: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            width: '100%',
            height: '2.5em'
        },
        logo: {
            width: '2.5em',
            marginLeft: '2em'
        },
        content: {
            ...defaultStyles.flexRow
        },
        loader: {
            backgroundColor: 'white',
            height: '3px',
            marginTop: '-3px',
            width: '105%'
        },
        userName: {
            color: defaultColors.grey.main.color,
            marginRight: '6em',
            fontStyle: 'italic'
        },
        progressBar: {
            backgroundColor: defaultColors.darkBlue.main.color
        },
        marginLeft16px: {
            marginLeft: '16px'
        },
        marginRight16px: {
            marginRight: '16px'
        },
        menuIcon: {
            color: defaultColors.grey.dark.color,
            cursor: 'pointer'
        }
    })

export default withStyles(styles, muiOptions)(Header)