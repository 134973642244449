import React from 'react'
import { NotificationView } from './models'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

export let NotificationItem = React.memo(({ notification, classes }: Props) => {
    return (
        <div className={classes.line + ' ' + notification.alertLevel} onClick={notification.open}>
            <div className={classes.lineLeft}>
                <div className={classes.lineLeftTitle}>
                    <span>{notification.title}</span>
                </div>
                <div className={classes.lineLeftSubTitle}>
                    <span>{notification.subtitle}</span>
                </div>
            </div>
            <div className={classes.lineRight}>
                <div>
                    <span>{notification.action}</span>
                </div>
                <div>
                    <span>{notification.daysAgo}</span>
                </div>
            </div>
            {notification.help ?
                <div className={classes.help}>
                    <div className={classes.helpText}>
                        <span>{notification.help}</span>
                    </div>
                    <div className={classes.helpIcon}>
                        <HelpOutlineIcon />
                    </div>
                </div> : null}
        </div>
    )
}, (prev, next) =>
    prev.notification.title === next.notification.title &&
    prev.notification.subtitle === next.notification.subtitle &&
    prev.notification.daysAgo === next.notification.daysAgo)

type Props = {
    notification: NotificationView
    classes: any
}